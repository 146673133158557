/* eslint-disable max-len */
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
    AccordionDetails,
    AccordionGroup,
    AspectRatio,
    Box,
    Card,
    CardContent,
    Link,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Typography,
    useTheme,
} from '@mui/joy';
import Accordion, {accordionClasses} from '@mui/joy/Accordion';
import AccordionSummary, {accordionSummaryClasses} from '@mui/joy/AccordionSummary';
import propTypes from 'prop-types';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
import {processYoutubeUrl} from 'utilities/urlUtils';
import SectionHeader from './SectionHeader';
import TextItem from './TextItem';

/* eslint-enable max-len */

const LinksSection = ({links, numPopulatedSections}) => {
    return (
        <Accordion defaultExpanded={numPopulatedSections < 3}>
            <SectionHeader icon={<LinkRoundedIcon />}>Useful links</SectionHeader>
            <AccordionDetails>
                <List marker='"-"'>
                    {links.map((link) => (
                        <ListItem key={link.title}>
                            <ListItemContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <Link
                                        variant="soft"
                                        href={link.url}
                                        target="_blank"
                                        sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            ml: 2,
                                            borderRadius: 'sm',
                                        }}
                                    >
                                        <Typography level="title-md" component="div">
                                            <PlainMarkdown>{link.title}</PlainMarkdown>
                                        </Typography>
                                    </Link>
                                    <Link href={link.url} target="_blank">
                                        <OpenInNewRoundedIcon fontSize="small" />
                                    </Link>
                                </Box>
                                <Typography sx={{ml: 2}} component="div">
                                    <StyledMarkdown>{link.description}</StyledMarkdown>
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

LinksSection.propTypes = {
    links: propTypes.arrayOf(
        propTypes.shape({
            title: propTypes.string,
            description: propTypes.string,
            url: propTypes.string,
        }),
    ),
    numPopulatedSections: propTypes.number,
};

const VideosSection = ({videos}) => {
    return (
        <Accordion defaultExpanded={false}>
            <SectionHeader icon={<OndemandVideoRoundedIcon />}>
                Helpful videos
            </SectionHeader>
            <AccordionDetails>
                <Typography level="body-md" sx={{mt: 1, mb: 2}} component="div">
                    <StyledMarkdown>{videos.description}</StyledMarkdown>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                >
                    {videos.videos.map((video) => (
                        <Card key={video.title} variant="outlined" sx={{width: '90%'}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AspectRatio
                                    sx={{
                                        width: '90%',
                                    }}
                                >
                                    <iframe
                                        src={processYoutubeUrl(video.youtubeUrl)}
                                        title={video.title}
                                        style={{
                                            border: 0,
                                        }}
                                        // eslint-disable-next-line max-len
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                </AspectRatio>
                            </Box>
                            <div>
                                <Typography level="title-md" component="div">
                                    <PlainMarkdown>{video.title}</PlainMarkdown>
                                </Typography>
                                <Typography level="body-sm" component="div">
                                    <StyledMarkdown>{video.description}</StyledMarkdown>
                                </Typography>
                            </div>
                        </Card>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

VideosSection.propTypes = {
    videos: propTypes.shape({
        description: propTypes.string,
        videos: propTypes.arrayOf(
            propTypes.shape({
                title: propTypes.string,
                description: propTypes.string,
                youtubeUrl: propTypes.string,
            }),
        ),
    }),
};

const DescriptionSection = ({medicationName, description}) => {
    return (
        <Accordion defaultExpanded={false}>
            <SectionHeader icon={<DescriptionRoundedIcon />}>
                About {medicationName}
            </SectionHeader>
            <AccordionDetails>
                <Typography sx={{my: 1}} component="div">
                    <StyledMarkdown>{description}</StyledMarkdown>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

DescriptionSection.propTypes = {
    medicationName: propTypes.string,
    description: propTypes.string,
};

const SafetyChecksSection = ({checks}) => {
    return (
        <Accordion defaultExpanded={false}>
            <SectionHeader icon={<HealthAndSafetyRoundedIcon />}>
                Safety checks
            </SectionHeader>
            <AccordionDetails>
                {checks.description.split('\n').map((line, index) => (
                    <Typography key={index} sx={{my: 1}}>
                        {line}
                    </Typography>
                ))}
                <List>
                    {checks.checks.map((check) => (
                        <ListItem key={check.title}>
                            <ListItemDecorator>
                                <CheckCircleOutlineRoundedIcon color="primary" />
                            </ListItemDecorator>
                            <ListItemContent>
                                <Typography
                                    level="title-md"
                                    sx={{ml: 2}}
                                    component="div"
                                >
                                    <PlainMarkdown>{check.title}</PlainMarkdown>
                                </Typography>
                                <Typography sx={{ml: 2}} component="div">
                                    <StyledMarkdown>{check.description}</StyledMarkdown>
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

SafetyChecksSection.propTypes = {
    checks: propTypes.shape({
        description: propTypes.string,
        checks: propTypes.arrayOf(
            propTypes.shape({
                title: propTypes.string,
                description: propTypes.string,
            }),
        ),
    }),
};

const SharedCareSection = ({sharedCareDetails}) => {
    const theme = useTheme();
    const mode = getColourMode();

    return (
        <Card
            variant="outlined"
            color="danger"
            sx={{
                width: '95%',
                bgcolor:
                    mode == 'light'
                        ? theme.palette.danger['50']
                        : theme.palette.danger['900'],
                boxShadow: 'sm',
                my: 2,
                px: 0,
                py: 1,
            }}
        >
            <AccordionGroup
                disableDivider
                sx={{
                    mx: 1,
                    [`& .${accordionClasses.root}`]: {
                        marginTop: 0,
                        marginBottom: 0,
                        transition: '0.2s ease',
                        '& button:not([aria-expanded="true"])': {
                            transition: '0.2s ease',
                            paddingBottom: '0.625rem',
                        },
                        '& button:hover': {
                            background: 'transparent',
                        },
                    },
                    [`& .${accordionSummaryClasses.indicator}`]: {
                        transition: '0.2s',
                    },
                    [`& [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]:
                        {
                            transform: 'rotate(45deg)',
                        },
                }}
            >
                <Accordion defaultExpanded={false}>
                    <CardContent>
                        <AccordionSummary indicator={<AddIcon />} color="danger">
                            <Box
                                sx={{display: 'flex', flexDirection: 'column', gap: 1}}
                            >
                                <Typography level="body-md" color="danger">
                                    Important - Read first
                                </Typography>
                                <Typography level="title-lg" color="danger">
                                    Shared Care Drug Information
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {sharedCareDetails.map((item, index) => {
                                return <TextItem key={index} {...item} />;
                            })}
                        </AccordionDetails>
                    </CardContent>
                </Accordion>
            </AccordionGroup>
        </Card>
    );
};

SharedCareSection.propTypes = {
    sharedCareDetails: propTypes.arrayOf(
        propTypes.shape({
            title: propTypes.string,
            body: propTypes.string,
            url: propTypes.string,
            listItems: propTypes.arrayOf(propTypes.string),
        }),
    ),
};

export {
    DescriptionSection,
    LinksSection,
    SafetyChecksSection,
    SharedCareSection,
    VideosSection,
};
