import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import UserContext from './UserContext';

/**
 * UserProvider is a context provider component for user information.
 * It takes a decoded token as a prop and uses it to provide various user information
 * to the components that consume this context.
 *
 * @param {object} props - The component props
 * @param {React.ReactNode} props.children - The children components
 * @param {object} props.decodedToken - The decoded user token
 * @param {string} props.decodedToken.given_name - The user's given name
 * @param {string} props.decodedToken.family_name - The user's family name
 * @param {string} props.decodedToken.email - The user's email
 * @param {string} props.decodedToken.custom:nhs_number - The user's NHS number
 * @param {string} props.decodedToken.birthdate - The user's date of birth
 * @param {string} props.decodedToken.phone_number - The user's phone number
 * @param {string} props.decodedToken.sub - The user's subject identifier
 * @param {string} props.decodedToken.gp_linkage_key - The user's GP linkage key
 * @param {string} props.decodedToken.gp_ods_code - The user's GP ODS code
 * @param {string} props.decodedToken.gp_user_id - The user's GP user ID
 *
 * @return {React.ElementType}
 */
const UserProvider = ({children, decodedToken}) => {
    const value = useMemo(() => {
        return {
            ...decodedToken, // Spread all key-value pairs from decodedToken
        };
    }, [decodedToken]);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    decodedToken: PropTypes.shape({
        given_name: PropTypes.string,
        family_name: PropTypes.string,
        email: PropTypes.string,
        'custom:nhs_number': PropTypes.string,
        birthdate: PropTypes.string,
        phone_number: PropTypes.string,
        sub: PropTypes.string,
        gp_linkage_key: PropTypes.string,
        gp_ods_code: PropTypes.string,
        gp_user_id: PropTypes.string,
    }).isRequired,
};

export default UserProvider;
