import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
// views 👇🏻
// root
import Root from './Root.js';
// import MyActions from './routes/Actions/MyActions.js';
import MyHealth from './routes/Health/MyHealth.js';
import Home from './routes/Home/Home.js';
import ErrorPage from './routes/Home/error/errorPage.js';
import MyMedications from './routes/Medication/MyMedications.js';
// import MyResults from './routes/Results/MyResults.js';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/error',
                element: <ErrorPage />,
            },
            {
                path: 'health',
                element: <MyHealth />,
            },
            // {
            //     path: 'results',
            //     element: <MyResults />,
            // },
            {
                path: 'medications',
                element: <MyMedications />,
            },
            // {
            //     path: 'actions',
            //     element: <MyActions />,
            // },
        ],
    },
]);

export default router;
