import {Box, Card, Skeleton, Typography} from '@mui/joy';
import React from 'react';

const SkeletonCard = () => {
    return (
        <Card variant="outlined" sx={{width: '100%', maxWidth: '400px'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 4,
                }}
            >
                <Typography level="title-sm" color="primary">
                    <Skeleton>Lorem ipsum dolor sit amet, consectetur.</Skeleton>
                </Typography>
            </Box>
            <Typography level="body-sm" component="div">
                <Skeleton>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis
                    enim egestas, bibendum tortor et, vulputate enim. Sed non vestibulum
                    ligula, ut iaculis leo. Maecenas iaculis efficitur facilisis.
                </Skeleton>
            </Typography>
        </Card>
    );
};
SkeletonCard.propTypes = {};

export default SkeletonCard;
