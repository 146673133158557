import {gql, useLazyQuery} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const SEARCH_PHARMACIES = gql`
    query SearchPharmacies(
        $query: String!
        $searchByLocation: Boolean!
        $limit: Int
        $skip: Int
    ) {
        searchPharmacies(
            query: $query
            searchByLocation: $searchByLocation
            limit: $limit
            skip: $skip
        ) {
            pharmacy {
                odsCode
                organisationName
                organisationType
                url
                address1
                address2
                address3
                city
                county
                latitude
                longitude
                organisationSubType
                postcode
            }
            searchScore
        }
    }
`;

export const useSearchPharmacies = () => {
    const [getPharmacies, {loading, error, data}] = useLazyQuery(SEARCH_PHARMACIES, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        getPharmacies,
        loading,
        error,
        data: data ? data.searchPharmacies : null,
    };
};
