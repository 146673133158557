import {Box, Typography} from '@mui/joy';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import PropTypes from 'prop-types';
import React from 'react';

const PrescriptionTimelineItem = ({order, nextOrder, elapsedTime}) => {
    return (
        <Box>
            {elapsedTime && (
                <TimelineItem>
                    <TimelineOppositeContent> </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{mb: 6}}>
                        <Typography level="body-xs">
                            {elapsedTime}{' '}
                            {nextOrder ? 'between orders' : 'since last order'}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            )}
            <TimelineItem>
                <TimelineOppositeContent sx={{mb: 6}}>
                    {order.date && (
                        <Typography level="title-md">
                            {new Date(order.date).toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric',
                            })}
                        </Typography>
                    )}
                    {order.quantity && order.quantityUnit && (
                        <Typography level="body-sm">
                            <b>Quantity: </b>
                            {order.quantity} {order.quantityUnit}
                        </Typography>
                    )}
                    {order.dose && (
                        <Typography level="body-sm">
                            <b>Dose: </b>
                            {order.dose}
                        </Typography>
                    )}
                    {order.prescriptionType && (
                        <Typography level="body-sm">
                            <b>Prescription Type: </b>
                            {order.prescriptionType}
                        </Typography>
                    )}
                    {order.status && (
                        <Typography level="body-sm">
                            <b>Status: </b>
                            {order.status}
                        </Typography>
                    )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent></TimelineContent>
            </TimelineItem>
        </Box>
    );
};

export default PrescriptionTimelineItem;

PrescriptionTimelineItem.propTypes = {
    elapsedTime: PropTypes.string,
    nextOrder: PropTypes.object,
    order: PropTypes.shape({
        date: PropTypes.string,
        dose: PropTypes.string,
        prescriptionType: PropTypes.string,
        quantity: PropTypes.string,
        quantityUnit: PropTypes.string,
        status: PropTypes.string,
    }).isRequired,
};
