import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {Breadcrumbs, IconButton, Link as JoyLink, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

const JoyRouterLink = React.forwardRef(function JoyRouterLink(props, ref) {
    return <JoyLink ref={ref} component={RouterLink} {...props} />;
});

const BreadcrumbComponent = ({breadcrumbs}) => {
    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{my: 0, p: 0}}>
            <IconButton
                aria-label="home"
                component={JoyRouterLink}
                color="neutral"
                to="/"
            >
                <HomeOutlinedIcon sx={{display: 'flex'}} />
            </IconButton>
            {breadcrumbs.map((breadcrumb, index) => {
                const isLast = index === breadcrumbs.length - 1;

                if (breadcrumb.href && !isLast) {
                    return (
                        <JoyRouterLink
                            key={breadcrumb.href || breadcrumb.text}
                            underline="hover"
                            color="inherit"
                            to={`/${breadcrumb.href}`}
                        >
                            {breadcrumb.text}
                        </JoyRouterLink>
                    );
                } else {
                    return (
                        <Typography key={breadcrumb.text} color="text.primary">
                            {breadcrumb.text}
                        </Typography>
                    );
                }
            })}
        </Breadcrumbs>
    );
};

BreadcrumbComponent.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string, // Optional href for clickable breadcrumbs
            text: PropTypes.string.isRequired, // Required visible text of the crumb
            isCurrent: PropTypes.bool, // Optional flag to indicate current page
        }),
    ),
};

export default BreadcrumbComponent;
