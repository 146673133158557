import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const MY_LOADING_PROGRESS = gql`
    query MyLoadingProgress {
        myLoadingProgress {
            importStatus
            eventDescription
            availableData
            loginEventId
            progress
            message
            userId
            eventTime
        }
    }
`;

export const useMyLoadingProgress = (pollInterval) => {
    const {loading, error, data, startPolling, stopPolling, networkStatus} = useQuery(
        MY_LOADING_PROGRESS,
        {
            fetchPolicy: 'network-only', // Always fetch from the server
            notifyOnNetworkStatusChange: true,
            pollInterval: pollInterval,
        },
    );

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        loading,
        error,
        data,
        startPolling,
        stopPolling,
        networkStatus,
    };
};
