import DoneAllIcon from '@mui/icons-material/DoneAll';
import DownloadingIcon from '@mui/icons-material/Downloading';
import SearchIcon from '@mui/icons-material/Search';
import {Box, Modal, Sheet, Stack, Typography} from '@mui/joy';
import LinearProgress from '@mui/joy/LinearProgress';
import {useProgress} from 'contexts/ProgressProvider';
import PocketGpLogoHeader from 'logos/PocketGpLogoHeader';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import DataItemCard from './components.js/DataItemCard';

const loadingMessageMapping = {
    None: {
        message: 'Finding your medical record...',
        progressValue: 33,
        icon: <SearchIcon />,
    },
    PatientId: {
        message: 'Loading your medical record...',
        progressValue: 66,
        icon: <DownloadingIcon />,
    },
    MedicalRecord: {message: 'Success!', progressValue: 100, icon: <DoneAllIcon />},
};

// Order for displaying items (Top to Bottom)
const displayOrder = ['MedicalRecord', 'PatientId', 'None'];

// Order for adding items to the list
const additionOrder = ['None', 'PatientId', 'MedicalRecord'];

const LoadingMedicalRecordModal = () => {
    const colourMode = getColourMode();

    const {loadingProgress, availableData} = useProgress();
    const [modalOpen, setModalOpen] = React.useState(true);
    const [modalOpacity, setModalOpacity] = React.useState(1);
    const [distinctAvailableDataItems, setDistinctAvailableDataItems] = React.useState(
        [],
    );
    const [displayedItems, setDisplayedItems] = React.useState([]);

    React.useEffect(() => {
        if (loadingProgress === 100) {
            // Start fading out the modal with a delay
            setTimeout(() => setModalOpacity(0), 1000); // Fade out after 1 second
        }
    }, [loadingProgress]);

    React.useEffect(() => {
        if (modalOpacity === 0) {
            // Close the modal after the fade-out transition is complete
            setTimeout(() => setModalOpen(false), 1000);
        }
    }, [modalOpacity]);

    React.useEffect(() => {
        const newData = [...availableData];

        // Sometimes we may receive MedicalRecord message before 'PatientId'.
        // If we receieved 'MedicalRecord', then we know we have 'PatientId' too.
        if (newData.includes('MedicalRecord') && !newData.includes('PatientId')) {
            newData.push('PatientId');
        }

        const orderedAvailableData = Array.from(new Set(newData)).sort(
            (a, b) => additionOrder.indexOf(a) - additionOrder.indexOf(b),
        );
        setDistinctAvailableDataItems(orderedAvailableData);
    }, [availableData]);

    React.useEffect(() => {
        const newItems = distinctAvailableDataItems.filter(
            (item) => !displayedItems.includes(item),
        );

        newItems.forEach((item, index) => {
            const delay = (displayedItems.length + index) * 300;
            setTimeout(() => {
                setDisplayedItems((prevItems) => [...prevItems, item]);
            }, delay);
        });
    }, [distinctAvailableDataItems]);

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            disableEscapeKeyDown
            onClose={(event, reason) => {}}
            open={modalOpen}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: modalOpacity,
                transition: 'opacity 1.0s ease-in',
                outline: 'none',
                paddingTop: 'env(safe-area-inset-top)',
                paddingBottom: 'env(safe-area-inset-bottom)',
            }}
            slotProps={{
                backdrop: {
                    style: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                },
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: '90%',
                    maxWidth: 450,
                    height: '90vh',
                    maxHeight: '450px',
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                    overflow: 'hidden',
                    '&:focus-visible': {
                        outline: 'none',
                    },
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <PocketGpLogoHeader />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0,
                        }}
                    >
                        <Typography level="h3">Loading your health data...</Typography>
                        <Typography level="body-sm">
                            This could take up to minute for longer records.
                        </Typography>
                    </Box>
                    <LinearProgress
                        variant="soft"
                        size="sm"
                        sx={{
                            width: '80%',
                        }}
                    />
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0.5}
                        sx={{width: '100%'}}
                    >
                        {displayedItems
                            .filter((item) => item !== 'None')
                            .slice() // Create a shallow copy for sorting
                            .sort(
                                (a, b) =>
                                    displayOrder.indexOf(a) - displayOrder.indexOf(b),
                            )
                            .map((item) => (
                                <DataItemCard
                                    key={item}
                                    icon={loadingMessageMapping[item].icon}
                                    text={loadingMessageMapping[item].message}
                                />
                            ))}
                        <DataItemCard
                            icon={<SearchIcon />}
                            text="Finding your medical record..."
                        />
                    </Stack>
                </Stack>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '40%',
                        borderRadius: 'md', // Match the borderRadius of the Sheet
                        backgroundImage:
                            /* eslint-disable max-len */
                            colourMode == 'light'
                                ? 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))'
                                : 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))',
                        /* eslint-enable max-len */
                        zIndex: 2, // Add a z-index higher than the content
                    }}
                />
            </Sheet>
        </Modal>
    );
};

export default LoadingMedicalRecordModal;
