import React from 'react';
import ResponsiveAppBar from './ResponsiveAppBar';

const AppHeader = (props) => {
    return (
        <header
            style={{
                backgroundColor: '#241C70',
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}
        >
            {/* <nav> */}
            <ResponsiveAppBar {...props} />
            {/* </nav> */}
        </header>
    );
};

export default AppHeader;
