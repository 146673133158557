/* eslint-disable max-len */
import {ApolloProvider} from '@apollo/client';
import '@fontsource/inter';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import PocketGpApp from 'PocketGpApp';
import {Hub} from 'aws-amplify/utils';
import iNoBounce from 'inobounce';
import {SnackbarProvider} from 'notistack';
import React, {useEffect, useState} from 'react';
import LoginLoadingPage from 'routes/Login/LoginLoadingPage';
import SurgeriesModal from 'routes/Login/components/SurgeriesModal';
import {createApolloClient} from 'utilities/apolloClient';
import {CssProvidersWrapper} from 'utilities/themeUtilities';
import UserProvider from './contexts/UserProvider';
import LoginPage from './routes/Login/LoginPage';
import {getIdToken} from './utilities/loginUtils';
/* eslint-enable max-len */

/**
 * Root component that handles authentication and renders the appropriate content based
 * on the user's authentication status and GP credentials availability.
 *
 * @component
 * @param {Object} props - Props for the Root component.
 * @return {JSX.Element} JSX element representing the Root component.
 */
const Root = () => {
    const [surgeriesModalOpen, setSurgeriesModalOpen] = useState(false);
    const [token, setToken] = useState(null);
    const [apolloClient, setApolloClient] = useState(createApolloClient());
    const [loginAction, setLoginAction] = useState(null);
    const [amplifyError, setAmplifyError] = useState(null);

    iNoBounce.disable();

    // Handlers
    const handleCloseSurgeriesModal = () => {
        setSurgeriesModalOpen(false);
    };

    useEffect(() => {
        // On first load, check whether we are already logged in and have an id token.
        getIdToken().then((idToken) => {
            if (idToken) {
                setToken(idToken);
                setLoginAction(null);

                setApolloClient(createApolloClient());
            }
        });
    }, []);

    // Handle events from AWS Amplify regarding Authentication.
    useEffect(() => {
        Hub.listen('auth', ({payload: {event, data}}) => {
            switch (event) {
                case 'signedIn':
                    getIdToken().then((idToken) => {
                        if (idToken) {
                            setToken(idToken);
                            setLoginAction(null);

                            setApolloClient(createApolloClient());
                        }
                    });
                    break;
                case 'signedOut':
                case 'signOut':
                    document.getElementById('themeColor').removeAttribute('content');
                    setLoginAction(null);
                    setToken(null);
                    break;
                case 'signIn_failure':
                    setToken(null);
                    setLoginAction(null);
                    break;
                case 'cognitoHostedUI_failure':
                    setToken(null);
                    setLoginAction(null);
                    setAmplifyError(data);
                    console.error('Sign in failure', data);
                    break;
                default:
                    break;
            }
        });
    }, []);

    /**
     * React useEffect hook that checks for the presence of a 'code' query parameter
     * in the URL, which means that we are logging the user in having received a
     * callback from NHS Login.
     *
     * This hook runs once, when the component mounts.
     *
     * @param {Function} setLoginAction - A function to set the login action based on
     *                                    the query parameter.
     * @return {void}
     */
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        if (queryParameters.has('code')) {
            setLoginAction('login');
        }
    }, []);

    const customSnackbarIcons = {
        success: <CheckCircleOutlineOutlinedIcon />,
        error: <ErrorOutlineRoundedIcon />,
        warning: <WarningAmberOutlinedIcon />,
        info: <InfoOutlinedIcon />,
    };

    /* eslint-disable max-len */
    // NOTE: There is a sequence of login page, logging in, then loading screen. This is due to the app querying to see if it has a token, before then logging in. The solution to this would be to have a generic loading page to decide what to do before then showing login / logging in / loading app.
    /* eslint-enable max-len */

    return (
        <CssProvidersWrapper>
            <SnackbarProvider maxSnack={5} iconVariant={customSnackbarIcons}>
                <ApolloProvider client={apolloClient}>
                    <SurgeriesModal
                        open={surgeriesModalOpen}
                        handleCloseSurgeriesModal={handleCloseSurgeriesModal}
                    />
                    {token && !loginAction && (
                        <UserProvider decodedToken={token.payload}>
                            <PocketGpApp
                                token={token}
                                setToken={setToken}
                                apolloClient={apolloClient}
                                loginAction={loginAction}
                                setLoginAction={setLoginAction}
                            />
                        </UserProvider>
                    )}
                    {loginAction && <LoginLoadingPage action={loginAction} />}
                    {!token && !loginAction && (
                        <CssProvidersWrapper>
                            <LoginPage
                                error={amplifyError}
                                setSurgeriesModalOpen={setSurgeriesModalOpen}
                            />
                        </CssProvidersWrapper>
                    )}
                </ApolloProvider>
            </SnackbarProvider>
        </CssProvidersWrapper>
    );
};

export default Root;

Root.propTypes = {};
