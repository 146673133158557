import {Avatar, Box, Divider, Typography} from '@mui/joy';

import PropTypes from 'prop-types';
import React from 'react';
import BreadcrumbComponent from './components/BreadcrumbComponent';

const PageHeader = ({
    title,
    subtitle,
    breadcrumbs,
    buttons,
    icon,
    iconAlt,
    dividerPadding = 4,
}) => {
    return (
        <Box sx={{width: '100%', mt: 1}}>
            {breadcrumbs !== null && <BreadcrumbComponent breadcrumbs={breadcrumbs} />}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    my: 2,
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            alt={iconAlt}
                            color="primary"
                            size="lg"
                            sx={{display: {xs: 'none', sm: 'flex'}}}
                        >
                            {icon}
                        </Avatar>
                        <Avatar
                            alt={iconAlt}
                            color="primary"
                            size="md"
                            sx={{display: {xs: 'flex', sm: 'none'}}}
                        >
                            {icon}
                        </Avatar>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 2,
                            }}
                        >
                            <Typography level="h4">{title}</Typography>
                            <Typography>{subtitle}</Typography>
                        </Box>
                    </Box>
                    {buttons}
                </Box>
            </Box>
            <Divider
                variant="middle"
                sx={{
                    mb: {dividerPadding},
                }}
            />
        </Box>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    breadcrumbs: PropTypes.array,
    dividerPadding: PropTypes.number,
    icon: PropTypes.element,
    iconAlt: PropTypes.string,
    buttons: PropTypes.element,
};

export default PageHeader;
