import {styled} from '@mui/joy';
import {signInWithRedirect} from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import React from 'react';

const nhsStyles = {
    backgroundColor: '#005eb8',
    border: '2px solid transparent',
    borderRadius: '4px',
    boxShadow: '0 4px 0 #003087',
    boxSizing: 'border-box',
    color: '#fff',
    cursor: 'pointer',
    display: 'inline-block',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 28,
    padding: '12px 16px',
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'top',
    width: 'auto',
    textDecoration: 'none',

    '@media (min-width: 40.0625em)': {
        fontSize: '1.1875rem',
        lineHeight: 1.47368,
        marginBottom: 36,
    },
    '@media print': {
        fontSize: '14pt',
        lineHeight: 1.15,
    },
    '@media (max-width: 40.0525em)': {
        padding: '8px 16px',
    },
    '&:link, &:visited, &:active, &:hover': {
        color: '#fff',
        textDecoration: 'none',
    },
    '&::-moz-focus-inner': {
        border: 0,
        padding: 0,
    },
    '&:hover': {
        backgroundColor: '#1e569b',
    },
    '&:focus': {
        background: '#ffeb3b',
        boxShadow: '0 4px 0 #003087',
        color: '#003087',
        outline: 'none',
    },
    '&:active': {
        background: '#1e569b',
        boxShadow: 'none',
        color: '#fff',
        top: '4px',
    },
    '&::before': {
        background: 'transparent',
        bottom: '-6px',
        content: '""',
        display: 'block',
        left: '-2px',
        position: 'absolute',
        right: '-2px',
        top: '-2px',
    },
    '&:active::before': {
        top: '-6px',
    },
    '&.nhslogin-button--reverse': {
        backgroundColor: '#fff',
        boxShadow: '0 4px 0 #003087',
        color: '#005eb8',
        '&:hover': {
            backgroundColor: '#f2f2f2',
            color: '#005eb8',
        },
        '&:focus': {
            background: '#ffeb3b',
            boxShadow: '0 4px 0 #003087',
            color: '#003087',
            outline: '4px solid transparent',
        },
        '&:active': {
            background: '#f2f2f2',
            boxShadow: 'none',
            color: '#005eb8',
            top: '4px',
        },
        '&:link': {
            color: '#005eb8',
        },
        '&.nhsuk-button--disabled': {
            backgroundColor: '#fff',
        },
        '&.nhsuk-button--disabled:focus': {
            backgroundColor: '#fff',
        },
    },
    '&.nhslogin-button--disabled, &[disabled="disabled"], &[disabled]': {
        backgroundColor: '#005eb8',
        opacity: 0.5,
        pointerEvents: 'none',
        '&:hover': {
            backgroundColor: '#005eb8',
            cursor: 'default',
        },
        '&:focus': {
            backgroundColor: '#005eb8',
            outline: 'none',
        },
        '&:active': {
            boxShadow: '0 4px 0 #003087',
            top: 0,
        },
    },
    // eslint-disable-next-line max-len
    '&.nhslogin-button--reverse[disabled="disabled"], &.nhslogin-button--reverse[disabled]':
        {
            backgroundColor: '#fff',
            opacity: 0.5,
            '&:hover': {
                backgroundColor: '#fff',
                cursor: 'default',
            },
            '&:focus': {
                outline: 'none',
            },
            '&:active': {
                boxShadow: '0 4px 0 #003087',
                top: 0,
            },
        },
    '&.ie8 &:focus': {
        outline: '1px dotted #003087',
    },
    '&.nhslogin-button-logo': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.button-logo': {
        paddingRight: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

const NHSLoginButton = styled('button')(({theme}) => ({
    ...nhsStyles,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
}));

const NHSLoginComponent = ({maintenanceMode}) => {
    const handleButtonClick = async () => {
        await signInWithRedirect({
            provider: {
                custom: 'NHSLogin',
            },
        });
    };

    const buttonRef = React.useRef(null);
    const [padding, setPadding] = React.useState('16px');

    React.useEffect(() => {
        const calculatePadding = () => {
            if (buttonRef.current) {
                const height = buttonRef.current.offsetHeight;
                const calculatedPadding = Math.max(16, height / 2) + 'px';
                setPadding(calculatedPadding);
            }
        };

        calculatePadding();
        window.addEventListener('resize', calculatePadding);

        return () => {
            window.removeEventListener('resize', calculatePadding);
        };
    }, []);

    return (
        <NHSLoginButton
            ref={buttonRef}
            style={{margin: padding}}
            onClick={handleButtonClick}
            title={process.env.REACT_APP_NHS_LOGIN_DOMAIN_URL}
            disabled={maintenanceMode}
        >
            <div className="button-logo">
                <svg
                    className="nhsuk-logo"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 16"
                    height="27"
                    width="67"
                >
                    <path
                        className="nhsuk-logo__background"
                        fill="#fff"
                        d="M0 0h40v16H0z"
                    />
                    <path
                        className="nhsuk-logo__text"
                        fill="#005eb8"
                        // eslint-disable-next-line max-len
                        d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
                    />
                </svg>
            </div>
            Continue to NHS login
        </NHSLoginButton>
    );
};

NHSLoginComponent.propTypes = {
    maintenanceMode: PropTypes.bool,
};

export default NHSLoginComponent;
