import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {Alert, Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';

const Action = ({action, level, title, body}) => {
    const getColorFromLevel = (level) => {
        switch (level) {
            case 'emergency':
                return 'danger';
            case 'info':
                return 'primary';
            case 'warning':
                return 'primary';
            case 'success':
                return 'success';
            default:
                return 'neutral';
        }
    };
    // TODO: Simplify the logic here, it's too spread out into different parts

    switch (action) {
        case 'display_information':
            return (
                <Alert
                    color={getColorFromLevel(level)}
                    variant={level != 'info' ? 'solid' : 'outlined'}
                    startDecorator={<ErrorOutlineOutlinedIcon />}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography
                            color="white"
                            level="title-lg"
                            component="div"
                            gutterBottom
                        >
                            <PlainMarkdown>{title}</PlainMarkdown>
                        </Typography>
                        <Typography
                            color="white"
                            sx={{whiteSpace: 'pre-line'}}
                            component="div"
                        >
                            <StyledMarkdown>{body}</StyledMarkdown>
                        </Typography>
                    </Box>
                </Alert>
            );
        case 'continue':
            break;
    }
};

Action.propTypes = {
    action: PropTypes.string.isRequired,
    level: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
};

export default Action;
