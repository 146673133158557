import {
    Box,
    Grid,
    Skeleton,
    Tab,
    TabList,
    TabPanel,
    Tabs,
    Typography,
    useTheme,
} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import SkeletonCard from './SkeletonCard';

const SummarySection = ({hidden = false}) => {
    const theme = useTheme();

    return (
        <Box sx={{display: hidden ? 'none' : 'block', width: '100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                    <Typography level="title-lg">Loading your conditions...</Typography>
                    <Typography level="body-sm" component="div">
                        Please wait whilst we load the conditions from your medical
                        record.
                    </Typography>
                </Box>
            </Box>
            <Tabs
                aria-label="Loading Health Summary Information Section Tabs"
                sx={{my: 1}}
            >
                <TabList
                    sx={{
                        pb: '2px',
                        borderRadius: 0,
                        overflowX: 'auto',
                        overflowY: 'hidden',
                        scrollSnapType: 'x mandatory',
                        '&::-webkit-scrollbar': {display: 'none'},
                    }}
                >
                    {Array(3)
                        .fill(0)
                        .map((_, index) => (
                            <Tab
                                key={index}
                                color="primary"
                                disabled={true}
                                sx={{
                                    '&.Mui-selected': {
                                        // eslint-disable-next-line max-len
                                        border: `1px solid rgba(${theme.vars.palette.primary[500]}, 0.9)`,
                                        backgroundColor: 'inherit',
                                        color: theme.vars.palette.primary[500],
                                        '&::after': {
                                            opacity: 1,
                                            transition: 'opacity 0.5s ease-in-out',
                                        },
                                    },
                                    '&:active': {
                                        bgcolor: 'inherit',
                                    },
                                    '&::after': {
                                        opacity: 0,
                                        transition: 'opacity 0.5s ease-in-out',
                                    },
                                    '&:not(.Mui-selected):hover': {
                                        bgcolor: 'inherit',
                                    },
                                }}
                            >
                                <Typography level="title-sm" color="inherit">
                                    <Skeleton>Overview</Skeleton>
                                </Typography>
                            </Tab>
                        ))}
                </TabList>
                <TabPanel value={0} sx={{width: '100%', height: '100%', px: 0}}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Grid container direction="row" spacing={2} sx={{m: 0}}>
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                }}
                            >
                                <SkeletonCard />
                            </Grid>
                            <Grid
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                }}
                            >
                                <SkeletonCard />
                            </Grid>
                        </Grid>
                    </Box>
                </TabPanel>
            </Tabs>
        </Box>
    );
};

SummarySection.propTypes = {
    hidden: propTypes.bool,
};

export default SummarySection;
