import {Box, Card, CardActions, CardContent, Skeleton} from '@mui/joy';
import React from 'react';

const PharmacyCardSkeleton = () => {
    return (
        <Card
            sx={{
                width: '100%',
                minWidth: 250,
                maxWidth: 450,
            }}
            variant="outlined"
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 1,
                }}
            >
                {/* Header */}
                <Box>
                    <Skeleton variant="text" level="title-lg"></Skeleton>
                    <Skeleton variant="text" level="body-sm"></Skeleton>
                </Box>

                {/* Address or Internet Pharmacy */}
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Skeleton variant="text" level="body-sm" width={150} />
                    <Skeleton variant="text" level="body-sm" width={150} />
                    <Skeleton variant="text" level="body-sm" width={150} />
                    <Skeleton variant="text" level="body-sm" width={150} />
                </Box>
            </CardContent>
            <CardActions sx={{justifyContent: 'flex-end'}}>
                <Skeleton variant="rectangular" width={100} height={36} />
            </CardActions>
        </Card>
    );
};

export default PharmacyCardSkeleton;
