import {Link} from '@mui/joy';
import {fetchAuthSession} from 'aws-amplify/auth';
import React from 'react';

export const getIdToken = async () => {
    const {tokens} = await fetchAuthSession();

    if (tokens) {
        const {idToken} = tokens;

        return idToken;
    }
    return null;
};

export const getAccessToken = async () => {
    const {tokens} = await fetchAuthSession();

    if (tokens) {
        const {accessToken} = tokens;

        return accessToken;
    }
    return null;
};

export const forceTokenRefresh = async () => {
    const {tokens} = await fetchAuthSession({forceRefresh: true});

    if (tokens) {
        const {idToken} = tokens;

        return idToken;
    }
    return null;
};

/**
 * Processes query parameters to handle error descriptions related to NHS login.
 * This function extracts the error description from the query parameters and
 * processes it to generate a title and description for the specific error type.
 *
 * @param {URLSearchParams} queryParameters - The query parameters containing the error
 *   description.
 * @return {Object | null} An object with the title and description of the error,
 *                          or null if there is no error description in the query
 *                          parameters.
 */
export function handleErrorCallback(queryParameters) {
    const errorDescription = queryParameters.get('error_description');

    if (errorDescription) {
        console.error('An error occurred whilst logging you in: ', errorDescription);
        console.error('Query params: ', queryParameters);
    }

    if (errorDescription) {
        // Process the error description to get title and description
        let title = '';
        let description = '';

        if (errorDescription.startsWith('ConsentNotGiven;')) {
            title = 'You cannot continue without sharing your information';
            description = (
                <>
                    <p>
                        PocketGP needs your NHS login information to verify your
                        identity.
                    </p>
                    <br />
                    <strong>
                        Please login again, and provide consent if you wish to access
                        PocketGP.
                    </strong>
                    <p>
                        If you need medical help, go to{' '}
                        <Link
                            variant="plain"
                            href="https://111.nhs.uk/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            111.nhs.uk
                        </Link>{' '}
                        or call{' '}
                        <Link variant="plain" href="tel:111">
                            111
                        </Link>{' '}
                        or your GP.
                    </p>
                    <p>
                        Call{' '}
                        <Link variant="plain" href="tel:999">
                            999
                        </Link>{' '}
                        if it&apos;s a life-threatening emergency.
                    </p>
                </>
            );
        } else if (errorDescription.startsWith('request_scope_invalid;')) {
            title = 'An error occurred whilst logging you in';
            description = (
                <p>
                    PocketGP failed to log you in with NHS login. Please try logging in
                    again, otherwise, please email{' '}
                    <Link variant="plain" href="mailto:support@pocketgp.app">
                        support@pocketgp.app
                    </Link>{' '}
                    for assistance.
                </p>
            );
        } else if (errorDescription.includes('The user is under 18 years old.')) {
            title = 'You are not allowed to access PocketGP';
            description = (
                <>
                    <p>Pocket GP is not available to users under 18 years old.</p>
                    <p>
                        If you do not believe this to be the case, please check your
                        registered Date of Birth with NHS login and your GP Surgery.
                    </p>
                    <p>
                        If after that, you still encounter this error, please email{' '}
                        <Link variant="plain" href="mailto:support@pocketgp.app">
                            support@pocketgp.app
                        </Link>{' '}
                        for assistance.
                    </p>
                </>
            );
        } else {
            title = 'There was an error logging you in';
            description = (
                <>
                    <p>
                        There was an error logging you in. Please try logging in again.
                    </p>
                    <p>Error Description: {errorDescription}</p>
                    <p>
                        If you still encounter this error, please email{' '}
                        <Link variant="plain" href="mailto:support@pocketgp.app">
                            support@pocketgp.app
                        </Link>{' '}
                        for assistance.
                    </p>
                </>
            );
        }

        return {title, description};
    }

    // If no error_description, return null to indicate no error
    return null;
}

/**
 * Generates display code for Amplify errors by returning an error message and
 * description.
 *
 * @function
 * @name handleAmplifyError
 * @module
 * @exports handleAmplifyError
 *
 * @param {Error} error - The error object from Amplify.
 *
 * @return {Object|null} An object containing a title and a description (React
 *                       components) or null if no error.
 * @property {string} title - The error title.
 * @property {JSX.Element} description - A React JSX component containing the error
 *                                       description.
 *
 * @example
 * const err = new Error('Failed to authenticate.');
 * const errorInfo = handleAmplifyError(err);
 * console.log(errorInfo.title); // Outputs:
 *     "Error: An error occured whilst trying to log you in"
 *
 */
export function handleAmplifyError(error) {
    let title = '';
    let description = '';

    if (error) {
        title = 'Error: An error occured whilst trying to log you in';
        description = (
            <>
                <p>{String(error)}</p>
                <p>
                    If you still encounter this error, please email{' '}
                    {/* eslint-disable-next-line max-len */}
                    <Link href="mailto:support@pocketgp.app">
                        support@pocketgp.app
                    </Link>{' '}
                    for assistance.
                </p>
            </>
        );

        return {title, description};
    }
    return null;
}
