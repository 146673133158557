import {useMyLoadingProgress} from 'graphql/myLoadingProgress';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import ProgressContext from './ProgressContext';

export const ProgressProvider = ({children}) => {
    const {
        data: loadingData,
        stopPolling: stopPolling,
        networkStatus: networkStatus,
    } = useMyLoadingProgress(2000);

    const [availableData, setAvailableData] = useState([]);
    const [loadingProgress, setLoadingProgress] = useState(0);

    /**
     * When we get loading progress data retrievals, merge it into available data,
     * and update the loading progress.
     * */
    useEffect(() => {
        const tempAvailableData = [];
        let tempLoadingProgress = 0;

        const loadingProgressData = loadingData?.myLoadingProgress;

        if (loadingProgressData && loadingProgressData.length > 0) {
            loadingProgressData.forEach((item) => {
                if (item.availableData != 'None') {
                    tempAvailableData.push(item.availableData);
                }
                tempLoadingProgress = Math.max(tempLoadingProgress, item.progress);
            });
        }

        if (tempLoadingProgress === 100) {
            stopPolling();
        }

        const distinctAvailableDataItems = new Set([
            ...availableData,
            ...tempAvailableData,
        ]);
        setAvailableData([...distinctAvailableDataItems]);
        setLoadingProgress(tempLoadingProgress);
    }, [loadingData, networkStatus]);

    const value = useMemo(
        () => ({
            availableData,
            loadingProgress,
        }),
        [availableData, loadingProgress],
    );

    return (
        <ProgressContext.Provider value={value}>{children}</ProgressContext.Provider>
    );
};

ProgressProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export const useProgress = () => {
    const context = useContext(ProgressContext);
    if (!context) {
        throw new Error('useProgress must be used within a ProgressProvider');
    }

    return context;
};
