import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import {
    Box,
    Link,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Typography,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';

const TextItem = ({title, body, url, listItems}) => {
    return (
        <Box sx={{my: 1}}>
            {title && (
                <ListItem>
                    <ListItemContent>
                        <Typography level="title-md" component="div">
                            <PlainMarkdown>{title}</PlainMarkdown>
                        </Typography>
                    </ListItemContent>
                </ListItem>
            )}
            {body && (
                <ListItem>
                    <ListItemContent>
                        <Typography level="body-md" component="div">
                            <StyledMarkdown>{body}</StyledMarkdown>
                        </Typography>
                    </ListItemContent>
                </ListItem>
            )}
            {url && (
                <ListItem
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <ListItemDecorator>
                        <LinkRoundedIcon />
                    </ListItemDecorator>
                    <ListItemContent
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        <Link
                            variant="plain"
                            color="neutral"
                            href={url}
                            target="_blank"
                            sx={{
                                my: 1,
                                ml: 2,
                                borderRadius: 'xs',
                            }}
                        >
                            {url}
                        </Link>
                    </ListItemContent>
                </ListItem>
            )}
            {listItems?.length > 0 && (
                <ListItem>
                    <ListItemContent>
                        <List sx={{mt: 0}} size="sm">
                            {listItems.map((item, index) => (
                                <ListItem key={index} sx={{alignItems: 'flex-start'}}>
                                    <ListItemDecorator>
                                        <ChevronRightRoundedIcon />
                                    </ListItemDecorator>
                                    <ListItemContent>
                                        <Typography
                                            level="body-md"
                                            sx={{ml: 2}}
                                            component="div"
                                        >
                                            <PlainMarkdown>{item}</PlainMarkdown>
                                        </Typography>
                                    </ListItemContent>
                                </ListItem>
                            ))}
                        </List>
                    </ListItemContent>
                </ListItem>
            )}
        </Box>
    );
};

export default TextItem;

TextItem.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    url: PropTypes.string,
    listItems: PropTypes.arrayOf(PropTypes.string),
};
