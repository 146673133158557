import {Box} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import FormSectionHeader from './FormSectionHeader';

/**
 * FormSection component displays a form section with a title, supporting text, optional
 * supporting content, and a body.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title text.
 * @param {string} props.supportingText - Supporting text displayed below the title.
 * @param {React.Element} [props.supportingContent] - Optional JSX content displayed
 * below the supporting text.
 * @param {React.Element} props.body - The main content of the form section.
 * @return {React.Element}
 */
const FormSection = ({title, supportingText, supportingContent, children}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row'},
                gap: {xs: 2, sm: 4},
                justifyContent: 'space-between',
                maxHeight: '100%',
            }}
        >
            <>
                <FormSectionHeader
                    title={title}
                    supportingText={supportingText}
                    supportingContent={supportingContent}
                />
                <Box sx={{height: '100%', width: '100%'}}>{children}</Box>
            </>
        </Box>
    );
};

FormSection.propTypes = {
    title: PropTypes.string.isRequired,
    supportingText: PropTypes.string,
    supportingContent: PropTypes.element,
    children: PropTypes.element,
};

export default FormSection;
