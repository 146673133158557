/* eslint-disable indent */
/* eslint-disable max-len */
import {ApolloError} from '@apollo/client';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import {Box, Divider, Grid, Typography, useTheme} from '@mui/joy';
import iNoBounce from 'inobounce';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import MedicationCardSkeleton from '../components/MedicationCardSkeleton';
import MedicationHistoryPanel from './MedicationHistoryPanel';
import MedicationInfoPanel from './MedicationInfoPanel';
import PastMedicationsYearBlock from './components/PastMedicationsYearBlock';
/* eslint-enable max-len */

/**
 * A custom action card component displaying a table of the patient's repeat
 * medication
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {Object} props.medicationData - The data for the table rows.
 * @param {Function} props.handleOrderMedication - Handler for the order button.
 * @return {JSX.Element} - The JSX element representing the custom ActionCard
 *   component.
 */
const PastMedicationDisplay = ({medicationData}) => {
    const theme = useTheme();
    const [infoPanelSource, setInfoPanelSource] = useState(null);
    const [infoPanelContents, setInfoPanelContents] = useState(null);
    const [medicationHistoryPanelContents, setMedicationHistoryPanelContents] =
        useState(null);
    const [medications, setMedications] = useState(new Map());

    useEffect(() => {
        if (
            (infoPanelContents || medicationHistoryPanelContents) &&
            iNoBounce.isScrollSupported
        ) {
            iNoBounce.enable();
        } else {
            iNoBounce.disable();
        }
    }, [infoPanelContents, medicationHistoryPanelContents]);
    useEffect(() => {
        if (
            medicationData?.data?.me?.pastMedication?.length > 0 &&
            !medicationData.loading &&
            !medicationData.error &&
            !medicationData.waitingForData
        ) {
            const sortedMedicationData = [
                ...medicationData.data.me.pastMedication,
            ].sort((a, b) => {
                const dateA = new Date(a.latestPrescription);
                const dateB = new Date(b.latestPrescription);
                return dateB - dateA; // Sort in descending order
            });

            const newMedication = new Map();
            sortedMedicationData.forEach((medicationItem) => {
                if (Array.isArray(medicationItem.prescriptions)) {
                    // Create a shallow copy of the prescriptions array before sorting
                    const sortedPrescriptions = [...medicationItem.prescriptions].sort(
                        (a, b) => {
                            const prescriptionDateA = new Date(a.date);
                            const prescriptionDateB = new Date(b.date);
                            return prescriptionDateB - prescriptionDateA; // Desc order
                        },
                    );

                    // Replace the original prescriptions array with the sorted one
                    medicationItem = {
                        ...medicationItem,
                        prescriptions: sortedPrescriptions,
                    };
                }

                newMedication.set(medicationItem.medicineName, {...medicationItem});
            });
            setMedications(newMedication);
        }
    }, [medicationData]);

    useEffect(() => {
        if (infoPanelSource) {
            const medication = medications.get(infoPanelSource.medicineId);

            if (medication?.medicationInformation?.length > 0) {
                if (medication.medicationInformation?.length === 1) {
                    setInfoPanelContents(medication.medicationInformation[0]);
                } else {
                    const informationPage = medication.medicationInformation.find(
                        (item) =>
                            item.informationPageName ===
                            infoPanelSource.informationPageName,
                    );
                    if (informationPage) {
                        setInfoPanelContents(informationPage);
                    }
                }
            }
        }
    }, [infoPanelSource]);

    const handleShowInfo = (medicineName, informationPageName) => {
        setInfoPanelSource({
            medicineId: medicineName,
            informationPageName: informationPageName,
        });
    };

    const handleShowHistory = (medicineName) => {
        setMedicationHistoryPanelContents({
            pageName: medicineName,
            content: {pastOrders: medications.get(medicineName)?.prescriptions},
        });
    };

    const displayGrid = () => {
        if (
            !medicationData.loading &&
            !medicationData.error &&
            !medicationData.waitingForData
        ) {
            if (medications.size > 0) {
                const medicationDataArray = Array.from(medications.values());

                // Transform array into a dictionary with year as key
                const medicationByYear = medicationDataArray.reduce(
                    (acc, medication) => {
                        const year = new Date(
                            medication.latestPrescription,
                        ).getFullYear();
                        if (!acc[year]) {
                            acc[year] = [];
                        }
                        acc[year].push(medication);
                        // Sort medications by name within each year
                        acc[year].sort((a, b) =>
                            a.medicineName.localeCompare(b.medicineName),
                        );
                        return acc;
                    },
                    {},
                );

                // Sort dictionary by keys (years) in descending order
                const sortedMedicationByYear = Object.entries(medicationByYear)
                    .sort(([a], [b]) => b - a)
                    .reduce((acc, [year, medications]) => {
                        acc[year] = medications;
                        return acc;
                    }, {});

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                            width: '100%',
                        }}
                    >
                        {Object.entries(sortedMedicationByYear)
                            .sort(([a], [b]) => b - a)
                            .map(([year, medications]) => (
                                <Box key={year}>
                                    <Typography level="title-lg">{year}</Typography>
                                    <Divider sx={{mb: 2}} />
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        gridAutoRows="1fr"
                                        spacing={{xs: 3, md: 3}}
                                        sx={{
                                            mx: 'auto',
                                        }}
                                    >
                                        <PastMedicationsYearBlock
                                            medications={medications}
                                            handleShowHistory={handleShowHistory}
                                            handleShowInfo={handleShowInfo}
                                        />
                                    </Grid>
                                </Box>
                            ))}
                    </Box>
                );
            }
        } else {
            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    gridAutoRows="1fr"
                    spacing={{xs: 3, md: 3}}
                    sx={{
                        mx: 'auto',
                    }}
                >
                    {Array.from({length: 4}).map((_, index) => (
                        <Grid
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            key={index} // NOSONAR
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <MedicationCardSkeleton />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            );
        }
    };

    return (
        <Box
            sx={{
                gap: 2,
                mb: 4,
            }}
        >
            <MedicationInfoPanel
                open={infoPanelContents !== null}
                setInfoPanelContents={setInfoPanelContents}
                medicationName={infoPanelContents?.informationPageName}
                infoPanelContents={infoPanelContents?.informationDisplayContent}
            />
            <MedicationHistoryPanel
                open={medicationHistoryPanelContents !== null}
                setMedicationHistoryPanelContents={setMedicationHistoryPanelContents}
                medicationName={medicationHistoryPanelContents?.pageName}
                medicationHistoryPanelContents={medicationHistoryPanelContents?.content}
            />
            {!medicationData.loading &&
                !medicationData.error &&
                !medicationData.waitingForData &&
                medicationData?.data?.me?.pastMedication?.length === 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            my: 8,
                        }}
                    >
                        <Box
                            sx={{
                                width: 42,
                                height: 42,
                                border: `1px solid ${theme.vars.palette.neutral[300]}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 8,
                                boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                            }}
                        >
                            <MedicationRoundedIcon color="primary" fontSize="large" />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography level="title-md" sx={{textAlign: 'center'}}>
                                No previous medications found
                            </Typography>
                            <Typography level="body-md" sx={{textAlign: 'center'}}>
                                It looks like you don&apos;t have any previous
                                medications.
                            </Typography>
                        </Box>
                    </Box>
                )}
            {displayGrid()}
        </Box>
    );
};

PastMedicationDisplay.propTypes = {
    medicationData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        error: PropTypes.instanceOf(ApolloError),
        data: PropTypes.shape({
            me: PropTypes.shape({
                pastMedication: PropTypes.arrayOf(PropTypes.shape({})),
            }),
        }).isRequired,
        waitingForData: PropTypes.bool.isRequired,
    }),
};

export default PastMedicationDisplay;
