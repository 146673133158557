/* eslint-disable max-len */
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import {Alert, Box, Card, Chip, Typography} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
/* eslint-enable max-len */

const ActionCard = ({title, description, requiredAction, actionType}) => {
    let colour = 'primary';
    if (actionType === 'REQUIRED') {
        colour = 'warning';
    } else if (actionType === 'DONE') {
        colour = 'success';
    }

    return (
        <Card variant="outlined" sx={{width: '100%', maxWidth: '400px'}} color={colour}>
            {actionType === 'REQUIRED' && (
                <Chip variant="soft" color="warning">
                    Action Required
                </Chip>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1,
                }}
            >
                {actionType === 'DONE' && (
                    <AssignmentTurnedInRoundedIcon color={colour} fontSize="small" />
                )}
                {actionType === 'REQUIRED' && (
                    <PendingActionsRoundedIcon color={colour} fontSize="small" />
                )}
                <Typography level="title-sm" color={colour} component="div">
                    <PlainMarkdown>{title}</PlainMarkdown>
                </Typography>
            </Box>
            {description && (
                <Typography level="body-sm" component="div">
                    <StyledMarkdown>{description}</StyledMarkdown>
                </Typography>
            )}
            {requiredAction?.instruction && (
                <Alert
                    color={colour}
                    variant="outlined"
                    startDecorator={<AssignmentLateRoundedIcon />}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography color={colour} level="title-sm">
                            Next Steps:
                        </Typography>
                        <Typography level="body-sm" component="div">
                            <StyledMarkdown>
                                {requiredAction.instruction}
                            </StyledMarkdown>
                        </Typography>
                    </Box>
                </Alert>
            )}
        </Card>
    );
};
ActionCard.propTypes = {
    title: propTypes.string.isRequired,
    description: propTypes.string,
    actionType: propTypes.string,
    requiredAction: propTypes.shape({
        instruction: propTypes.string,
    }),
};

export default ActionCard;
