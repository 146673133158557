/* eslint-disable max-len */
import {ApolloError} from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    IconButton,
    LinearProgress,
    Modal,
    Sheet,
    Typography,
    useTheme,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import MedicationChecksStepBody from './medicationChecks/MedicationChecksStepBody';
import SubmitOrderStepBody from './submitOrder/SubmitOrderStepBody';

import {useInitiateMedicationOrder} from '../../../graphql/initiateMedicationOrder';

import iNoBounce from 'inobounce';
import PharmacyStepBody from './pharmacy/PharmacyStepBody';
/* eslint-enable max-len */

const componentOrder = ['pharmacy', 'medicationChecks', 'confirmOrder'];

const OrderMedicationModal = ({
    medicationData,
    selectedMedications,
    isModalOpen,
    handleCloseModal,
    handleCompleteMedicationOrder,
}) => {
    const theme = useTheme();

    const [loading, setLoading] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [skippedMedicationChecks, setSkippedMedicationChecks] = useState(false);
    const [noChecksFound, setNoChecksFound] = useState(false);
    const [stepperState, setStepperState] = useState({
        activeStep: componentOrder[0],
        skipped: [],
        stepsStack: [componentOrder[0]],
    });

    const {
        initiateOrder,
        loading: initiateOrderLoading,
        // error: initiateOrderError,
        data: initiateOrderData,
    } = useInitiateMedicationOrder();

    const [preferredPharmacy, setPreferredPharmacy] = useState(null);
    const [selectedPharmacy, setSelectedPharmacy] = useState(null);
    const [orderState, setOrderState] = useState({
        sessionParameters: {},
        checksComplete: false,
    });
    const [medicationCheckActions, setMedicationCheckActions] = useState([]);

    // Set preferredPharmacy once we have loaded the data
    useEffect(() => {
        if (medicationData.data?.me?.preferredPharmacy) {
            setPreferredPharmacy(medicationData.data.me.preferredPharmacy);
        }
    }, [medicationData.data?.me?.preferredPharmacy]);

    const resetProcess = (shouldInitiateOrder = false) => {
        setStepperState({
            activeStep: componentOrder[0],
            skipped: [],
            stepsStack: [componentOrder[0]],
        });
        setProgressValue(0);
        setSkippedMedicationChecks(false);
        setOrderState({
            sessionParameters: {},
            checksComplete: false,
        });
        setSelectedPharmacy(null);
        setMedicationCheckActions([]);

        if (shouldInitiateOrder) {
            initiateOrder({
                variables: {
                    requestedMedications: selectedMedications.map(
                        (dict) => dict.medicineId,
                    ),
                },
            });
        }
    };

    // If the modal is closed, reset the state.
    useEffect(() => {
        if (!isModalOpen || stepperState.activeStep === componentOrder[0]) {
            resetProcess(isModalOpen);
        }
    }, [stepperState.activeStep, isModalOpen]);

    // On open, initiate the order process
    useEffect(() => {
        if (isModalOpen) {
            if (iNoBounce.isScrollSupported) {
                iNoBounce.enable();
            }
        } else {
            resetProcess(false);
            if (iNoBounce.isScrollSupported) {
                iNoBounce.disable();
            }
        }
    }, [isModalOpen]);

    // Once we have data from the order initiation, store it
    useEffect(() => {
        if (initiateOrderData) {
            setOrderState((prevState) => ({
                ...prevState,
                sessionParameters:
                    initiateOrderData?.initiateMedicationOrder?.sessionParameters,
                checksComplete:
                    initiateOrderData?.initiateMedicationOrder?.checksComplete,
            }));
            if (initiateOrderData?.initiateMedicationOrder?.medicationCheckActions) {
                setMedicationCheckActions(
                    initiateOrderData?.initiateMedicationOrder?.medicationCheckActions,
                );
            }
            if (initiateOrderData?.initiateMedicationOrder?.checksComplete) {
                setNoChecksFound(
                    initiateOrderData?.initiateMedicationOrder?.checksComplete,
                );
            }
        }
    }, [initiateOrderData]);

    const handleNext = (nextStep) => {
        if (nextStep === 'medicationChecks' && orderState.checksComplete) {
            nextStep = 'confirmOrder';
        }

        if (nextStep === 'confirmOrder') {
            setProgressValue(95);
        }

        setStepperState((prevState) => ({
            ...prevState,
            activeStep: nextStep,
            stepsStack: [...prevState.stepsStack, nextStep],
        }));
    };

    const handleBack = () => {
        setStepperState((prevState) => {
            const newStack = prevState.stepsStack.slice(0, -1);
            const newActiveStep =
                newStack.length > 0 ? newStack[newStack.length - 1] : componentOrder[0];

            if (newActiveStep === 'medicationChecks') {
                if (noChecksFound === true) {
                    return {
                        activeStep: componentOrder[0],
                        skipped: [],
                        stepsStack: [componentOrder[0]],
                    };
                } else {
                    setOrderState({
                        sessionParameters: {},
                        checksComplete: false,
                    });
                    setMedicationCheckActions([]);
                    initiateOrder({
                        variables: {
                            requestedMedications: selectedMedications.map(
                                (dict) => dict.medicineId,
                            ),
                        },
                    });
                    setProgressValue(50);
                }
            }

            return {
                ...prevState,
                activeStep: newActiveStep,
                stepsStack: newStack,
            };
        });
    };

    const displayBody = () => {
        switch (stepperState.activeStep) {
            case 'pharmacy':
                return (
                    <PharmacyStepBody
                        scrollableRef={scrollableRef}
                        setProgressValue={setProgressValue}
                        preferredPharmacy={preferredPharmacy}
                        setPreferredPharmacy={setPreferredPharmacy}
                        setSelectedPharmacy={setSelectedPharmacy}
                        handleNext={handleNext}
                        setLoading={setLoading}
                    />
                );
            case 'medicationChecks':
                return (
                    <MedicationChecksStepBody
                        scrollableRef={scrollableRef}
                        loadingInitiateMedicationOrder={initiateOrderLoading}
                        medicationCheckActions={medicationCheckActions}
                        setMedicationCheckActions={setMedicationCheckActions}
                        selectedMedications={selectedMedications}
                        setOrderState={setOrderState}
                        setProgressValue={setProgressValue}
                        orderState={orderState}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        setLoading={setLoading}
                        setSkippedMedicationChecks={setSkippedMedicationChecks}
                    />
                );
            case 'confirmOrder':
                return (
                    <SubmitOrderStepBody
                        scrollableRef={scrollableRef}
                        orderState={orderState}
                        selectedMedications={selectedMedications}
                        selectedPharmacy={selectedPharmacy}
                        handleCompleteMedicationOrder={handleCompleteMedicationOrder}
                        handleBack={handleBack}
                        setLoading={setLoading}
                        skippedMedicationChecks={skippedMedicationChecks}
                    />
                );
            default:
                return null;
        }
    };

    const scrollableRef = React.useRef(null);

    return (
        <Modal
            open={isModalOpen}
            disableEscapeKeyDown={true}
            aria-labelledby="modal-medication-ordering"
            aria-describedby="modal-medication-ordering-description"
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Sheet
                    sx={{
                        width: '95%',
                        maxWidth: '750px',
                        height: '90%',
                        maxHeight: '900px',
                        position: 'absolute',
                        borderRadius: 'md',
                        top: '5%',
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        backgroundColor: theme.palette.background.body,
                    }}
                >
                    {loading && (
                        <LinearProgress
                            size="lg"
                            sx={{
                                width: '100%',
                                minHeight: 12,
                                maxHeight: 12,
                                '--LinearProgress-thickness': '12px',
                                color: 'primary.300',
                                zIndex: 1000,
                            }}
                        />
                    )}
                    {!loading && (
                        <LinearProgress
                            determinate
                            size="lg"
                            value={progressValue}
                            sx={{
                                width: '100%',
                                minHeight: 12,
                                maxHeight: 12,
                                '--LinearProgress-radius': '4px',
                                '--LinearProgress-thickness': '12px',
                                color: 'primary.300',
                                zIndex: 1000,
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            my: 2,
                            mx: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            position: 'sticky',
                            top: 0,
                        }}
                    >
                        <Typography id="modal-modal-title" level="h3">
                            Request a Prescription
                        </Typography>
                        <IconButton onClick={handleCloseModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            className="scrollable"
                            ref={scrollableRef}
                            sx={{
                                mx: 4,
                                yb: 2,
                                '--scrollable-background':
                                    theme.vars.palette.background.body,
                            }}
                        >
                            {displayBody()}
                        </Box>
                    </Box>
                </Sheet>
            </Box>
        </Modal>
    );
};

OrderMedicationModal.propTypes = {
    medicationData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        error: PropTypes.instanceOf(ApolloError),
        data: PropTypes.shape({
            me: PropTypes.shape({
                repeatMedication: PropTypes.arrayOf(
                    PropTypes.shape({
                        medicineId: PropTypes.string.isRequired,
                        medicineName: PropTypes.string.isRequired,
                        quantity: PropTypes.string,
                        dose: PropTypes.string,
                        lastIssued: PropTypes.string,
                        canBeRequested: PropTypes.bool.isRequired,
                        issueNumber: PropTypes.number,
                    }),
                ),
                preferredPharmacy: PropTypes.shape({
                    odsCode: PropTypes.string.isRequired,
                    organisationName: PropTypes.string.isRequired,
                    organisationType: PropTypes.string.isRequired,
                    url: PropTypes.string,
                    address1: PropTypes.string,
                    address2: PropTypes.string,
                    address3: PropTypes.string,
                    city: PropTypes.string,
                    county: PropTypes.string,
                    latitude: PropTypes.number,
                    longitude: PropTypes.number,
                    organisationSubType: PropTypes.string,
                    postcode: PropTypes.string.isRequired,
                }),
            }).isRequired,
        }),
    }).isRequired,
    selectedMedications: PropTypes.arrayOf(
        PropTypes.shape({
            medicineId: PropTypes.string.isRequired,
            medicineName: PropTypes.string.isRequired,
            quantity: PropTypes.string,
            dose: PropTypes.string,
            lastIssued: PropTypes.string,
            canBeRequested: PropTypes.bool.isRequired,
            issueNumber: PropTypes.number,
        }),
    ).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    handleCompleteMedicationOrder: PropTypes.func.isRequired,
};

export default OrderMedicationModal;
