import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import {
    Box,
    Card,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Typography,
} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';

const TextCard = ({title, body, listItems}) => {
    return (
        <Card variant="outlined" sx={{width: '100%', maxWidth: '400px'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <TextSnippetRoundedIcon color="primary" fontSize="small" />
                <Typography level="title-sm" color="primary" component="div">
                    <PlainMarkdown>{title}</PlainMarkdown>
                </Typography>
            </Box>
            {body && (
                <Typography level="body-sm" component="div">
                    <StyledMarkdown>{body}</StyledMarkdown>
                </Typography>
            )}
            {listItems && (
                <List
                    sx={{
                        mt: 0,
                    }}
                    size="sm"
                >
                    {listItems.map((item, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                alignItems: 'flex-start',
                            }}
                        >
                            <ListItemDecorator>
                                <ChevronRightRoundedIcon />
                            </ListItemDecorator>
                            <ListItemContent>
                                <Typography
                                    level="body-sm"
                                    sx={{
                                        ml: 2,
                                    }}
                                    component="div"
                                >
                                    <PlainMarkdown>{item}</PlainMarkdown>
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                    ))}
                </List>
            )}
        </Card>
    );
};
TextCard.propTypes = {
    title: propTypes.string.isRequired,
    body: propTypes.string,
    listItems: propTypes.array,
};

export default TextCard;
