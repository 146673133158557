/* eslint-disable max-len */
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import {
    AccordionDetails,
    AccordionGroup,
    Box,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    Sheet,
    Stack,
    Typography,
    useTheme,
} from '@mui/joy';
import Accordion, {accordionClasses} from '@mui/joy/Accordion';
import PropTypes from 'prop-types';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import {PlainMarkdown} from 'utilities/textUtilities';
import {
    DescriptionSection,
    LinksSection,
    SafetyChecksSection,
    SharedCareSection,
    VideosSection,
} from './components/SectionContent';
import SectionHeader from './components/SectionHeader';
import TextItem from './components/TextItem';

const MedicationInfoPanel = ({
    open,
    setInfoPanelContents,
    medicationName,
    infoPanelContents,
}) => {
    const theme = useTheme();
    const mode = getColourMode();

    let numPopulatedSections = 0;
    if (infoPanelContents) {
        numPopulatedSections = Object.values(infoPanelContents).filter(
            (value) => value !== null,
        ).length;
    }

    return (
        <Drawer
            open={open}
            onClose={() => {
                setInfoPanelContents(null);
            }}
            anchor="right"
            size="lg"
        >
            <Sheet
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 'md',
                    overflow: 'hidden',
                    pr: 'env(safe-area-inset-right)',
                    pb: 'env(safe-area-inset-bottom)',
                }}
            >
                {infoPanelContents && (
                    <>
                        <DialogTitle
                            sx={{
                                mb: 2,
                                paddingTop: 'max(16px, env(safe-area-inset-top))',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mr: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        px: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 40,
                                            maxHeight: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor:
                                                mode == 'light'
                                                    ? theme.palette.primary['100']
                                                    : theme.palette.primary['800'],
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MedicationRoundedIcon color="primary" />
                                    </Box>
                                    {medicationName}
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setInfoPanelContents(null);
                                    }}
                                    aria-label="close"
                                    sx={{
                                        maxHeight: 40,
                                        maxWidth: 40,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                paddingBottom: 'env(safe-area-inset-bottom)',
                                flexGrow: 1,
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                className="scrollable"
                                sx={{
                                    '--scrollable-background':
                                        theme.vars.palette.background.surface,
                                }}
                            >
                                {infoPanelContents.summary && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1,
                                            my: 1,
                                            mx: 2,
                                        }}
                                    >
                                        <Typography level="body-sm" component="div">
                                            <PlainMarkdown>
                                                {infoPanelContents.summary}
                                            </PlainMarkdown>
                                        </Typography>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {infoPanelContents.sharedCareDetails && (
                                            <SharedCareSection
                                                sharedCareDetails={
                                                    infoPanelContents.sharedCareDetails
                                                }
                                            />
                                        )}
                                        <AccordionGroup
                                            sx={{
                                                px: 1,
                                                [`& .${accordionClasses.root}`]: {
                                                    marginTop: '0.5rem',
                                                    transition: '0.2s ease',
                                                    '& button:not([aria-expanded="true"])':
                                                        {
                                                            transition: '0.2s ease',
                                                            paddingBottom: '0.625rem',
                                                        },
                                                    '& button:hover': {
                                                        background: 'transparent',
                                                    },
                                                },
                                                [`& .${accordionClasses.root}.${accordionClasses.expanded}`]:
                                                    {
                                                        borderRadius: 'md',
                                                        borderBottom: '1px solid',
                                                        borderColor:
                                                            'background.level2',
                                                    },
                                                '& [aria-expanded="true"]': {
                                                    boxShadow: (theme) =>
                                                        `inset 0 -1px 0 ${theme.vars.palette.divider}`,
                                                },
                                                width: '100%',
                                            }}
                                        >
                                            {infoPanelContents.description && (
                                                <DescriptionSection
                                                    medicationName={medicationName}
                                                    description={
                                                        infoPanelContents.description
                                                    }
                                                />
                                            )}
                                            {infoPanelContents.textContent && (
                                                <Accordion defaultExpanded={false}>
                                                    <SectionHeader
                                                        icon={<InfoOutlinedIcon />}
                                                    >
                                                        What should I know about{' '}
                                                        {medicationName}?
                                                    </SectionHeader>
                                                    <AccordionDetails>
                                                        <Stack gap={1} sx={{mt: 1}}>
                                                            {infoPanelContents.textContent.map(
                                                                (item, index) => {
                                                                    return (
                                                                        <TextItem
                                                                            key={index}
                                                                            {...item}
                                                                        />
                                                                    );
                                                                },
                                                            )}
                                                        </Stack>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                            {infoPanelContents?.links.length > 0 && (
                                                <LinksSection
                                                    links={infoPanelContents.links}
                                                    numPopulatedSections={
                                                        numPopulatedSections
                                                    }
                                                />
                                            )}
                                            {/* eslint-disable max-len */}
                                            {infoPanelContents.videos && (
                                                <VideosSection
                                                    videos={infoPanelContents.videos}
                                                />
                                            )}
                                            {infoPanelContents.safetyChecks && (
                                                <SafetyChecksSection
                                                    checks={
                                                        infoPanelContents.safetyChecks
                                                    }
                                                />
                                            )}
                                        </AccordionGroup>
                                    </Box>
                                </Box>
                            </Box>
                            {/* eslint-enable max-len */}
                        </DialogContent>
                    </>
                )}
            </Sheet>
        </Drawer>
    );
};

export default MedicationInfoPanel;

MedicationInfoPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    setInfoPanelContents: PropTypes.func.isRequired,
    medicationName: PropTypes.string,
    infoPanelContents: PropTypes.shape({
        summary: PropTypes.string,
        description: PropTypes.string,
        sharedCareDetails: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        textContent: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                body: PropTypes.string,
            }),
        ),
        links: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        videos: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            videos: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    youtubeUrl: PropTypes.string,
                }),
            ),
        }),
        safetyChecks: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            checks: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                }),
            ),
        }),
    }),
};
