import {Button, CircularProgress, Typography} from '@mui/joy';
import 'App.css';
import {signOut} from 'aws-amplify/auth';
import PocketGpLogoHeader from 'logos/PocketGpLogoHeader';
import {PropTypes} from 'prop-types';
import React from 'react';
import BackgroundSheet from './components/BackgroundSheet';
import LoginCard from './components/LoginCard';

const LoginLoadingPage = (props) => {
    let actionWord = '';

    if (props.action === 'logout') {
        actionWord = 'out';
    } else if (props.action === 'login') {
        actionWord = 'in';
    }

    return (
        <BackgroundSheet>
            <LoginCard>
                <PocketGpLogoHeader />
                <Typography
                    level="h3"
                    sx={{
                        my: 1,
                        mx: 2,
                    }}
                >
                    Logging you {actionWord}...
                </Typography>
                <CircularProgress sx={{m: 2}} />
                <Typography
                    align="justify"
                    sx={{
                        m: 2,
                    }}
                >
                    <Typography sx={{mb: 1}}>
                        We&apos;re working to log you {actionWord}.
                    </Typography>
                    {props.action === 'login' && (
                        <Typography>
                            {' '}
                            If this is your first time logging in, it may take a little
                            longer.
                        </Typography>
                    )}
                </Typography>
                <Button variant="solid" onClick={async () => signOut()}>
                    Cancel
                </Button>
            </LoginCard>
        </BackgroundSheet>
    );
};

LoginLoadingPage.propTypes = {
    action: PropTypes.oneOf(['login', 'logout']).isRequired,
};

export default LoginLoadingPage;
