/* eslint-disable max-len */
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {Alert, Box, Typography, useTheme} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
/* eslint-enable max-len */

const DisplayInformation = ({title, body, level, isFirstItem}) => {
    const theme = useTheme();
    const mode = getColourMode();

    const levelColors = {
        success: {
            background:
                mode === 'light'
                    ? theme.vars.palette.success[50]
                    : theme.vars.palette.success[900],
            color:
                mode === 'light'
                    ? theme.vars.palette.success[700]
                    : theme.vars.palette.success[200],
            name: 'success',
            icon: <CheckCircleOutlineRoundedIcon />,
        },
        warning: {
            background:
                mode === 'light'
                    ? theme.vars.palette.warning[50]
                    : theme.vars.palette.warning[900],
            color:
                mode === 'light'
                    ? theme.vars.palette.warning[700]
                    : theme.vars.palette.warning[200],
            name: 'warning',
            icon: <WarningAmberRoundedIcon />,
        },
        info: {
            background:
                mode === 'light'
                    ? theme.vars.palette.primary[50]
                    : theme.vars.palette.primary[900],
            color:
                mode === 'light'
                    ? theme.vars.palette.primary[700]
                    : theme.vars.palette.primary[200],
            name: 'primary',
            icon: <InfoOutlinedIcon />,
        },
        error: {
            background:
                mode === 'light'
                    ? theme.vars.palette.danger[50]
                    : theme.vars.palette.danger[900],
            color:
                mode === 'light'
                    ? theme.vars.palette.danger[700]
                    : theme.vars.palette.danger[200],
            name: 'danger',
            icon: <ErrorOutlineRoundedIcon />,
        },
    };

    const backgroundColor = levelColors[level].background;
    const color = levelColors[level].color;

    return (
        <Alert
            color={levelColors[level].name || level}
            variant="outlined"
            sx={{
                mt: isFirstItem ? 0 : 2,
                mb: 2,
                backgroundColor: backgroundColor,
                alignItems: 'flex-start',
            }}
            startDecorator={levelColors[level].icon}
        >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {title && (
                    <Typography
                        level="title-md"
                        color="success"
                        gutterBottom
                        sx={{
                            color: color,
                            // display: 'flex',
                            // flexDirection: 'column',
                            // gap: 4,
                            // py: 2,
                        }}
                        component="div"
                    >
                        <PlainMarkdown>{title}</PlainMarkdown>
                    </Typography>
                )}
                {body && (
                    <Typography
                        level="body-sm"
                        color="success"
                        sx={{
                            color: color,
                        }}
                        component="div"
                    >
                        <StyledMarkdown>{body}</StyledMarkdown>
                    </Typography>
                )}
            </Box>
        </Alert>
    );
};

export default DisplayInformation;

DisplayInformation.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    level: PropTypes.string.isRequired,
    isFirstItem: PropTypes.bool,
};
