import {Box, Sheet} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({height, children}) => {
    return (
        <Sheet
            sx={{
                height: height || '100%',
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRadius: 4,
            }}
        >
            <Box
                sx={{
                    height: '100%',
                    width: '95%',
                    maxWidth: 1200,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    pl: 'env(safe-area-inset-left)',
                    pr: 'env(safe-area-inset-right)',
                    pb: 'env(safe-area-inset-bottom)',
                }}
            >
                {children}
            </Box>
        </Sheet>
    );
};

export {Container};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    height: PropTypes.string,
};
