import {gql, useMutation} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const INITIATE_MEDICATION_ORDER = gql`
    mutation InitiateMedicationOrder($requestedMedications: [String!]!) {
        initiateMedicationOrder(requestedMedications: $requestedMedications) {
            sessionParameters {
                sessionId
                sessionIteration
            }
            checksComplete
            medicationCheckActions {
                medicineId
                actions {
                    hash
                    path
                    type
                    ... on UserQuery {
                        title
                        body
                        supportingText
                        responseType
                    }
                    ... on ImmediateActionQuery {
                        title
                        body
                        supportingText
                        responseType
                        onTruthy {
                            ...ImmediateActionResponseFields
                        }
                        onFalsy {
                            ...ImmediateActionResponseFields
                        }
                    }
                    ... on DisplayInformation {
                        title
                        body
                        level
                    }
                }
            }
        }
    }
    fragment ImmediateActionResponseFields on ImmediateActionResponse {
        action
        flowInterrupt
        ... on ImmediateActionInformationDisplay {
            level
            title
            body
        }
    }
`;

export const useInitiateMedicationOrder = (requestedMedications) => {
    const [initiateOrder, {loading, error, data}] = useMutation(
        INITIATE_MEDICATION_ORDER,
        {
            variables: {requestedMedications}, // Pass requestedMedications as variables
            notifyOnNetworkStatusChange: true,
        },
    );

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        initiateOrder, // This is the function you'll call to initiate the order
        loading,
        error,
        data, // Data will contain the mutation result
    };
};
