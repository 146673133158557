/* eslint-disable max-len */
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Typography,
} from '@mui/joy';
import FormHeader from 'components/forms/FormHeader';
import {useSubmitMedicationOrderResponses} from 'graphql/submitMedicationOrderResponses';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import MedicationChecksSection from './components/MedicationChecksSection';
/* eslint-enable max-len */

const getMedicineName = (medicineId, requestedMedications) => {
    const matchingMedicine = requestedMedications.find(
        (obj) => obj.medicineId === medicineId,
    );
    return matchingMedicine ? matchingMedicine['medicineName'] : null;
};

const MedicationChecksStepBody = ({
    scrollableRef,
    loadingInitiateMedicationOrder,
    medicationCheckActions,
    setMedicationCheckActions,
    orderState,
    selectedMedications,
    setOrderState,
    setProgressValue,
    handleNext,
    handleBack,
    setLoading,
    setSkippedMedicationChecks,
}) => {
    const {enqueueSnackbar} = useSnackbar();
    const {
        submitOrderResponses,
        loading: submitResponsesLoading,
        error: submitResponsesError,
        data: submitResponsesData,
    } = useSubmitMedicationOrderResponses();

    const [showBypassWarning, setShowBypassWarning] = React.useState(false);
    const [firstChecks, setFirstChecks] = React.useState(true);
    const [resolutionValues, setResolutionValues] = React.useState({});
    const [medicationChecksToDisplay, setMedicationChecksToDisplay] = React.useState(
        [],
    );
    const [waitingForSubmissionResponse, setWaitingForSubmissionResponse] =
        React.useState(false);
    const [flowInterruptActive, setFlowInterruptActive] = React.useState(false);
    const [allQuestionsCompleted, setAllQuestionsCompleted] = React.useState(false);

    const handleToggleBypass = () => {
        setShowBypassWarning(!showBypassWarning);
    };

    const handleBypassChecks = () => {
        setOrderState((prevState) => ({
            sessionParameters: {
                sessionId: prevState.sessionParameters.sessionId,
                sessionIteration: null,
            },
            checksComplete: true,
        }));
        setSkippedMedicationChecks(true);
        handleNext('confirmOrder');
    };

    const addResolution = ({
        path,
        hash,
        type,
        booleanValue,
        medicineId,
        flowInterrupt,
    }) => {
        setResolutionValues((currentObject) => ({
            ...currentObject,
            [path]: {
                path: path,
                hash: hash,
                type: type,
                booleanValue: booleanValue,
                medicineId: medicineId,
                flowInterrupt: flowInterrupt,
            },
        }));
    };
    const transformResolutionValues = (resolutionValues) => {
        const groupedByMedicine = {};

        // Iterate and group by medicineId
        Object.values(resolutionValues).forEach((item) => {
            const {medicineId, path, hash, type, booleanValue} = item;

            if (!groupedByMedicine[medicineId]) {
                groupedByMedicine[medicineId] = [];
            }

            groupedByMedicine[medicineId].push({
                path: path,
                hash: hash,
                type: type,
                booleanValue: booleanValue,
            });
        });

        // Map to desired format
        return Object.keys(groupedByMedicine).map((medicineId) => ({
            medicineId,
            results: groupedByMedicine[medicineId],
        }));
    };

    const handleSubmitResponses = () => {
        setFirstChecks(false);
        setWaitingForSubmissionResponse(true);

        const medicationCheckResponses = transformResolutionValues(resolutionValues);

        // setPreviousResolutionValues(resolutionValues);
        setResolutionValues({});

        // Call the mutation with the transformed data
        submitOrderResponses({
            variables: {
                sessionParameters: {
                    sessionId: orderState.sessionParameters.sessionId,
                    sessionIteration: orderState.sessionParameters.sessionIteration,
                },
                medicationCheckResponses,
            },
        });
    };

    React.useEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, []);

    React.useEffect(() => {
        setLoading(submitResponsesLoading);
    }, [submitResponsesLoading]);

    React.useEffect(() => {
        setLoading(loadingInitiateMedicationOrder);
    }, [loadingInitiateMedicationOrder]);

    React.useEffect(() => {
        if (submitResponsesData) {
            setProgressValue((prevProgress) => prevProgress + 5);

            setWaitingForSubmissionResponse(false);
            const newOrderState = {
                sessionParameters:
                    submitResponsesData?.submitMedicationOrderResponses
                        ?.sessionParameters,
                checksComplete:
                    submitResponsesData?.submitMedicationOrderResponses?.checksComplete,
            };
            setOrderState(newOrderState);

            if (
                submitResponsesData?.submitMedicationOrderResponses
                    ?.medicationCheckActions
            ) {
                const newActions =
                    submitResponsesData?.submitMedicationOrderResponses
                        .medicationCheckActions;
                setMedicationCheckActions(newActions);
            }
        }
    }, [submitResponsesData]);

    React.useEffect(() => {
        if (orderState.checksComplete) {
            setProgressValue(95);
            handleNext('confirmOrder');
            setSkippedMedicationChecks(false);
        }
    }, [orderState]);

    React.useEffect(() => {
        if (submitResponsesError) {
            setWaitingForSubmissionResponse(false);
            enqueueSnackbar(
                // eslint-disable-next-line max-len
                'Failed to submit responses. If this continues, you can bypass the questions using the button at the bottom.',
                {variant: 'error'},
            );
        }
    }, [submitResponsesError]);

    // Based on the resolution values, determine which medications to display
    React.useEffect(() => {
        let foundFlowInterrupt = false;
        let questionsRemaining = false;
        const actionsToDisplay = [];
        let hideRemaining = false;

        // Loop through each medication check actions
        for (const medicationActions of medicationCheckActions) {
            const shouldMarkHidden = hideRemaining || foundFlowInterrupt;
            let index = 1;
            const newActions = [];

            // Loop through each action
            for (const obj of medicationActions.actions) {
                const resolutionValue = resolutionValues[obj.path];

                const numberOfQuestions = medicationActions.actions.filter(
                    (action) => action.type !== 'DisplayInformation',
                ).length;

                newActions.push({
                    ...obj,
                    medicineId: medicationActions.medicineId,
                    questionNumber: `${index}/${numberOfQuestions}`,
                    hidden: hideRemaining || foundFlowInterrupt,
                });
                if (obj.type !== 'DisplayInformation') {
                    index++;
                }

                // Get the resolution of the query. If the resolution is null, then
                // the question has not been answered yet
                // If the flowInterrupt is true, then we should hide the remaining
                // questions because we should prevent the user from continuing
                if (
                    obj.type === 'ImmediateActionQuery' &&
                    (!resolutionValue || resolutionValue?.flowInterrupt === true)
                ) {
                    hideRemaining = true;
                    if (resolutionValue?.flowInterrupt === true) {
                        foundFlowInterrupt = true;
                    }
                }
                if (!resolutionValue && obj.type !== 'DisplayInformation') {
                    questionsRemaining = true;
                }
            }
            hideRemaining = questionsRemaining || foundFlowInterrupt;

            actionsToDisplay.push({
                ...medicationActions,
                medicineName: getMedicineName(
                    medicationActions.medicineId,
                    selectedMedications,
                ),
                hidden: shouldMarkHidden,
                actions: newActions,
            });
        }

        setFlowInterruptActive(foundFlowInterrupt);
        setAllQuestionsCompleted(!questionsRemaining);
        setMedicationChecksToDisplay(actionsToDisplay);
    }, [resolutionValues, medicationCheckActions]);

    return (
        <Box
            sx={{
                mt: 2,
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '750px',
            }}
        >
            <FormHeader
                title="Medication Questions"
                supportingText={
                    'We need to ask you some questions so that ' +
                    'your GP can get your medications to you as soon as possible.'
                }
            />
            <Divider sx={{my: 2}} />
            <Box
                className="scrollable"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    pb: 2,
                }}
            >
                {(loadingInitiateMedicationOrder || submitResponsesLoading) && (
                    <Box
                        sx={{
                            width: '100%',
                            minHeight: '50px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            justifyContent: 'center', // centers horizontally
                            alignItems: 'center', // centers vertically
                            p: 6,
                            animation: 'fadein 1s',
                            transitionDelay:
                                loadingInitiateMedicationOrder || submitResponsesLoading
                                    ? '800ms'
                                    : '0ms',
                            '@keyframes fadein': {
                                from: {opacity: 0},
                                to: {opacity: 1},
                            },
                        }}
                    >
                        <CircularProgress />
                        <Typography>
                            {firstChecks &&
                                'Running checks against your Medical Record...'}
                            {!firstChecks && 'Processing your answers...'}
                        </Typography>
                    </Box>
                )}
                {!loadingInitiateMedicationOrder &&
                    !submitResponsesLoading &&
                    medicationChecksToDisplay.map((obj, index) => {
                        return (
                            <MedicationChecksSection
                                key={obj.medicineId}
                                medicationCheckActions={obj}
                                resolutionValues={resolutionValues}
                                addResolution={addResolution}
                            />
                        );
                    })}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap-reverse',
                        gap: 2,
                        justifyContent: {
                            xs: 'center',
                            sm: 'space-between',
                        },
                        alignItems: {xs: 'center', sm: 'flex-start'},
                    }}
                >
                    <Button
                        variant="outlined"
                        onClick={handleBack}
                        sx={{
                            pt: 1,
                            pb: 1,
                            minWidth: '100px',
                        }}
                    >
                        Back
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap-reverse',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Button
                            color="danger"
                            variant="plain"
                            onClick={handleToggleBypass}
                            disabled={showBypassWarning}
                            sx={{
                                py: 1,
                                px: 2,
                                minWidth: '100px',
                                maxWidth: 145,
                            }}
                        >
                            Skip checks
                        </Button>
                        <Button
                            disabled={
                                !allQuestionsCompleted ||
                                flowInterruptActive ||
                                loadingInitiateMedicationOrder
                            }
                            onClick={handleSubmitResponses}
                            loading={waitingForSubmissionResponse}
                            sx={{maxWidth: 145}}
                        >
                            Submit Answers
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        opacity: showBypassWarning ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out',
                    }}
                >
                    {showBypassWarning && (
                        <Alert
                            color="warning"
                            variant="soft"
                            onClose={handleToggleBypass}
                            startDecorator={
                                <WarningAmberOutlinedIcon color="warning" />
                            }
                            endDecorator={
                                <IconButton
                                    variant="soft"
                                    color="warning"
                                    onClick={handleToggleBypass}
                                >
                                    <CloseRoundedIcon />
                                </IconButton>
                            }
                            sx={{
                                alignItems: 'flex-start',
                            }}
                        >
                            <Box
                                sx={{display: 'flex', flexDirection: 'column', gap: 1}}
                            >
                                <Typography
                                    level="title-lg"
                                    color="warning"
                                    gutterBottom
                                >
                                    Are you sure you want to skip the Medication Checks?
                                </Typography>
                                <Typography>
                                    You can skip the medication checks, and proceed to
                                    order your medications. However, this may result in
                                    your order being delayed whilst your GP manually
                                    checks these details.
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        variant="solid"
                                        color="warning"
                                        sx={{align: 'right'}}
                                        onClick={handleBypassChecks}
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </Alert>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default MedicationChecksStepBody;

MedicationChecksStepBody.propTypes = {
    scrollableRef: PropTypes.any,
    handleBack: PropTypes.func,
    loadingInitiateMedicationOrder: PropTypes.bool,
    orderState: PropTypes.object,
    setProgressValue: PropTypes.func.isRequired,
    setMedicationCheckActions: PropTypes.func,
    medicationCheckActions: PropTypes.array,
    setOrderState: PropTypes.func,
    handleNext: PropTypes.func,
    medicationCheckActions: PropTypes.array,
    selectedMedications: PropTypes.array,
    setLoading: PropTypes.func.isRequired,
    setSkippedMedicationChecks: PropTypes.func.isRequired,
};
