import {useColorScheme} from '@mui/joy';

const getColourMode = () => {
    const {mode, systemMode} = useColorScheme();

    if (mode === 'system') {
        return systemMode;
    } else {
        return mode;
    }
};

const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `${r} ${g} ${b}`;
};

export {getColourMode, hexToRgb};
