import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import {
    Button,
    Card,
    CardActions,
    CardOverflow,
    Divider,
    Dropdown,
    List,
    ListItem,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {getTimeDifference} from 'utilities/dateUtils';

const PastMedicationCard = ({
    medicineName,
    isSharedCareDrug,
    quantity,
    dose,
    lastIssued,
    informationPages,
    numberOfPrescriptions,
    handleShowInfo,
    handleShowHistory,
}) => {
    const theme = useTheme();

    return (
        <Card
            orientation="vertical"
            size="lg"
            variant="outlined"
            color="neutral"
            sx={{
                maxWidth: 450,
                minWidth: 200,
                width: '100%',
                border: null,
                backgroundColor: `${theme.vars.palette.background.body}`,
            }}
        >
            <CardOverflow
                variant="soft"
                color={isSharedCareDrug ? 'danger' : 'neutral'}
                sx={{
                    height: 24,
                    px: 0.2,
                    justifyContent: 'center',
                    alignContent: 'center',
                    fontSize: isSharedCareDrug ? 'md' : 'xs',
                    fontWeight: isSharedCareDrug ? 'xl' : 'xs',
                    letterSpacing: '1px',
                    textTransform: isSharedCareDrug ? 'uppercase' : null,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    textAlign: 'center',
                }}
            >
                {isSharedCareDrug ? 'Shared Care Drug' : null}
            </CardOverflow>
            <Typography level="h4">{medicineName}</Typography>
            <Divider inset="none" />
            <List size="sm" sx={{mx: 'calc(-1 * var(--ListItem-paddingX))'}}>
                {quantity && (
                    <ListItem>
                        <ListItemDecorator>
                            <LocalPharmacyOutlinedIcon />
                        </ListItemDecorator>
                        {quantity}
                    </ListItem>
                )}
                {dose && (
                    <ListItem>
                        <ListItemDecorator>
                            <AssignmentOutlinedIcon />
                        </ListItemDecorator>
                        {dose}
                    </ListItem>
                )}
                {lastIssued && lastIssued !== '1970-01-01' && (
                    <ListItem>
                        <ListItemDecorator>
                            <EventOutlinedIcon />
                        </ListItemDecorator>
                        Last ordered: {lastIssued} ({getTimeDifference(lastIssued)} ago)
                    </ListItem>
                )}
            </List>
            <Divider inset="none" />
            <CardActions
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: {xs: 'center', sm: 'space-between'},
                }}
            >
                <Button
                    variant="soft"
                    color="neutral"
                    startDecorator={<HistoryRoundedIcon />}
                    disabled={numberOfPrescriptions < 2}
                    onClick={() => {
                        handleShowHistory(medicineName, null);
                    }}
                    sx={{flexGrow: 1}}
                >
                    All Orders
                </Button>
                {informationPages.length < 2 && (
                    <Button
                        variant={isSharedCareDrug ? 'solid' : 'soft'}
                        color={isSharedCareDrug ? 'danger' : 'neutral'}
                        endDecorator={<KeyboardArrowRight />}
                        disabled={informationPages.length === 0}
                        onClick={() => {
                            handleShowInfo(medicineName, null);
                        }}
                        sx={{flexGrow: 1}}
                    >
                        {isSharedCareDrug ? 'Key information' : 'Find out more'}
                    </Button>
                )}
                {informationPages.length > 1 && (
                    <Dropdown>
                        <MenuButton
                            variant={isSharedCareDrug ? 'solid' : 'soft'}
                            color={isSharedCareDrug ? 'danger' : 'neutral'}
                            endDecorator={<KeyboardArrowRight />}
                        >
                            {isSharedCareDrug ? 'Key information' : 'Find out more'}
                        </MenuButton>
                        <Menu placement="bottom-end">
                            {informationPages.map((informationPage) => (
                                <MenuItem
                                    key={informationPage}
                                    onClick={() => {
                                        handleShowInfo(medicineName, informationPage);
                                    }}
                                >
                                    {informationPage}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Dropdown>
                )}
            </CardActions>
        </Card>
    );
};

export default PastMedicationCard;

PastMedicationCard.propTypes = {
    medicineName: PropTypes.string.isRequired,
    isSharedCareDrug: PropTypes.bool,
    quantity: PropTypes.string,
    dose: PropTypes.string,
    lastIssued: PropTypes.string,
    numberOfPrescriptions: PropTypes.number.isRequired,
    informationPages: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleShowHistory: PropTypes.func.isRequired,
    handleShowInfo: PropTypes.func.isRequired,
};
