import {Card, CardContent, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

const DataItemCard = ({icon, text}) => {
    return (
        <Card
            sx={{
                width: '90%', // CSS for animation
                opacity: 0,
                transform: 'translateY(-20px)',
                animation: 'fade-in-down 0.5s forwards',
                '&:not(:last-child)': {
                    mb: 1, // margin-bottom between cards
                },
                // Define the keyframes for the animation
                '@keyframes fade-in-down': {
                    '0%': {
                        opacity: 0,
                        transform: 'translateY(-20px)',
                    },
                    '100%': {
                        opacity: 1,
                        transform: 'translateY(0)',
                    },
                },
            }}
            variant="outlined"
            color="primary"
            orientation="horizontal"
            invertedColors
        >
            <CardContent sx={{display: 'flex', flexDirection: 'row'}}>
                {icon}
                <Typography level="body-sm">{text}</Typography>
            </CardContent>
        </Card>
    );
};

export default DataItemCard;

DataItemCard.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string.isRequired,
};
