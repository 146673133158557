import {GlobalStyles} from '@mui/joy';
import CssBaseline from '@mui/joy/CssBaseline';
import {CssVarsProvider as JoyCssVarsProvider} from '@mui/joy/styles';
import {
    THEME_ID as MATERIAL_THEME_ID,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import PocketGPTheme, {PocketGpJoyTheme} from 'styles/PocketGPTheme';

const CssProvidersWrapper = ({children}) => (
    <>
        <CssBaseline enableColorScheme />
        <MaterialCssVarsProvider
            theme={{[MATERIAL_THEME_ID]: PocketGPTheme}}
            defaultMode="light"
        >
            <GlobalStyles
                styles={{
                    p: {
                        marginBottom: '0px', // adjust as needed
                        marginTop: '0px', // adjust as needed
                    },
                    body: {
                        backgroundColor: '#241C70',
                    },
                }}
            />
            <JoyCssVarsProvider defaultMode="light" theme={PocketGpJoyTheme}>
                {children}
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
    </>
);

CssProvidersWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export {CssProvidersWrapper};
