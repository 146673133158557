import {gql, useMutation} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const SET_PREFERRED_PHARMACY = gql`
    mutation SetPreferredPharmacy($odsCode: ID!, $postcode: String!) {
        setMyPreferredPharmacy(odsCode: $odsCode, postcode: $postcode) {
            odsCode
            organisationName
            organisationType
            url
            address1
            address2
            address3
            city
            county
            latitude
            longitude
            organisationSubType
            postcode
        }
    }
`;

export const useSetMyPreferredPharmacy = () => {
    const [setPharmacy, {loading, error}] = useMutation(SET_PREFERRED_PHARMACY);

    const setPreferredPharmacy = async (pharmacyData) => {
        try {
            const {data} = await setPharmacy({variables: pharmacyData});
            return data.setMyPreferredPharmacy;
        } catch (e) {
            console.error('Error setting preferred pharmacy:', e);
        }
    };

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        setPreferredPharmacy,
        loading,
        error,
    };
};

// Usage:
// const { setPreferredPharmacy, loading, error } = useSetMyPreferredPharmacy();
// await setPreferredPharmacy(yourPharmacyData);
