import {Sheet, useColorScheme} from '@mui/joy';
import {useTheme} from '@mui/joy/styles';
import PropTypes from 'prop-types';
import React from 'react';

const StyledSheetWrapper = ({children}) => {
    const theme = useTheme();
    const {mode} = useColorScheme();

    const sheetStyle = {
        /* eslint-disable max-len */
        backgroundImage:
            mode === 'light'
                ? `
            radial-gradient(at center 40%, transparent 0%, ${theme.vars.palette.background.surface} 80%),
            repeating-linear-gradient(${theme.vars.palette.grey[200]}, ${theme.vars.palette.grey[200]} 1px, transparent 1px, transparent 48px),
            repeating-linear-gradient(90deg, ${theme.vars.palette.grey[200]}, ${theme.vars.palette.grey[200]} 1px, transparent 1px, transparent 48px)
        `
                : `
            radial-gradient(at center 40%, transparent 0%, ${theme.vars.palette.background.surface} 80%),
            repeating-linear-gradient(${theme.palette.grey[200]}, ${theme.palette.grey[200]} 1px, transparent 1px, transparent 48px),
            repeating-linear-gradient(90deg, ${theme.palette.grey[200]}, ${theme.palette.grey[200]} 1px, transparent 1px, transparent 48px)
        `,
        /* eslint-enable max-len */
        backgroundColor: theme.vars.palette.background.surface,
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
    };

    return <Sheet sx={sheetStyle}>{children}</Sheet>;
};

export default StyledSheetWrapper;

StyledSheetWrapper.propTypes = {
    children: PropTypes.node,
};
