import {extendTheme} from '@mui/joy/styles';
import {experimental_extendTheme as materialExtendTheme} from '@mui/material/styles';

const primary = {
    25: '#fdfcff',
    50: '#fbf8ff',
    100: '#F7F2FF',
    200: '#D4C4FD',
    300: '#A38EED',
    400: '#6553C3',
    500: '#241C70',
    600: '#100D5E',
    700: '#06074B',
    800: '#030739',
    900: '#020726',
};

const secondary = {
    25: '#fcfcff',
    50: '#f8f8ff',
    100: '#F2F2FF',
    200: '#D9DBFD',
    300: '#B8C0F2',
    400: '#8D9BD4',
    500: '#576899',
    600: '#384D7C',
    700: '#263B60',
    800: '#192B43',
    900: '#0E1A26',
};

const greyPalette = {
    main: '#475467',
    light: '#F2F4F7',
    dark: '#1D2939',
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
    // Adjust the global variant tokens as you'd like.
    // The tokens should be the same for all color schemes.
    solidBg: 'var(--joy-palette-grey-400)',
    solidActiveBg: 'var(--joy-palette-grey-500)',
    outlinedBorder: 'var(--joy-palette-grey-500)',
    outlinedColor: 'var(--joy-palette-grey-700)',
    outlinedActiveBg: 'var(--joy-palette-grey-100)',
    softColor: 'var(--joy-palette-grey-800)',
    softBg: 'var(--joy-palette-grey-200)',
    softActiveBg: 'var(--joy-palette-grey-300)',
    plainColor: 'var(--joy-palette-grey-700)',
    plainActiveBg: 'var(--joy-palette-grey-100)',
};

export const PocketGpJoyTheme = extendTheme({
    palette: {
        mode: 'light',
    },
    colorSchemes: {
        light: {
            palette: {
                background: {
                    level2: primary[25],
                    level3: primary[50],
                },
                primary: {
                    ...primary,
                    plainColor: `var(--joy-palette-primary-600)`,
                    plainHoverBg: `var(--joy-palette-primary-100)`,
                    plainActiveBg: `var(--joy-palette-primary-200)`,
                    plainDisabledColor: `var(--joy-palette-primary-200)`,
                    outlinedColor: `var(--joy-palette-primary-500)`,
                    outlinedBorder: `var(--joy-palette-primary-200)`,
                    outlinedHoverBg: `var(--joy-palette-primary-100)`,
                    outlinedHoverBorder: `var(--joy-palette-primary-300)`,
                    outlinedActiveBg: `var(--joy-palette-primary-200)`,
                    outlinedDisabledColor: `var(--joy-palette-primary-100)`,
                    outlinedDisabledBorder: `var(--joy-palette-primary-100)`,
                    softColor: `var(--joy-palette-primary-600)`,
                    softBg: `var(--joy-palette-primary-100)`,
                    softHoverBg: `var(--joy-palette-primary-200)`,
                    softActiveBg: `var(--joy-palette-primary-300)`,
                    softDisabledColor: `var(--joy-palette-primary-300)`,
                    softDisabledBg: `var(--joy-paletteprimary}-50)`,
                    solidColor: '#fff',
                    solidBg: `var(--joy-palette-primary-500)`,
                    solidHoverBg: `var(--joy-palette-primary-600)`,
                    solidActiveBg: `var(--joy-palette-primary-700)`,
                    solidDisabledColor: `#fff`,
                    solidDisabledBg: `var(--joy-palette-primary-200)`,
                },
                grey: greyPalette,
            },
        },
        dark: {
            palette: {
                background: {
                    level1: secondary[900],
                    level2: secondary[800],
                    level3: secondary[700],
                },
                primary: {
                    ...secondary,
                    plainColor: `var(--joy-palette-primary-300)`,
                    plainHoverBg: `var(--joy-palette-primary-800)`,
                    plainActiveBg: `var(--joy-palette-primary-700)`,
                    plainDisabledColor: `var(--joy-palette-primary-800)`,
                    outlinedColor: `var(--joy-palette-primary-200)`,
                    outlinedBorder: `var(--joy-palette-primary-700)`,
                    outlinedHoverBg: `var(--joy-palette-primary-800)`,
                    outlinedHoverBorder: `var(--joy-palette-primary-600)`,
                    outlinedActiveBg: `var(--joy-palette-primary-900)`,
                    outlinedDisabledColor: `var(--joy-palette-primary-800)`,
                    outlinedDisabledBorder: `var(--joy-palette-primary-800)`,
                    softColor: `var(--joy-palette-primary-200)`,
                    softBg: `var(--joy-palette-primary-900)`,
                    softHoverBg: `var(--joy-palette-primary-800)`,
                    softActiveBg: `var(--joy-palette-primary-700)`,
                    softDisabledColor: `var(--joy-palette-primary-800)`,
                    softDisabledBg: `var(--joy-palette-primary-900)`,
                    solidColor: `#fff`,
                    solidBg: `var(--joy-palette-primary-600)`,
                    solidHoverBg: `var(--joy-palette-primary-700)`,
                    solidActiveBg: `var(--joy-palette-primary-800)`,
                    solidDisabledColor: `var(--joy-palette-primary-700)`,
                    solidDisabledBg: `var(--joy-palette-primary-900)`,
                },
                grey: greyPalette,
            },
            // components: {
            //     JoyButton: {
            //         styleOverrides: {
            //             root: {
            //                 '&:hover': {
            //                     color: 'var(--joy-palette-primary-50)',
            //                 },
            //             },
            //         },
            //     },
            // },
        },
    },
});

const themePalette = {
    primary: {
        main: '#241c70',
        light: '#B4ADEB',
        dark: '#0b0821',
        25: '#F0EFFB',
        50: '#D2CEF3',
        100: '#B4ADEB',
        200: '#958DE2',
        300: '#685DC6',
        400: '#4A3BCE',
        500: '#3A2DB4',
        600: '#302593',
        700: '#241c70',
        800: '#151041',
        900: '#0b0821',
        contrastText: '#FFFFFF',
        contrastThreshold: 3,
    },
    primaryWhite: {
        main: '#FFFFFF',
        contrastText: '#221E6C',
    },
    success: {
        main: '#039855',
        light: '#D1FADF',
        dark: '#054F31',
        25: '#F6FEF9',
        50: '#ECFDF3',
        100: '#D1FADF',
        200: '#A6F4C5',
        300: '#6CE9A6',
        400: '#32D583',
        500: '#12B76A',
        600: '#039855',
        700: '#027A48',
        800: '#05603A',
        900: '#054F31',
    },
    warning: {
        main: '#DC6803',
        light: '#FEF0C7',
        dark: '#93370D',
        25: '#FFFCF5',
        50: '#FFFAEB',
        100: '#FEF0C7',
        200: '#FEDF89',
        300: '#FEC84B',
        400: '#FDB022',
        500: '#F79009',
        600: '#DC6803',
        700: '#B54708',
        800: '#93370D',
        900: '#7A2E0E',
        contrastText: '#FFFFFF',
    },
    error: {
        main: '#D92D20',
        light: '#FEE4E2',
        dark: '#912018',
        25: '#FFFBFA',
        50: '#FEF3F2',
        100: '#FEE4E2',
        200: '#FECDCA',
        300: '#FDA29B',
        400: '#F97066',
        500: '#F04438',
        600: '#D92D20',
        700: '#B42318',
        800: '#912018',
        900: '#7A271A',
        contrastText: '#FFFFFF',
    },
    greyBlue: {
        main: '#5b6698',
        light: '#9ca4c2',
        dark: '#1d2355',
        25: '#eceef3',
        50: '#e7e9f0',
        100: '#c2c7db',
        200: '#9ca4c2',
        300: '#7781a9',
        400: '#5b6698',
        500: '#404d88',
        600: '#3a4680',
        700: '#323c74',
        800: '#2b3368',
        900: '#1d2355',
        contrastText: '#ffffff',
        contrastThreshold: 3,
    },
    grey: {
        main: '#475467',
        light: '#F2F4F7',
        dark: '#1D2939',
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        400: '#98A2B3',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#1D2939',
        900: '#101828',
    },
    contrastThreshold: 4.5,
};

const themeTypography = {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    display2Xl: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '4.5rem',
        lineHeight: '5.625rem',
        letterSpacing: '-0.02em',
    },
    display2XlMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '4.5rem',
        lineHeight: '5.625rem',
        letterSpacing: '-0.02em',
    },
    display2XlSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '4.5rem',
        lineHeight: '5.625rem',
        letterSpacing: '-0.02em',
    },
    display2XlBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '4.5rem',
        lineHeight: '5.625rem',
        letterSpacing: '-0.02em',
    },
    displayXl: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '3.75rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.02em',
    },
    displayXlMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '3.75rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.02em',
    },
    displayXlSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '3.75rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.02em',
    },
    displayXlBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '3.75rem',
        lineHeight: '4.5rem',
        letterSpacing: '-0.02em',
    },
    displayLg: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: '3.75rem',
        letterSpacing: '-0.02em',
    },
    displayLgMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '3rem',
        lineHeight: '3.75rem',
        letterSpacing: '-0.02em',
    },
    displayLgSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '3rem',
        lineHeight: '3.75rem',
        letterSpacing: '-0.02em',
    },
    displayLgBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: '3.75rem',
        letterSpacing: '-0.02em',
    },
    displayMd: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        letterSpacing: '-0.02em',
    },
    displayMdMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        letterSpacing: '-0.02em',
    },
    displayMdSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        letterSpacing: '-0.02em',
    },
    displayMdBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        letterSpacing: '-0.02em',
    },
    displaySm: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.875rem',
        lineHeight: '2.375rem',
    },
    displaySmMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.875rem',
        lineHeight: '2.375rem',
    },
    displaySmSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.875rem',
        lineHeight: '2.375rem',
    },
    displaySmBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.875rem',
        lineHeight: '2.375rem',
    },
    displayXs: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    displayXsMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    displayXsSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    displayXsBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    textXl: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
    },
    textXlMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
    },
    textXlSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
    },
    textXlBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
    },
    textLg: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },
    textLgMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },
    textLgSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },
    textLgBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },
    textMd: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    textMdMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    textMdSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    textMdBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    textSm: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
    },
    textSmMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
    },
    textSmSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
    },
    textSmBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
    },
    textXs: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
    },
    textXsMedium: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
    },
    textXsSemibold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
    },
    textXsBold: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
    },
    // button: {
    //     Typography: {
    //         variant: "textMdSemibold",
    //     },
    //     textTransform: "none",
    // },
};

const themeComponents = {
    MuiAlert: {
        styleOverrides: {
            root: ({ownerState}) => ({
                /* eslint-disable indent */
                ...(ownerState.variant === 'filled' &&
                    (ownerState.severity === 'success' ||
                        ownerState.severity === 'warning' ||
                        ownerState.severity === 'primary') && {
                        color: themePalette.primaryWhite.main,
                    }),
                /* eslint-enable indent */
            }),
        },
    },
};

const PocketGPTheme = materialExtendTheme({
    palette: themePalette,
    typography: themeTypography,
    components: themeComponents,
});

export default PocketGPTheme;
