import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import {getAccessToken} from './loginUtils';

const url = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT;
const region = process.env.REACT_APP_APPSYNC_REGION;

const memCache = new InMemoryCache();

export const createApolloClient = () => {
    const auth = () => ({
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => {
            return await getAccessToken();
        },
    });

    const httpLink = new HttpLink({uri: url});

    const link = ApolloLink.from([
        createAuthLink({url, region, auth: auth()}),
        createSubscriptionHandshakeLink({url, region, auth: auth()}, httpLink),
    ]);

    return new ApolloClient({
        link,
        cache: memCache,
        connectToDevTools: true,
    });
};
