import {Box} from '@mui/joy';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';

const PocketGpLogoHeader = () => {
    const colourMode = getColourMode();

    return (
        <Box
            component="img"
            sx={{
                width: '80%',
                maxWidth: {xs: 350, md: 350},
                height: 'auto',
                my: 3,
                mx: 2,
            }}
            alt="PocketGP Logo"
            src={
                colourMode === 'light'
                    ? 'images/pocketgp_logo_transparent_background.png'
                    : 'images/white_logo_transparent_background_cropped.png'
            }
            /* eslint-disable max-len */
            srcSet={
                colourMode === 'light'
                    ? 'images/pocketgp_logo_transparent_background.png 1x, images/pocketgp_logo_transparent_background@2x.png 2x'
                    : 'images/white_logo_transparent_background_cropped.png 1x, images/white_logo_transparent_background_cropped@2x.png 2x'
            }
            /* eslint-enable max-len */
        />
    );
};

export default PocketGpLogoHeader;

PocketGpLogoHeader.propTypes = {};
