import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import SearchIcon from '@mui/icons-material/Search';
import {Box, Button, Link as JoyLink, Stack, Typography} from '@mui/joy';
import {useTheme} from '@mui/joy/styles';
import React from 'react';
import {Link, useNavigate, useRouteError} from 'react-router-dom';
import BackgroundSheet from 'routes/Login/components/BackgroundSheet';
import {CssProvidersWrapper} from 'utilities/themeUtilities';

/**
 * ErrorPage component displays a simple error page with an error message.
 * It is used to show a user-friendly error message when an unexpected error occurs.
 *
 * @return {JSX.Element} The JSX element representing the ErrorPage.
 */
const ErrorPage = () => {
    const theme = useTheme();
    const error = useRouteError();
    const navigate = useNavigate();
    console.error(error);

    let content = null;

    if (error.status === 404) {
        content = (
            <>
                <Box
                    sx={{
                        width: 42,
                        height: 42,
                        border: `1px solid ${theme.vars.palette.neutral[300]}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                    }}
                >
                    <SearchIcon fontSize="large" />
                </Box>
                <Typography level="h1" textAlign="center">
                    Page not Found
                </Typography>
                <Typography textAlign="center" level="body-sm">
                    The page you are looking for doesn&apos;t exist.
                    <br />
                    Here are some helpful links:
                </Typography>
                <br />
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Button
                        variant="outlined"
                        startDecorator={<ArrowBackIcon />}
                        onClick={() => navigate(-1)}
                    >
                        Go back
                    </Button>
                    <Button variant="solid" component={Link} to={'/'}>
                        Take me home
                    </Button>
                </Stack>
            </>
        );
    } else {
        content = (
            <>
                <Box
                    sx={{
                        width: 42,
                        height: 42,
                        border: `1px solid ${theme.vars.palette.neutral[300]}`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 8,
                        boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                    }}
                >
                    <ResetTvIcon fontSize="large" />
                </Box>
                <Typography level="h1" textAlign="center">
                    Oops! Something went wrong
                </Typography>
                <Typography textAlign="center" level="body-sm">
                    If this keeps happening, please contact us at{' '}
                    <JoyLink variant="soft">support@synapse256.co.uk</JoyLink>
                    <br />
                    <br />
                    Here are some helpful links to take you back to safety:
                </Typography>
                <br />
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Button
                        variant="outlined"
                        startDecorator={<ArrowBackIcon />}
                        onClick={() => navigate(-1)}
                    >
                        Go back
                    </Button>
                    <Button variant="solid" component={Link} to={'/'}>
                        Take me home
                    </Button>
                </Stack>
            </>
        );
    }

    return (
        <CssProvidersWrapper>
            <BackgroundSheet>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        width: '90%',
                        maxWidth: 750,
                    }}
                >
                    {content}
                </Stack>
            </BackgroundSheet>
        </CssProvidersWrapper>
    );
};

export default ErrorPage;
