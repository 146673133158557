import {
    Card,
    CardActions,
    CardOverflow,
    Divider,
    List,
    ListItem,
    Skeleton,
    useTheme,
} from '@mui/joy';
import React from 'react';

const MedicationCardSkeleton = () => {
    const theme = useTheme();

    return (
        <Card
            orientation="vertical"
            size="lg"
            variant="outlined"
            color="neutral"
            sx={{
                maxWidth: 450,
                minWidth: 200,
                width: '100%',
                backgroundColor: `${theme.vars.palette.background.body}`,
            }}
        >
            <CardOverflow
                variant="soft"
                color="neutral"
                sx={{
                    height: 24,
                    px: 0.2,
                    writingMode: 'sideways-rl',
                    justifyContent: 'center',
                    alignContent: 'center',
                    fontSize: 'xs',
                    fontWeight: 'xs',
                    letterSpacing: '1px',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    textAlign: 'center',
                }}
            ></CardOverflow>
            <Skeleton variant="text" level="h4" />
            <Divider inset="none" />
            <List size="sm" sx={{mx: 'calc(-1 * var(--ListItem-paddingX))'}}>
                <ListItem>
                    <Skeleton variant="rectangular" width={24} height={24} />
                    <Skeleton variant="text" />
                </ListItem>
                <ListItem>
                    <Skeleton variant="rectangular" width={24} height={24} />
                    <Skeleton variant="text" />
                </ListItem>
                <ListItem>
                    <Skeleton variant="rectangular" width={24} height={24} />
                    <Skeleton variant="text" />
                </ListItem>
            </List>
            <Divider inset="none" />
            <CardActions
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Skeleton variant="rectangular" width={80} height={22} />
                <Skeleton variant="rectangular" width={137} height={36} />
            </CardActions>
        </Card>
    );
};

export default MedicationCardSkeleton;

MedicationCardSkeleton.propTypes = {};
