import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React from 'react';

import {Box, Button, Card, Typography} from '@mui/joy';
import RadialStackedGauge from 'components/metrics/RadialStackedGauge';
import {getTimeDifference} from 'utilities/dateUtils';

const MetricCard = ({
    title,
    description,
    contextText,
    delimiter = '/',
    units,
    values = [],
    supportingInformation,
    setInfoPanelContents,
}) => {
    return (
        <Card
            variant="outlined"
            sx={{width: '100%', minWidth: '200px', maxWidth: '400px'}}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: 1,
                            }}
                        >
                            <BarChartRoundedIcon color="primary" fontSize="small" />
                            <Typography level="title-lg" color="primary">
                                {title}
                            </Typography>
                            {values && values.some((v) => v.datetime) && (
                                <Typography
                                    level="body-xs"
                                    sx={{alignSelf: 'flex-end'}}
                                >
                                    (
                                    {getTimeDifference(
                                        new Date(
                                            values.reduce((a, b) =>
                                                new Date(a.datetime) >
                                                new Date(b.datetime)
                                                    ? a
                                                    : b,
                                            ).datetime,
                                        ),
                                    )}{' '}
                                    ago)
                                </Typography>
                            )}
                        </Box>
                        <Typography level="body-sm">{description}</Typography>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            my: 1,
                        }}
                    >
                        {values && values.length > 0 && (
                            <RadialStackedGauge
                                height={200}
                                width={200}
                                label={values
                                    .filter((v) => v !== null)
                                    .map((v) => v.value)
                                    .join(delimiter)}
                                supportingLabel={units}
                                values={values}
                            />
                        )}
                    </Box>
                    {/* <Typography level="body-sm">{contextText}</Typography> */}
                    <Button
                        variant="soft"
                        color="neutral"
                        endDecorator={<KeyboardArrowRight />}
                        disabled={
                            (supportingInformation === undefined ||
                                supportingInformation === null) &&
                            !contextText
                        }
                        onClick={() => {
                            setInfoPanelContents({
                                title: title,
                                content: {
                                    ...supportingInformation,
                                    description: contextText,
                                    values: values,
                                },
                            });
                        }}
                        sx={{flexGrow: 1}}
                    >
                        Find out more
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

export default MetricCard;

MetricCard.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    contextText: PropTypes.string,
    delimiter: PropTypes.string,
    units: PropTypes.string,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            datetime: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
            rangeInformation: PropTypes.shape({
                minimum: PropTypes.string.isRequired,
                maximum: PropTypes.string.isRequired,
                rangeDescriptions: PropTypes.arrayOf(
                    PropTypes.shape({
                        minimum: PropTypes.string,
                        maximum: PropTypes.string,
                        sentiment: PropTypes.string.isRequired,
                    }),
                ),
            }).isRequired,
        }),
    ).isRequired,
    supportingInformation: PropTypes.shape({
        videos: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        links: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                url: PropTypes.string,
            }),
        ),
        textContent: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                body: PropTypes.string,
                listItems: PropTypes.arrayOf(PropTypes.string),
            }),
        ),
    }),
    setInfoPanelContents: PropTypes.func,
};
