/* eslint-disable indent */
/* eslint-disable max-len */
import {ApolloError} from '@apollo/client';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import {Box, Grid, Typography, useTheme} from '@mui/joy';
import iNoBounce from 'inobounce';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {formatDate} from 'utilities/dateUtils';
import MedicationCardSkeleton from '../components/MedicationCardSkeleton';
import RepeatMedicationCard from '../components/RepeatMedicationCard';
import MedicationInfoPanel from './MedicationInfoPanel';
/* eslint-enable max-len */

/**
 * A custom action card component displaying a table of the patient's repeat
 * medication
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {Object} props.medicationData - The data for the table rows.
 * @param {Function} props.handleOrderMedication - Handler for the order button.
 * @return {JSX.Element} - The JSX element representing the custom ActionCard
 *   component.
 */
const RepeatMedicationDisplay = ({
    medicationData,
    disableSelection,
    selectedCardsState,
}) => {
    const theme = useTheme();

    const {selectedCards, setSelectedCards} = selectedCardsState;
    const [infoPanelSource, setInfoPanelSource] = useState(null);
    const [infoPanelContents, setInfoPanelContents] = useState(null);
    const [medications, setMedications] = useState(new Map());

    useEffect(() => {
        if (infoPanelContents && iNoBounce.isScrollSupported) {
            iNoBounce.enable();
        } else {
            iNoBounce.disable();
        }
    }, [infoPanelContents]);

    useEffect(() => {
        if (
            medicationData?.data?.me?.repeatMedication?.length > 0 &&
            !medicationData.loading &&
            !medicationData.error &&
            !medicationData.waitingForData
        ) {
            const sortedMedication = [...medicationData.data.me.repeatMedication].sort(
                (a, b) => a.medicineName.localeCompare(b.medicineName),
            );
            const newMedication = new Map();
            sortedMedication.forEach((item) => {
                newMedication.set(item.medicineId, {...item});
            });
            setMedications(newMedication);
        }
    }, [medicationData]);

    useEffect(() => {
        if (infoPanelSource) {
            const medication = medications.get(infoPanelSource.medicineId);

            if (medication?.medicationInformation?.length > 0) {
                if (medication.medicationInformation?.length === 1) {
                    setInfoPanelContents(medication.medicationInformation[0]);
                } else {
                    const informationPage = medication.medicationInformation.find(
                        (item) =>
                            item.informationPageName ===
                            infoPanelSource.informationPageName,
                    );
                    if (informationPage) {
                        setInfoPanelContents(informationPage);
                    }
                }
            }
        }
    }, [infoPanelSource]);

    const handleSelectMedication = (medicineId) => {
        setSelectedCards((prevArray) => {
            if (prevArray.includes(medicineId)) {
                // Remove medicineId from the array
                return prevArray.filter((id) => id !== medicineId);
            } else {
                // Add medicineId to the array
                return [...prevArray, medicineId];
            }
        });
    };

    const handleShowInfo = (medicineId, informationPageName) => {
        setInfoPanelSource({
            medicineId: medicineId,
            informationPageName: informationPageName,
        });
    };

    const displayGrid = () => {
        if (
            !medicationData.loading &&
            !medicationData.error &&
            !medicationData.waitingForData
        ) {
            if (medications.size > 0) {
                return (
                    <>
                        {[...medications.values()].map((medication) => {
                            let informationPages = [];
                            let hasSharedCareDetails = false;

                            // Get a list of the medication pages for the drug.
                            // Check if any of the pages have shared care details, if so
                            // mark the drug as shared care.
                            if (medication?.medicationInformation?.length > 0) {
                                informationPages = medication.medicationInformation.map(
                                    (item) => item.informationPageName,
                                );

                                hasSharedCareDetails =
                                    medication?.medicationInformation?.some(
                                        (item) =>
                                            item?.informationDisplayContent
                                                ?.sharedCareDetails &&
                                            item?.informationDisplayContent
                                                ?.sharedCareDetails.length > 0,
                                    );
                            }

                            return (
                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    key={medication.medicineId}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                    >
                                        <RepeatMedicationCard
                                            cardType="repeatMedication"
                                            disableSelection={disableSelection}
                                            selected={selectedCards.includes(
                                                medication.medicineId,
                                            )}
                                            medicineName={medication.medicineName}
                                            medicineId={medication.medicineId}
                                            dose={medication.dose}
                                            quantity={medication.quantity}
                                            lastIssued={formatDate(
                                                medication.lastIssued,
                                            )}
                                            canBeRequested={medication.canBeRequested}
                                            informationPages={informationPages}
                                            isSharedCareDrug={hasSharedCareDetails}
                                            handleSelectMedication={
                                                handleSelectMedication
                                            }
                                            handleShowInfo={handleShowInfo}
                                        />
                                    </Box>
                                </Grid>
                            );
                        })}
                    </>
                );
            }
        } else {
            return (
                <>
                    {Array.from({length: 4}).map((_, index) => (
                        <Grid
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            key={index} // NOSONAR
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <MedicationCardSkeleton />
                            </Box>
                        </Grid>
                    ))}
                </>
            );
        }
    };

    return (
        <Box
            sx={{
                gap: 2,
                mb: 4,
            }}
        >
            <MedicationInfoPanel
                open={infoPanelContents !== null}
                setInfoPanelContents={setInfoPanelContents}
                medicationName={infoPanelContents?.informationPageName}
                infoPanelContents={infoPanelContents?.informationDisplayContent}
            />
            {!medicationData.loading &&
                !medicationData.error &&
                !medicationData.waitingForData &&
                medicationData?.data?.me?.repeatMedication?.length === 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            my: 8,
                        }}
                    >
                        <Box
                            sx={{
                                width: 42,
                                height: 42,
                                border: `1px solid ${theme.vars.palette.neutral[300]}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 8,
                                boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                            }}
                        >
                            <MedicationRoundedIcon color="primary" fontSize="large" />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography level="title-md" sx={{textAlign: 'center'}}>
                                No repeat medications found
                            </Typography>
                            <Typography level="body-md" sx={{textAlign: 'center'}}>
                                It looks like you don&apos;t have any repeat
                                medications.
                            </Typography>
                        </Box>
                    </Box>
                )}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                gridAutoRows="1fr"
                spacing={{xs: 3, md: 3}}
                sx={{
                    mx: 'auto',
                }}
            >
                {displayGrid()}
            </Grid>
        </Box>
    );
};

RepeatMedicationDisplay.propTypes = {
    selectedCardsState: PropTypes.shape({
        selectedCards: PropTypes.arrayOf(PropTypes.string).isRequired,
        setSelectedCards: PropTypes.func.isRequired,
    }).isRequired,
    disableSelection: PropTypes.bool.isRequired,
    medicationData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        error: PropTypes.instanceOf(ApolloError),
        data: PropTypes.shape({
            me: PropTypes.shape({
                repeatMedication: PropTypes.arrayOf(
                    PropTypes.shape({
                        medicineId: PropTypes.string.isRequired,
                        medicineName: PropTypes.string.isRequired,
                        quantity: PropTypes.string,
                        dose: PropTypes.string,
                        lastIssued: PropTypes.string,
                        canBeRequested: PropTypes.bool.isRequired,
                        issueNumber: PropTypes.number,
                        medicationInformation: PropTypes.arrayOf(PropTypes.shape({})),
                    }),
                ),
            }).isRequired,
        }),
        waitingForData: PropTypes.bool.isRequired,
    }).isRequired,
};

export default RepeatMedicationDisplay;
