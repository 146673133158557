import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import {Box, Card, Typography} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
import {processYoutubeUrl} from 'utilities/urlUtils';

const VideoCard = ({youtubeUrl, title, description}) => {
    return (
        <Card variant="outlined" sx={{width: '100%', maxWidth: '400px'}}>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <iframe
                    src={processYoutubeUrl(youtubeUrl)}
                    title={title}
                    style={{
                        border: 0,
                        width: '100%',
                        height: '200px',
                    }}
                    // eslint-disable-next-line max-len
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </Box>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <OndemandVideoRoundedIcon color="primary" fontSize="small" />
                    <Typography level="title-md" color="primary" component="div">
                        <PlainMarkdown>{title}</PlainMarkdown>
                    </Typography>
                </Box>
                {description && (
                    <Typography level="body-sm" component="div">
                        <StyledMarkdown>{description}</StyledMarkdown>
                    </Typography>
                )}
            </Box>
        </Card>
    );
};
VideoCard.propTypes = {
    youtubeUrl: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    description: propTypes.string,
};

export default VideoCard;
