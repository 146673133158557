import {gql, useMutation} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const SUBMIT_MEDICATION_ORDER_RESPONSES = gql`
    mutation SubmitMedicationOrderResponses(
        $sessionParameters: SessionParametersInput!
        $medicationCheckResponses: [MedicationCheckActionsResultInput!]
    ) {
        submitMedicationOrderResponses(
            sessionParameters: $sessionParameters
            medicationCheckResponses: $medicationCheckResponses
        ) {
            sessionParameters {
                sessionId
                sessionIteration
            }
            checksComplete
            medicationCheckActions {
                medicineId
                actions {
                    hash
                    path
                    type
                    ... on UserQuery {
                        title
                        body
                        supportingText
                        responseType
                    }
                    ... on ImmediateActionQuery {
                        title
                        body
                        supportingText
                        responseType
                        onTruthy {
                            ...ImmediateActionResponseFields
                        }
                        onFalsy {
                            ...ImmediateActionResponseFields
                        }
                    }
                }
            }
        }
    }
    fragment ImmediateActionResponseFields on ImmediateActionResponse {
        action
        flowInterrupt
        ... on ImmediateActionInformationDisplay {
            level
            title
            body
        }
    }
`;

export const useSubmitMedicationOrderResponses = () => {
    const [submitOrderResponses, {loading, error, data}] = useMutation(
        SUBMIT_MEDICATION_ORDER_RESPONSES,
        {
            notifyOnNetworkStatusChange: true,
        },
    );

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        submitOrderResponses,
        loading,
        error,
        data,
    };
};
