import {AspectRatio, Card, CardContent, CardOverflow, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const HomeCard = ({Icon, title, supportingText, destination}) => {
    return (
        <Link to={destination} style={{textDecoration: 'none'}}>
            <Card
                sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    maxWidth: 343,
                    '--icon-size': '100px',
                }}
            >
                <CardOverflow variant="solid" color="primary">
                    <AspectRatio
                        variant="outlined"
                        color="primary"
                        ratio="1"
                        sx={{
                            m: 'auto',
                            transform: 'translateY(50%)',
                            borderRadius: '50%',
                            width: 'var(--icon-size)',
                            boxShadow: 'sm',

                            bgcolor: 'background.surface',
                            position: 'relative',
                        }}
                    >
                        <div>
                            <Icon color="primary" sx={{fontSize: '4rem'}} />
                        </div>
                    </AspectRatio>
                </CardOverflow>
                <Typography level="title-lg" sx={{mt: 'calc(var(--icon-size) / 2)'}}>
                    {title}
                </Typography>
                <CardContent sx={{maxWidth: '40ch'}}>{supportingText}</CardContent>
            </Card>
        </Link>
    );
};

export default HomeCard;

HomeCard.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    supportingText: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
};
