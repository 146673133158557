import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    // Fade,
    IconButton,
    Typography,
    useTheme,
} from '@mui/joy';
import FormHeader from 'components/forms/FormHeader';
import {useConfirmMedicationOrder} from 'graphql/confirmMedicationOrder';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {getColourMode} from 'utilities/colourUtilities';
import PharmacyCard from '../pharmacy/components/PharmacyCard';

const CustomSnackbar = React.forwardRef(
    ({closeSnackbar, snackbarId, children, ...props}, ref) => (
        <Alert
            ref={ref}
            endDecorator={
                <IconButton
                    variant="solid"
                    color={props.color ? props.color : 'neutral'}
                    onClick={() => closeSnackbar(snackbarId)}
                >
                    <CloseRoundedIcon />
                </IconButton>
            }
            {...props}
        >
            {children}
        </Alert>
    ),
);

CustomSnackbar.displayName = 'CustomSnackbar';

CustomSnackbar.propTypes = {
    children: PropTypes.node.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    snackbarId: PropTypes.number,
    color: PropTypes.string,
};

const MedicationCard = ({medication}) => {
    return (
        <Card sx={{minWidth: 150}}>
            <CardContent>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Typography level="title-md">{medication.medicineName}</Typography>
                    {medication.nhsMedicinesUrls?.length === 1 && (
                        <IconButton
                            color="primary"
                            component={Link}
                            to={medication.nhsMedicinesUrls[0].webUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InfoOutlinedIcon />
                        </IconButton>
                    )}
                </Box>
                <Typography level="body-sm">
                    {medication.quantity} | {medication.dose}
                </Typography>
            </CardContent>
        </Card>
    );
};

MedicationCard.propTypes = {
    medication: PropTypes.shape({
        medicineId: PropTypes.string.isRequired,
        medicineName: PropTypes.string.isRequired,
        quantity: PropTypes.string,
        dose: PropTypes.string,
        lastIssued: PropTypes.string,
        canBeRequested: PropTypes.bool.isRequired,
        issueNumber: PropTypes.number,
        nhsMedicinesUrls: PropTypes.arrayOf(
            PropTypes.shape({
                baseName: PropTypes.string,
                webUrl: PropTypes.string,
            }),
        ),
    }).isRequired,
};

const SubmitOrderStepBody = ({
    scrollableRef,
    selectedMedications,
    selectedPharmacy,
    orderState,
    handleCompleteMedicationOrder,
    handleBack,
    setLoading,
    skippedMedicationChecks,
}) => {
    const theme = useTheme();
    const mode = getColourMode();

    const [waitingForConfirmation, setWaitingForConfirmation] = useState(false);
    const {confirmMedicationOrder, error, loading, data} = useConfirmMedicationOrder();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handleConfirmOrder = () => {
        setWaitingForConfirmation(true);
        const sessionParameters = {
            sessionId:
                orderState.sessionParameters.sessionId !== undefined
                    ? orderState.sessionParameters.sessionId
                    : null,
            sessionIteration:
                orderState.sessionParameters.sessionIteration !== undefined
                    ? orderState.sessionParameters.sessionIteration
                    : null,
        };
        const pharmacy = {
            odsCode: selectedPharmacy.odsCode,
            postcode: selectedPharmacy.postcode,
        };
        confirmMedicationOrder(sessionParameters, pharmacy);
    };

    useEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }, []);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (data?.confirmMedicationOrder) {
            const orderConfirmations = data.confirmMedicationOrder;

            const allOrdersSuccessful = orderConfirmations.every(
                (order) => order.responseCode === 'MedicationOrdered',
            );
            setWaitingForConfirmation(false);
            handleCompleteMedicationOrder();
            if (allOrdersSuccessful) {
                enqueueSnackbar('Medications order submitted!', {
                    persist: false,
                    content: (key, message) => (
                        <CustomSnackbar
                            variant="solid"
                            color="success"
                            startDecorator={<DoneAllOutlinedIcon />}
                            closeSnackbar={closeSnackbar}
                            snackbarId={key}
                        >
                            {message}
                        </CustomSnackbar>
                    ),
                });
            } else {
                orderConfirmations.forEach((order) => {
                    if (order.responseCode === 'MedicationOrdered') {
                        enqueueSnackbar(order.responseMessage, {
                            persist: false,
                            content: (key, message) => (
                                <CustomSnackbar
                                    variant="solid"
                                    color="success"
                                    startDecorator={<DoneOutlinedIcon />}
                                    closeSnackbar={closeSnackbar}
                                    snackbarId={key}
                                >
                                    {message}
                                </CustomSnackbar>
                            ),
                        });
                    } else {
                        enqueueSnackbar(order.responseMessage, {
                            persist: true,
                            content: (key, message) => (
                                <CustomSnackbar
                                    variant="solid"
                                    color="danger"
                                    startDecorator={<ErrorOutlineOutlinedIcon />}
                                    closeSnackbar={closeSnackbar}
                                    snackbarId={key}
                                >
                                    {message}
                                </CustomSnackbar>
                            ),
                        });
                    }
                });
            }
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setWaitingForConfirmation(false);
            enqueueSnackbar(
                // eslint-disable-next-line max-len
                'Failed to order medications! If this continues, please contact your GP surgery to order your medications.',
                {
                    persist: true,
                    content: (key, message) => (
                        <CustomSnackbar
                            variant="solid"
                            color="danger"
                            startDecorator={<ErrorOutlineOutlinedIcon />}
                            closeSnackbar={closeSnackbar}
                            snackbarId={key}
                        >
                            {message}
                        </CustomSnackbar>
                    ),
                },
            );
        }
    }, [error]);

    const backgroundSuccess =
        mode === 'light' ? theme.palette.success[50] : theme.palette.success[900];
    const colorSuccess =
        mode === 'light' ? theme.palette.success[700] : theme.palette.success[200];
    const backgroundWarning =
        mode === 'light' ? theme.palette.warning[50] : theme.palette.warning[900];
    const colorWarning =
        mode === 'light' ? theme.palette.warning[700] : theme.palette.warning[200];

    return (
        <Box sx={{mt: 2, mb: 1, display: 'flex', flexDirection: 'column'}}>
            <FormHeader
                title="Confirm your request"
                supportingText={
                    'Review your order carefully. Your order has not been submitted ' +
                    'yet. ' +
                    'To finalize and submit your order, please click the ' +
                    "'Confirm request' button."
                }
            />
            <Divider sx={{my: 2}} />
            <Box className="scrollable" sx={{flexGrow: 1, pb: 2}}>
                {orderState.checksComplete && !skippedMedicationChecks && (
                    <Alert
                        color="success"
                        variant="outlined"
                        sx={{
                            mb: 2,
                            backgroundColor: backgroundSuccess,
                            alignItems: 'flex-start',
                        }}
                        startDecorator={
                            <AssignmentTurnedInIcon
                                sx={{
                                    color: colorSuccess,
                                }}
                            />
                        }
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography
                                level="title-md"
                                color="success"
                                gutterBottom
                                sx={{
                                    color: colorSuccess,
                                }}
                            >
                                Health checks complete
                            </Typography>
                            <Typography
                                level="body-sm"
                                color="success"
                                sx={{
                                    color: colorSuccess,
                                }}
                            >
                                We&apos;ve collected all the necessary details. Next,
                                we&apos;ll pass them with your prescription request to
                                your GP.
                            </Typography>
                        </Box>
                    </Alert>
                )}
                {orderState.checksComplete && skippedMedicationChecks && (
                    <Alert
                        color="warning"
                        variant="outlined"
                        sx={{
                            mb: 2,
                            backgroundColor: backgroundWarning,
                            alignItems: 'flex-start',
                        }}
                        startDecorator={
                            <HelpOutlineIcon
                                sx={{
                                    color: colorWarning,
                                }}
                            />
                        }
                    >
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Typography
                                level="title-md"
                                color="warning"
                                gutterBottom
                                sx={{
                                    color: colorWarning,
                                }}
                            >
                                Health checks skipped!
                            </Typography>
                            <Typography
                                level="body-sm"
                                color="warning"
                                sx={{
                                    color: colorWarning,
                                }}
                            >
                                You missed the health checks. To help your GP with your
                                prescription, they might need extra info from you. Feel
                                free to go back and complete them if you&apos;d like.
                            </Typography>
                        </Box>
                    </Alert>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        gap: 4,
                        animation: 'fadein 1s',
                        '@keyframes fadein': {
                            from: {opacity: 0},
                            to: {opacity: 1},
                        },
                    }}
                >
                    <Box sx={{flex: 1}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', mb: 1}}>
                            <Typography level="title-md">Medications</Typography>
                            <Typography>
                                Your order contains {selectedMedications.length} item
                                {selectedMedications.length > 1 && 's'}:
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
                            {selectedMedications.map((medication) => (
                                <MedicationCard
                                    key={medication.medicineId}
                                    medication={medication}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{flex: 1}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', mb: 1}}>
                            <Typography level="title-md">Pharmacy</Typography>
                            <Typography>
                                We&apos;re requesting that your order is sent to:
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <PharmacyCard
                                pharmacy={selectedPharmacy}
                                infoOnlyDisplay={true}
                            />
                            <Button
                                variant="solid"
                                size="lg"
                                onClick={handleConfirmOrder}
                                loading={waitingForConfirmation}
                            >
                                Confirm Request
                            </Button>
                            <Button variant="outlined" onClick={handleBack}>
                                Back
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SubmitOrderStepBody;

SubmitOrderStepBody.propTypes = {
    scrollableRef: PropTypes.any,
    handleBack: PropTypes.func,
    orderState: PropTypes.shape({
        checksComplete: PropTypes.bool,
        sessionParameters: PropTypes.shape({
            sessionId: PropTypes.string,
            sessionIteration: PropTypes.number,
        }),
    }),
    handleCompleteMedicationOrder: PropTypes.func.isRequired,
    selectedMedications: PropTypes.arrayOf(
        PropTypes.shape({
            medicineId: PropTypes.string.isRequired,
            medicineName: PropTypes.string.isRequired,
            quantity: PropTypes.string,
            dose: PropTypes.string,
            lastIssued: PropTypes.string,
            canBeRequested: PropTypes.bool.isRequired,
            issueNumber: PropTypes.number,
        }),
    ).isRequired,
    selectedPharmacy: PropTypes.shape({
        odsCode: PropTypes.string,
        organisationName: PropTypes.string.isRequired,
        organisationType: PropTypes.string,
        url: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        organisationSubType: PropTypes.string,
        postcode: PropTypes.string,
    }).isRequired,
    setLoading: PropTypes.func.isRequired,
    skippedMedicationChecks: PropTypes.bool,
};
