import {gql, useQuery} from '@apollo/client';
import {useProgress} from 'contexts/ProgressProvider';
import {useEffect, useState} from 'react';
import {logError} from 'utilities/apolloUtils';

export const FETCH_MY_CONDITIONS = gql`
    query GetMyConditions {
        me {
            conditions {
                datetime
                definition {
                    title
                    summary
                    sections {
                        title
                        description
                        content {
                            ... on ResultBlock {
                                title
                                description
                                shortDescription
                                displayType
                                unit
                                delimiter
                                values {
                                    label
                                    datetime
                                    value
                                    rangeInformation {
                                        minimum
                                        maximum
                                        rangeDescriptions {
                                            minimum
                                            maximum
                                            sentiment
                                        }
                                    }
                                }
                                supportingInformation {
                                    links {
                                        title
                                        description
                                        url
                                    }
                                    videos {
                                        title
                                        description
                                        youtubeUrl
                                    }
                                    textContent {
                                        title
                                        body
                                        listItems
                                    }
                                }
                            }
                            ... on VideoBlock {
                                title
                                description
                                youtubeUrl
                            }
                            ... on LinkBlock {
                                title
                                description
                                url
                            }
                            ... on TextBlock {
                                title
                                body
                                listItems
                            }
                            ... on ActionRequiredBlock {
                                title
                                description
                                listItems
                                actionType
                                requiredAction {
                                    instruction
                                }
                            }
                            ... on ActionDoneBlock {
                                title
                                description
                                listItems
                                actionType
                                requiredAction {
                                    instruction
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useFetchMyConditions = () => {
    const [patientRegistered, setPatientRegistered] = useState(false);
    const {availableData} = useProgress();

    const {loading, error, data, refetch} = useQuery(FETCH_MY_CONDITIONS, {
        skip: !patientRegistered,
    });

    useEffect(() => {
        const statusesWherePatientIsRegistered = ['PatientId', 'MedicalRecord'];
        const containsAny = statusesWherePatientIsRegistered.some((value) =>
            availableData.includes(value),
        );

        if (!patientRegistered && containsAny) {
            setPatientRegistered(true);
        }
    }, [availableData]);

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        loading,
        waitingForData: !patientRegistered,
        error,
        data,
        refetch,
    };
};
