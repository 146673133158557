import {Button, Link, Stack, Typography} from '@mui/joy';
import 'App.css';
import {signOut} from 'aws-amplify/auth';
import PocketGpLogoHeader from 'logos/PocketGpLogoHeader';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundSheet from './components/BackgroundSheet';
import LoginCard from './components/LoginCard';

const PocketGpUnavailablePage = ({patientDataSettings}) => {
    const getTextForUnavailabilityReason = () => {
        if (patientDataSettings?.hasGpCredentials === false) {
            return (
                <>
                    <p>
                        Your NHS login was recognised, but{' '}
                        <b>
                            your account isn&apos;t linked to your GP Surgery or Medical
                            Record
                        </b>
                        .
                    </p>
                    <p>
                        Please contact your GP surgery to enable access to your
                        &quot;Detailed coded medical record&quot; through your NHS
                        account.
                    </p>
                    <p>
                        PocketGP requires this access to function, so we&apos;re unable
                        to proceed without it.
                    </p>
                </>
            );
        }
        if (patientDataSettings?.gpSurgeryHasPocketGpSubscription === false) {
            return (
                <>
                    <p>
                        Your NHS login was recognised, but{' '}
                        <b>your GP surgery isn&apos;t subscribed to PocketGP</b>.
                    </p>
                    <p>
                        We&apos;re unable to load PocketGP without a subscription from
                        your surgery.
                    </p>
                    <p>
                        If you believe this is an error, or you&apos;d like us to reach
                        out to your GP surgery about PocketGP, please email{' '}
                        <Link href="mailto:support@pocketgp.app">
                            support@pocketgp.app
                        </Link>
                        .
                    </p>
                </>
            );
        }
        if (patientDataSettings?.canUsePocketGp === false) {
            return (
                <>
                    <p>
                        Your NHS login was recognised, but{' '}
                        <b>
                            we&apos;re unable to access the necessary data from your
                            medical record due to your GP surgery&apos;s settings or
                            your medical record settings
                        </b>
                        .
                    </p>
                    <p>
                        Access to your complete medical record is required for PocketGP
                        to function.
                    </p>
                    <p>
                        Please contact your GP surgery to request access to your
                        &quot;Detailed coded medical record&quot; via your NHS account.
                    </p>
                    <p>
                        If you believe this message is in error, or you&apos;d like more
                        support please email us at{' '}
                        <Link href="mailto:support@pocketgp.app">
                            support@pocketgp.app
                        </Link>
                        .
                    </p>
                </>
            );
        }

        return (
            <>
                <p>
                    Your NHS login was successful, but we encountered an issue loading
                    PocketGP.
                </p>
                <p>
                    Please log in again. If the issue persists, contact your GP surgery
                    to check if access to your Medical Record is enabled.
                </p>
                <p>
                    Should the problem remain, email us at{' '}
                    {/* eslint-disable-next-line max-len */}
                    <Link href="mailto:support@pocketgp.app">
                        support@pocketgp.app
                    </Link>{' '}
                    for further assistance.
                </p>
            </>
        );
    };

    return (
        <BackgroundSheet>
            <LoginCard>
                <PocketGpLogoHeader />
                <Typography
                    level="h3"
                    sx={{
                        mx: 2,
                        textAlign: 'center',
                    }}
                >
                    There was an issue accessing PocketGP
                </Typography>
                <Typography
                    sx={{
                        m: 2,
                    }}
                    component="div"
                >
                    <Stack direction="column" spacing={2}>
                        {getTextForUnavailabilityReason()}
                    </Stack>
                </Typography>
                <Button variant="solid" onClick={async () => signOut()}>
                    Logout
                </Button>
            </LoginCard>
        </BackgroundSheet>
    );
};

PocketGpUnavailablePage.propTypes = {
    patientDataSettings: PropTypes.shape({
        hasGpCredentials: PropTypes.bool,
        gpSurgeryHasPocketGpSubscription: PropTypes.bool,
        canUsePocketGp: PropTypes.bool,
    }),
};

export default PocketGpUnavailablePage;
