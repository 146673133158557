/* eslint-disable max-len */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import {
    Alert,
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Option,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/joy';
import NhsSyndicationApisLogo from 'components/accreditationItems/NhsSyndicationApisLogo';
import FormHeader from 'components/forms/FormHeader';
import FormSection from 'components/forms/FormSection';
import {useSearchPharmacies} from 'graphql/searchPharmacies';
import {useSetMyPreferredPharmacy} from 'graphql/setPreferredPharmacy';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import {isPostcode, toTitleCase} from 'utilities/stringFunctions';
import PharmacyCard from './components/PharmacyCard';
import PharmacyCardSkeleton from './components/PharmacyCardSkeleton';
/* eslint-enable max-len */

const formatString = (str) => {
    return isPostcode(str) ? str.toUpperCase() : toTitleCase(str);
};

const validateInput = (value, type) => {
    // Add more validation logic based on type
    return value.trim() !== '';
};

const PharmacyStepBody = ({
    scrollableRef,
    setProgressValue,
    preferredPharmacy,
    setPreferredPharmacy,
    setSelectedPharmacy,
    handleNext,
    setLoading,
}) => {
    const theme = useTheme();
    const mode = getColourMode();
    const {enqueueSnackbar} = useSnackbar();

    const [searchType, setSearchType] = useState('location');
    const [inputValue, setInputValue] = useState('');
    const [queryCount, setQueryCount] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [state, setState] = useState('search');

    const backgroundWarning =
        mode === 'light' ? theme.palette.warning[50] : theme.palette.warning[900];
    const colorWarning =
        mode === 'light' ? theme.palette.warning[700] : theme.palette.warning[200];

    const savePreferredPharmacy = useSetMyPreferredPharmacy();
    const {getPharmacies, loading, error, data} = useSearchPharmacies();
    const sortedPharmacies = useMemo(() => {
        if (!Array.isArray(data)) {
            return [];
        }

        const dataCopy = [...data];
        return dataCopy.sort((a, b) => b.pharmacy.searchScore - a.pharmacy.searchScore);
    }, [data]);

    useEffect(() => {
        setLoading(loading);
        if ((data || error) && loading === false) {
            setState('displaySearchResults');
            setProgressValue(40);
        } else if (loading) {
            setState('searching');
        }
    }, [data, error, loading, queryCount]);

    const getErrorDetails = (err) => {
        let title;
        let description;
        let icon;

        switch (err.errorType) {
            case 'AMBIGUOUS_LOCATION':
                title = 'Ambiguous Search Location';
                // eslint-disable-next-line max-len
                description = `Your search for '${formatString(inputValue)}' gave multiple results.\n\n${err.message}\n\nPlease refine your search.`;
                icon = <NotListedLocationIcon />;
                break;
            case 'NOT_FOUND':
                title = 'No Pharmacies Found';
                // eslint-disable-next-line max-len
                description = `We couldn't find any pharmacies matching '${formatString(inputValue)}'.\n\n${err.message}`;
                icon = <NotListedLocationIcon />;
                break;
            default:
                title = `Error: ${err.errorType}`;
                description = err.message;
                icon = <ErrorOutlineIcon />;
        }

        return {title, description, icon};
    };

    const handleSelectPharmacy = async (pharmacy, setPreferred = false) => {
        if (setPreferred) {
            const result = await savePreferredPharmacy.setPreferredPharmacy(pharmacy);
            if (result) {
                setPreferredPharmacy(result);
            } else {
                const message =
                    'There was an error saving your preferred Pharmacy. ' +
                    'You can still order your medication.';
                const options = {variant: 'warning'};

                enqueueSnackbar(message, options);
            }
        }

        setSelectedPharmacy(pharmacy);
        handleNext('medicationChecks');
    };

    const handleBack = () => {
        setState('search');
    };

    const handleSearchTypeChange = useCallback(
        (event, newValue) => {
            if (event) {
                setSearchType(newValue);
                setIsButtonDisabled(!validateInput(inputValue, newValue));
            }
        },
        [inputValue],
    );

    const handleInputChange = useCallback(
        (event) => {
            setInputValue(event.target.value);
            setIsButtonDisabled(!validateInput(event.target.value, searchType));
        },
        [searchType],
    );

    const handleSubmitSearch = () => {
        getPharmacies({
            variables: {
                query: formatString(inputValue),
                searchByLocation: searchType === 'location',
                limit: 10,
            },
        });
        setProgressValue(20);
        setQueryCount(queryCount + 1);

        if (scrollableRef.current) {
            scrollableRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    };

    const handlePharmacySelect = (pharmacy) => {
        setSelectedPharmacy(pharmacy);
        setProgressValue(50);
        handleNext('medicationChecks');
    };

    const searchLabel = useMemo(
        () => (searchType === 'name' ? 'Pharmacy Name' : 'Postcode'),
        [searchType],
    );

    const renderContent = () => {
        if (state === 'search') {
            return (
                <>
                    {preferredPharmacy && (
                        <>
                            <FormSection
                                title="Previously used Pharmacy"
                                // eslint-disable-next-line max-len
                                supportingText="Should we use the same Pharmacy as last time?"
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PharmacyCard
                                        pharmacy={preferredPharmacy}
                                        onPharmacySelect={handlePharmacySelect}
                                    />
                                </Box>
                            </FormSection>
                            <Divider sx={{mt: 2, mb: 2}} />
                        </>
                    )}

                    <Box>
                        <FormSection
                            title="Choose a different Pharmacy"
                            // eslint-disable-next-line max-len
                            supportingText="Search for a Pharmacy by its name or a postcode."
                            supportingContent={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {xs: 'row', sm: 'column'},
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <NhsSyndicationApisLogo />
                                </Box>
                            }
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Stack direction="column" gap={2} sx={{width: '100%'}}>
                                    <FormControl>
                                        <FormLabel id="select-search-by-label">
                                            Search by
                                        </FormLabel>
                                        <Select
                                            label-id="select-search-by-label"
                                            id="select-search-by-helper"
                                            defaultValue="Postcode"
                                            value={searchType}
                                            label="Search by"
                                            onChange={handleSearchTypeChange}
                                            sx={{maxWidth: 300}}
                                        >
                                            <Option value="name">Pharmacy name</Option>
                                            <Option value="location">Postcode</Option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <Input
                                            id="search-input"
                                            label={searchLabel}
                                            value={inputValue}
                                            placeholder={
                                                searchType === 'name'
                                                    ? 'Pharmacy name'
                                                    : 'XM4 5HQ'
                                            }
                                            onChange={handleInputChange}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === 'Enter' &&
                                                    !isButtonDisabled
                                                ) {
                                                    handleSubmitSearch();
                                                }
                                            }}
                                            sx={{maxWidth: 300}}
                                        />
                                        {searchType === 'name' && (
                                            <FormHelperText
                                                sx={{
                                                    maxWidth: 250,
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                Search for a Pharmacy by its name. The
                                                name must be exact (including or
                                                excluding) spaces.
                                            </FormHelperText>
                                        )}
                                        {searchType === 'location' && (
                                            <FormHelperText
                                                sx={{
                                                    maxWidth: 250,
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                Enter the postcode that you would like
                                                to search for Pharmacies close to.
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <Button
                                        variant="solid"
                                        color="primary"
                                        onClick={handleSubmitSearch}
                                        disabled={isButtonDisabled}
                                        sx={{minWidth: 100, alignSelf: 'flex-end'}}
                                    >
                                        Search
                                    </Button>
                                </Stack>
                            </Box>
                        </FormSection>
                    </Box>
                </>
            );
        } else if (state === 'searching') {
            const pharmacySearchText =
                "We're looking for Pharmacies based on your search for '" +
                formatString(inputValue) +
                "'...";

            return (
                <Box sx={{mb: 1}}>
                    <FormHeader
                        title="Searching for Pharmacies..."
                        supportingText={pharmacySearchText}
                    />
                    <Box>
                        <Stack
                            direction="column"
                            gap={2}
                            alignItems="center"
                            sx={{mt: 4, mb: 1}}
                        >
                            <PharmacyCardSkeleton />
                            <PharmacyCardSkeleton />
                        </Stack>
                    </Box>
                </Box>
            );
        } else if (state === 'displaySearchResults') {
            return (
                <>
                    <Box sx={{mt: 2, mb: 1}}>
                        {data && data.length !== 0 && !error && (
                            <FormHeader
                                title="Select a Pharmacy for Your Prescription"
                                supportingText={[
                                    <span key="FormHeaderText1">
                                        Based on your search for{' '}
                                    </span>,
                                    <span key="FormHeaderText2">
                                        <strong>{formatString(inputValue)}</strong>.
                                    </span>,
                                    <span key="FormHeaderText3">
                                        {' '}
                                        we found these Pharmacies. Where should we send
                                        your
                                    </span>,
                                    <span key="text4"> prescription?</span>,
                                ]}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            my: 4,
                        }}
                    >
                        {!data && !error && <PharmacyCardSkeleton />}
                        {sortedPharmacies?.map((pharmacy, index) => (
                            <PharmacyCard
                                key={pharmacy?.pharmacy?.odsCode}
                                pharmacy={pharmacy?.pharmacy}
                                onPharmacySelect={handleSelectPharmacy}
                            />
                        ))}
                        {error && (
                            <>
                                {error.graphQLErrors.map((err) => {
                                    const {title, description, icon} =
                                        getErrorDetails(err);

                                    return (
                                        <Alert
                                            color="danger"
                                            key={err.message}
                                            startDecorator={icon}
                                            sx={{alignItems: 'flex-start'}}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    level="title-lg"
                                                    gutterBottom
                                                >
                                                    {title}
                                                </Typography>
                                                <Typography
                                                    sx={{whiteSpace: 'pre-line'}}
                                                >
                                                    {description}
                                                </Typography>
                                            </Box>
                                        </Alert>
                                    );
                                })}
                            </>
                        )}
                        {data && data.length === 0 && (
                            <Alert
                                color="neutral"
                                startDecorator={<NotListedLocationIcon />}
                                sx={{alignItems: 'flex-start'}}
                            >
                                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                    <Typography level="title-lg" gutterBottom>
                                        We couldn&apos;t find any pharmacies near that
                                        location or by that name
                                    </Typography>
                                    <Typography sx={{whiteSpace: 'pre-line'}}>
                                        Please try again with a more specific location,
                                        or a different name.
                                    </Typography>
                                </Box>
                            </Alert>
                        )}
                        <Button
                            variant="outlined"
                            onClick={handleBack}
                            sx={{
                                maxWidth: 200,
                                width: '100%',
                                alignSelf: {xs: 'center', sm: 'flex-start'},
                            }}
                        >
                            Back
                        </Button>
                    </Box>
                </>
            );
        }
    };

    return (
        <Box sx={{mt: 2, display: 'flex', flexDirection: 'column'}}>
            <FormHeader
                title="Where should we send your prescription?"
                supportingText={
                    'We need to know which Pharmacy you would like your ' +
                    'prescription sent to.'
                }
                supportingContent={
                    <Alert
                        variant="outlined"
                        color="warning"
                        sx={{backgroundColor: backgroundWarning}}
                        startDecorator={
                            <WarningAmberRoundedIcon
                                sx={{
                                    color: colorWarning,
                                }}
                            />
                        }
                    >
                        <Typography level="body-sm" sx={{color: colorWarning}}>
                            Whichever Pharmacy you choose will become your NHS nominated
                            Pharmacy.
                        </Typography>
                    </Alert>
                }
            />
            <Divider sx={{my: 2}} />
            <Box className="scrollable" sx={{flexGrow: 1, pb: 2}}>
                {renderContent()}
            </Box>
        </Box>
    );
};

PharmacyStepBody.propTypes = {
    scrollableRef: PropTypes.any,
    setProgressValue: PropTypes.func.isRequired,
    preferredPharmacy: PropTypes.shape({
        odsCode: PropTypes.string,
        organisationName: PropTypes.string.isRequired,
        organisationType: PropTypes.string,
        url: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        organisationSubType: PropTypes.string,
        postcode: PropTypes.string,
    }),
    setPreferredPharmacy: PropTypes.func.isRequired,
    setSelectedPharmacy: PropTypes.func.isRequired,
    handleNext: PropTypes.func,
    setLoading: PropTypes.func.isRequired,
};

export default PharmacyStepBody;
