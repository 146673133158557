import {Amplify} from 'aws-amplify';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {ConsoleLogger, CookieStorage} from 'aws-amplify/utils';
import React from 'react';
import {RouterProvider} from 'react-router-dom';
import './App.css';
import router from './Router';

ConsoleLogger.LOG_LEVEL = 'DEBUG'
    ? process.env.REACT_APP_HOSTED_DOMAIN.includes('dev')
    : 'ERROR'; // Set log level to DEBUG

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
            identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
            identityPoolRegion: process.env.REACT_APP_COGNITO_IDENTITY_POOL_REGION,
            region: process.env.REACT_APP_COGNITO_REGION,

            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
                    scopes: ['openid'],
                    redirectSignIn: [process.env.REACT_APP_HOSTED_URL],
                    redirectSignOut: [process.env.REACT_APP_HOSTED_URL],
                    responseType: 'code',
                },
            },
        },
    },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
        domain: process.env.REACT_APP_HOSTED_DOMAIN,
        secure: true,
        expires: 0.5,
        sameSite: 'strict',
    }),
);

/**
 * Render the main App component with the provided RouterProvider.
 * App() is just another component. It is just special in that it wraps around every
 * other react component.
 *
 * @function
 * @return {JSX.Element} The JSX element representing the App component.
 */
function App() {
    return <RouterProvider router={router} />;
}

export default App;
