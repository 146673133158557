import {gql, useQuery} from '@apollo/client';
import {useProgress} from 'contexts/ProgressProvider';
import {useEffect, useState} from 'react';
import {logError} from 'utilities/apolloUtils';

export const FETCH_MY_PAST_MEDICATION = gql`
    query GetMyPastMedication {
        me {
            pastMedication {
                medicineName
                latestPrescription
                prescriptions {
                    date
                    dose
                    prescriptionType
                    quantity
                    quantityUnit
                    status
                }
                medicationInformation {
                    informationPageName
                    informationDisplayContent {
                        summary
                        sharedCareDetails {
                            title
                            body
                            url
                            listItems
                        }
                        description
                        textContent {
                            title
                            body
                            url
                            listItems
                        }
                        links {
                            title
                            description
                            url
                        }
                        videos {
                            title
                            description
                            videos {
                                title
                                description
                                youtubeUrl
                            }
                        }
                        safetyChecks {
                            title
                            description
                            checks {
                                title
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useFetchMyPastMedication = () => {
    const [patientRegistered, setPatientRegistered] = useState(false);
    const {availableData} = useProgress();

    const {loading, error, data} = useQuery(FETCH_MY_PAST_MEDICATION, {
        skip: !patientRegistered,
        fetchPolicy: 'cache-first',
    });

    useEffect(() => {
        const statusesWherePatientIsRegistered = ['PatientId', 'MedicalRecord'];
        const containsAny = statusesWherePatientIsRegistered.some((value) =>
            availableData.includes(value),
        );

        if (!patientRegistered && containsAny) {
            setPatientRegistered(true);
        }
    }, [availableData]);

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        loading,
        waitingForData: !patientRegistered,
        error,
        data,
    };
};
