// eslint-disable-next-line max-len
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import MedicationIcon from '@mui/icons-material/Medication';
import {Box, IconButton, Tab, TabList, TabPanel, Tabs, useTheme} from '@mui/joy';
import {tabClasses} from '@mui/joy/Tab';
import PageHeader from 'components/headers/PageHeader';
import {useFetchMyPastMedication} from 'graphql/myPastMedication';
import {useFetchMyRepeatMedication} from 'graphql/myRepeatMedication';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import PastMedicationDisplay from './displayMedications/PastMedicationDisplay';
import RepeatMedicationDisplay from './displayMedications/RepeatMedicationDisplay';
import OrderMedicationModal from './orderMedications/OrderMedicationModal';

const HeaderButtons = ({children}) => {
    return (
        <Box sx={{display: {xs: 'none', sm: 'flex'}, flexDirection: 'row', gap: 1}}>
            {children}
        </Box>
    );
};

HeaderButtons.propTypes = {
    children: PropTypes.node.isRequired,
};

const MyMedications = () => {
    const theme = useTheme();

    const [showOrderMedicationModal, setShowOrderMedicationModal] =
        React.useState(false);
    const [selectedMedications, setSelectedMedications] = React.useState([]);
    const [selectedCards, setSelectedCards] = React.useState([]);
    const [disableSelection, setDisableSelection] = React.useState(false);

    const title = 'My Medication';

    const breadcrumbData = [{href: '/medication', text: 'My Medication'}];

    const medicationData = {
        ...useFetchMyRepeatMedication(),
    };

    const pastMedicationData = {
        ...useFetchMyPastMedication(),
    };

    const handleCompleteMedicationOrder = async () => {
        setDisableSelection(true);
        setShowOrderMedicationModal(false);
        setSelectedMedications([]);
        setSelectedCards([]);

        try {
            await medicationData.refetch();
            // If the data returned is the same, the useEffect hooks won't trigger.
            // So we need to manually set the disableSelection state.
            setDisableSelection(false);
        } catch (error) {
            console.error('Refetch failed:', error);
        }
    };

    useEffect(() => {
        if (medicationData.error) {
            console.error(medicationData.error);
        }
    }, [medicationData.error]);

    useEffect(() => {
        if (medicationData.loading) {
            setSelectedMedications([]);
            setSelectedCards([]);
            setDisableSelection(true);
        } else {
            setDisableSelection(false);
        }
    }, [medicationData.loading, medicationData.data]);

    const handleCloseOrderMedicationModal = () => {
        setDisableSelection(false);
        setShowOrderMedicationModal(false);
    };
    const handleOpenOrderMedicationModal = () => {
        setShowOrderMedicationModal(true);
        setDisableSelection(true);
    };

    const handleOrderMedications = () => {
        if (selectedCards.length > 0) {
            const allMedications = medicationData.data?.me?.repeatMedication;

            if (allMedications) {
                setSelectedMedications(
                    allMedications.filter((medication) =>
                        selectedCards.includes(medication.medicineId),
                    ),
                );
                handleOpenOrderMedicationModal();
            } else {
                console.warn('allMedications is undefined or null');
            }
        }
    };

    return (
        <>
            <PageHeader
                title={title}
                breadcrumbs={breadcrumbData}
                icon={<MedicationIcon fontSize="medium" />}
                iconAlt="My Medication"
            />
            <Tabs
                aria-label="tabs"
                defaultValue={0}
                sx={{p: 1, bgcolor: 'transparent'}}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <TabList
                        disableUnderline
                        sx={{
                            p: 0.5,
                            gap: 0.5,
                            borderRadius: 'lg',
                            bgcolor: 'background.level1',
                            justifyContent: 'center',
                            [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                boxShadow: 'sm',
                                bgcolor: 'background.surface',
                            },
                            [`&& .${tabClasses.root}`]: {
                                flex: 'initial',
                                [`&.${tabClasses.selected}`]: {
                                    fontWeight: '600',
                                },
                            },
                        }}
                    >
                        <Tab disableIndicator sx={{textAlign: 'center'}}>
                            Repeat Medication
                        </Tab>
                        <Tab disableIndicator sx={{textAlign: 'center'}}>
                            Other Medication
                        </Tab>
                    </TabList>
                </Box>
                <TabPanel value={0}>
                    <RepeatMedicationDisplay
                        sx={{mr: 2}}
                        medicationData={medicationData}
                        disableSelection={disableSelection}
                        selectedCardsState={{selectedCards, setSelectedCards}}
                    />
                </TabPanel>
                <TabPanel value={1}>
                    <PastMedicationDisplay
                        sx={{mr: 2}}
                        medicationData={pastMedicationData}
                    />
                </TabPanel>
            </Tabs>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    bottom: 'max(24px, env(safe-area-inset-bottom))',
                    right: 24,
                    zIndex: 999,
                    py: 1,
                    borderRadius: 10,
                    boxShadow: 4,
                    '@media (min-width:1500px)': {
                        right: 'auto',
                        left: 'calc(50% + 550px + 24px)',
                    },
                }}
            >
                <IconButton
                    disabled={selectedCards.length === 0}
                    onClick={handleOrderMedications}
                    aria-label="Order Medications"
                    sx={{
                        boxShadow:
                            // eslint-disable-next-line max-len
                            `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12), 0px 0px 32px 4px ${theme.vars.palette.background.body}`,
                        borderRadius: 20,
                        border: `1px solid ${theme.vars.palette.primary[200]}`,
                        backgroundColor: theme.vars.palette.background.body,
                        opacity: selectedCards.length > 0 ? 1 : 0,
                        transition: 'opacity 0.2s ease-in',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                    }}
                >
                    <CheckCircleOutlineRoundedIcon fontSize="large" color="primary" />
                    Order {selectedCards.length}/
                    {medicationData.data?.me?.repeatMedication?.length || 0}
                </IconButton>
            </Box>
            <OrderMedicationModal
                medicationData={medicationData}
                selectedMedications={selectedMedications}
                isModalOpen={showOrderMedicationModal}
                handleCloseModal={handleCloseOrderMedicationModal}
                handleCompleteMedicationOrder={handleCompleteMedicationOrder}
            />
        </>
    );
};

export default MyMedications;
