import {
    Box,
    Chip,
    Grid,
    Tab,
    TabList,
    TabPanel,
    Tabs,
    Typography,
    useTheme,
} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
import ActionCard from './ActionCard';
import LinkCard from './LinkCard';
import MetricCard from './MetricCard';
import MetricInfoPanel from './MetricInfoPanel';
import TextCard from './TextCard';
import VideoCard from './VideoCard';

const ConditionSection = ({hidden = false, condition}) => {
    const theme = useTheme();

    const [metricInfoPanelContents, setMetricInfoPanelContents] = React.useState(null);

    const visibleSections = condition.sections.filter(
        (section) => section.content && section.content.length > 0,
    );

    const returnContentCard = ({content, setMetricInfoPanelContents}) => {
        switch (content.__typename) {
            case 'LinkBlock':
                return <LinkCard {...content} />;
            case 'VideoBlock':
                return <VideoCard {...content} />;
            case 'TextBlock':
                return <TextCard {...content} />;
            case 'ResultBlock': {
                const valuesWithoutNull = content.values.filter(
                    (value) => value !== null,
                );
                return (
                    <MetricCard
                        title={content.title}
                        description={content.shortDescription}
                        contextText={content.description}
                        units={content.unit}
                        delimiter={content.delimiter}
                        values={valuesWithoutNull}
                        supportingInformation={content.supportingInformation}
                        setInfoPanelContents={setMetricInfoPanelContents}
                    />
                );
            }
            case 'ActionRequiredBlock':
            case 'ActionDoneBlock':
                return <ActionCard {...content} />;
            default:
                return <TextCard title={content.title} />;
        }
    };

    return (
        <>
            <MetricInfoPanel
                open={metricInfoPanelContents !== null}
                setInfoPanelContents={setMetricInfoPanelContents}
                title={metricInfoPanelContents?.title}
                infoPanelContents={metricInfoPanelContents?.content}
            />
            <Box
                sx={{
                    display: hidden ? 'none' : 'block',
                    width: '100%',
                }}
            >
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography level="title-lg">{condition.title}</Typography>
                        {condition.summary && (
                            <Typography level="body-sm" component="div">
                                <StyledMarkdown>{condition.summary}</StyledMarkdown>
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Tabs aria-label="Condition Information Section Tabs" sx={{my: 1}}>
                    <TabList
                        tabFlex="auto"
                        sx={{
                            pb: '2px',
                            borderRadius: 0,
                            overflow: 'hidden',
                            flexWrap: 'wrap',
                        }}
                    >
                        {visibleSections.map((section, index) => {
                            const actionRequiredCount = section.content.reduce(
                                (count, item) => {
                                    return item.__typename === 'ActionRequiredBlock'
                                        ? count + 1
                                        : count;
                                },
                                0,
                            );
                            return (
                                <Tab
                                    key={section.title}
                                    color="primary"
                                    sx={{
                                        flex: 'none',
                                        scrollSnapAlign: 'start',
                                        '&.Mui-selected': {
                                            // eslint-disable-next-line max-len
                                            border: `1px solid rgba(${theme.vars.palette.primary[500]}, 0.9)`,
                                            backgroundColor: 'inherit',
                                            color: theme.vars.palette.primary[500],
                                            '&::after': {
                                                opacity: 1,
                                                transition: 'opacity 0.5s ease-in-out',
                                            },
                                        },
                                        '&:active': {
                                            bgcolor: 'inherit',
                                        },
                                        '&::after': {
                                            opacity: 0,
                                            transition: 'opacity 0.5s ease-in-out',
                                        },
                                        '&:not(.Mui-selected):hover': {
                                            bgcolor: 'inherit',
                                        },
                                    }}
                                >
                                    <Typography
                                        level="title-sm"
                                        color="inherit"
                                        component="div"
                                    >
                                        <PlainMarkdown>{section.title}</PlainMarkdown>
                                    </Typography>
                                    {actionRequiredCount > 0 && (
                                        <Chip color="warning" variant="soft">
                                            {actionRequiredCount}
                                        </Chip>
                                    )}
                                </Tab>
                            );
                        })}
                    </TabList>
                    {/* eslint-disable max-len */}
                    {visibleSections.map((section, index) => {
                        return (
                            <TabPanel
                                keepMounted
                                value={index}
                                key={section.title}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    px: 0,
                                    maxWidth: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 2,
                                    }}
                                >
                                    {section.description && (
                                        <Typography level="body-sm" component="div">
                                            <StyledMarkdown>
                                                {section.description}
                                            </StyledMarkdown>
                                        </Typography>
                                    )}
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        sx={{m: 0}}
                                    >
                                        {section.content.map((content, index) => {
                                            // Ensure that the ResultBlock has values to
                                            // display
                                            if (
                                                content.__typename === 'ResultBlock' &&
                                                content?.values &&
                                                content?.values.filter(
                                                    (value) => value !== null,
                                                ).length < 1
                                            ) {
                                                return null;
                                            }

                                            return (
                                                <Grid
                                                    key={content.title}
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    {returnContentCard({
                                                        content,
                                                        setMetricInfoPanelContents,
                                                    })}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Box>
                            </TabPanel>
                        );
                    })}
                </Tabs>
            </Box>
        </>
    );
};

ConditionSection.propTypes = {
    hidden: propTypes.bool,
    condition: propTypes.shape({
        title: propTypes.string.isRequired,
        summary: propTypes.string,
        sections: propTypes.arrayOf(
            propTypes.shape({
                title: propTypes.string.isRequired,
                description: propTypes.string,
                content: propTypes.arrayOf(
                    propTypes.shape({
                        __typename: propTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export default ConditionSection;
