import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {Box, Card, Link, Typography} from '@mui/joy';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';

const LinkCard = ({url, title, description}) => {
    return (
        <Card variant="outlined" sx={{width: '100%', maxWidth: '400px'}}>
            <Box
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <Link
                    variant="soft"
                    href={url}
                    target="_blank"
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                        borderRadius: 'sm',
                    }}
                >
                    <LinkRoundedIcon />
                    <Typography level="title-sm" color="primary" component="div">
                        <PlainMarkdown>{title}</PlainMarkdown>
                    </Typography>
                </Link>
                <Link href={url} target="_blank">
                    <OpenInNewRoundedIcon fontSize="small" />
                </Link>
            </Box>
            <Typography level="body-sm" component="div">
                <StyledMarkdown>{description}</StyledMarkdown>
            </Typography>
        </Card>
    );
};
LinkCard.propTypes = {
    url: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    description: propTypes.string,
};

export default LinkCard;
