import {Box} from '@mui/joy';
import {signOut} from 'aws-amplify/auth';
import LoadingMedicalRecordModal from 'components/dialogs/LoadingMedicalRecordModal';
import {useFetchMyDataSettings} from 'graphql/myDataSettings';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Container} from 'utilities/pageUtils';
import {CssProvidersWrapper} from 'utilities/themeUtilities';
import AppHeader from './components/appbars/AppHeader';
import InactivityDialog from './components/dialogs/InactivityDialog';
import {ProgressProvider} from './contexts/ProgressProvider';
import LoginLoadingPage from './routes/Login/LoginLoadingPage';
import PocketGpUnavailablePage from './routes/Login/PocketGpUnavailablePage';
import {forceTokenRefresh} from './utilities/loginUtils';

const PocketGpApp = ({apolloClient, token, setToken, setLoginAction}) => {
    const [lastActivity, setLastActivity] = useState(Date.now());
    const [canUsePocketGp, setCanUsePocketGp] = useState(null);
    const [canUsePocketGpTimeout, setCanUsePocketGpTimeout] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(60); // [seconds
    const [showDialog, setShowDialog] = useState(false);

    const INACTIVITY_WARNING_TIME = 29 * 60 * 1000; // 29 minutes
    const INACTIVITY_DURATION_LIMIT = 30 * 60 * 1000; // 30 minutes

    const {
        error: dataSettingsError,
        data: dataSettings,
        startPolling: startPollingDataSettings,
        stopPolling: stopPollingDataSettings,
        networkStatus: dataSettingsNetworkStatus,
    } = useFetchMyDataSettings();

    // Utility Functions
    const calculateTimeUntilTimeout = () => {
        const now = Date.now();
        const logoutTime = new Date(lastActivity + INACTIVITY_DURATION_LIMIT);
        const timeUntilTimeout = logoutTime - now;
        return timeUntilTimeout;
    };

    const calculateTimeUntilTimeoutInSeconds = () => {
        const timeUntilTimeout = calculateTimeUntilTimeout();
        return Math.floor(timeUntilTimeout / 1000);
    };

    const handleShowDialog = () => {
        setTimeRemaining(calculateTimeUntilTimeoutInSeconds());
        setShowDialog(true);
    };

    const handleHideDialog = () => {
        setShowDialog(false);
        setLastActivity(Date.now());
    };

    const handleLogout = async () => {
        setLoginAction('logout');
        await signOut();
        handleHideDialog();
    };

    /**
     * Registers a global click listener to update the last activity timestamp.
     * The listener is removed when the component is unmounted. Additionally, any click
     * will dismiss the inactivity dialog, if it's shown.
     */
    useEffect(() => {
        const updateLastActivity = () => {
            setLastActivity(Date.now());
            handleHideDialog(); // Dismiss the dialog if there's a click
        };

        // Set up event listeners
        window.addEventListener('click', updateLastActivity);

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('click', updateLastActivity);
        };
    }, []);

    /**
     * Monitors user inactivity and takes appropriate actions.
     *
     * 1. If the user has been inactive for the duration specified in
     *    `INACTIVITY_WARNING_TIME` but hasn't reached `INACTIVITY_DURATION_LIMIT` and
     *    is currently logged in, it shows a warning dialog.
     * 2. If the user has been inactive for the duration specified in
     *    `INACTIVITY_DURATION_LIMIT`, it logs the user out.
     *
     * This effect runs at a fixed interval (once every minute).
     *
     * The interval is cleaned up when the component is unmounted to prevent memory
     * leaks.
     */
    useEffect(() => {
        const checkActivity = async () => {
            const now = Date.now();
            const timeSinceLastActivity = now - lastActivity;

            if (
                timeSinceLastActivity >= INACTIVITY_WARNING_TIME &&
                timeSinceLastActivity < INACTIVITY_DURATION_LIMIT &&
                token !== null
            ) {
                handleShowDialog();
            } else if (timeSinceLastActivity >= INACTIVITY_DURATION_LIMIT) {
                await handleLogout();
            }
        };

        const interval = setInterval(() => {
            checkActivity().catch((error) => console.error(error));
        }, 5000 * 1); // Check every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, [lastActivity]);

    /**
     * If we have dataSettings, check whether they are populated.
     * If populated, stop polling and set the canUsePocketGp state.
     */
    useEffect(() => {
        if (dataSettingsError) {
            console.error(dataSettingsError);
        }
        if ('canUsePocketGp' in (dataSettings?.me?.patientDataSettings || {})) {
            stopPollingDataSettings();
            const canUseApp = dataSettings.me.patientDataSettings.canUsePocketGp;

            if (
                canUseApp === true &&
                !token.payload['cognito:groups'].includes('PocketGpUsers')
            ) {
                // If the user can use PocketGp, but doesn't have the group in their
                // token, the API calls will be denied, so we need to refresh the token.
                forceTokenRefresh().then((idToken) => {
                    setToken(idToken);
                    if (idToken.payload['cognito:groups'].includes('PocketGpUsers')) {
                        setCanUsePocketGp(canUseApp);
                    }
                });
            } else {
                setCanUsePocketGp(canUseApp);
            }
        }
    }, [dataSettingsError, dataSettings, dataSettingsNetworkStatus]);

    // If we don't have dataSettings, start polling for them.
    useEffect(() => {
        if (canUsePocketGp === null) {
            startPollingDataSettings(5000);

            // Stop polling after 30 seconds
            const timeoutId = setTimeout(() => {
                stopPollingDataSettings();

                if (canUsePocketGp === null) {
                    setCanUsePocketGpTimeout(true);
                }
            }, 30000);

            // Clear the timeout and stop polling when the component unmounts
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [startPollingDataSettings, stopPollingDataSettings]);

    if (token) {
        if (!apolloClient) {
            document.getElementById('themeColor').removeAttribute('content');
            return (
                <CssProvidersWrapper>
                    <LoginLoadingPage action={'login'} />
                </CssProvidersWrapper>
            );
        }
        if (apolloClient && canUsePocketGp) {
            document.getElementById('themeColor').setAttribute('content', '#241C70');
            return (
                <ProgressProvider>
                    <LoadingMedicalRecordModal />
                    <InactivityDialog
                        showDialog={showDialog}
                        handleHideDialog={handleHideDialog}
                        timeRemaining={timeRemaining}
                        handleLogout={handleLogout}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '100vh',
                            width: '100%',
                            maxWidth: '100vw',
                        }}
                    >
                        <AppHeader token={token} setLoginAction={setLoginAction} />
                        <main
                            style={{
                                flex: 1,
                                display: 'flex',
                                maxWidth: '100vw',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    width: '100%',
                                }}
                            >
                                <Container>
                                    <Box
                                        className="scrollable"
                                        sx={{width: '100%', height: '100%', pb: 10}}
                                    >
                                        <Outlet token={token} />
                                    </Box>
                                </Container>
                            </Box>
                        </main>
                    </Box>
                </ProgressProvider>
            );
        }
        if (canUsePocketGpTimeout || canUsePocketGp === false) {
            document.getElementById('themeColor').removeAttribute('content');
            return (
                <CssProvidersWrapper>
                    <PocketGpUnavailablePage
                        patientDataSettings={dataSettings?.me?.patientDataSettings}
                    />
                </CssProvidersWrapper>
            );
        }
        if (apolloClient && !canUsePocketGp && !canUsePocketGpTimeout) {
            document.getElementById('themeColor').removeAttribute('content');
            return (
                <CssProvidersWrapper>
                    <LoginLoadingPage action={'login'} />
                </CssProvidersWrapper>
            );
        }
    }
};

PocketGpApp.propTypes = {
    apolloClient: PropTypes.object.isRequired,
    token: PropTypes.object.isRequired,
    setToken: PropTypes.func.isRequired,
    setLoginAction: PropTypes.func.isRequired,
};

export default PocketGpApp;
