import {gql, useQuery} from '@apollo/client';
import {useProgress} from 'contexts/ProgressProvider';
import {useEffect, useState} from 'react';
import {logError} from 'utilities/apolloUtils';

export const FETCH_MY_REPEAT_MEDICATION = gql`
    query GetMyRepeatMedication {
        me {
            repeatMedication {
                medicineId
                lastIssued
                dose
                quantity
                canBeRequested
                unavailabilityReason
                issueNumber
                medicineName
                medicationInformation {
                    informationPageName
                    informationDisplayContent {
                        summary
                        sharedCareDetails {
                            title
                            body
                            url
                            listItems
                        }
                        description
                        textContent {
                            title
                            body
                            url
                            listItems
                        }
                        links {
                            title
                            description
                            url
                        }
                        videos {
                            title
                            description
                            videos {
                                title
                                description
                                youtubeUrl
                            }
                        }
                        safetyChecks {
                            title
                            description
                            checks {
                                title
                                description
                            }
                        }
                    }
                }
            }
            preferredPharmacy {
                odsCode
                organisationName
                organisationType
                url
                address1
                address2
                address3
                city
                county
                latitude
                longitude
                organisationSubType
                postcode
            }
        }
    }
`;

export const useFetchMyRepeatMedication = () => {
    const [patientRegistered, setPatientRegistered] = useState(false);
    const {availableData} = useProgress();

    const {loading, error, data, refetch} = useQuery(FETCH_MY_REPEAT_MEDICATION, {
        skip: !patientRegistered,
    });

    useEffect(() => {
        const statusesWherePatientIsRegistered = ['PatientId', 'MedicalRecord'];
        const containsAny = statusesWherePatientIsRegistered.some((value) =>
            availableData.includes(value),
        );

        if (!patientRegistered && containsAny) {
            setPatientRegistered(true);
        }
    }, [availableData]);

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        loading,
        waitingForData: !patientRegistered,
        error,
        data,
        refetch,
    };
};
