import {Button, DialogContent, DialogTitle, Modal, ModalDialog, Stack} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

/**
 * `InactivityDialog` Component.
 *
 * Presents a dialog to the user after a period of inactivity.
 * If the user does not respond in time, the component initiates a logout.
 * The component starts a countdown timer that notifies the user of
 * the remaining time before being logged out due to inactivity.
 *
 * @component
 * @param {boolean} showDialog - Determines if the dialog should be displayed.
 * @param {function} handleHideDialog - Handler to hide/close the dialog.
 * @param {number} timeRemaining - Initial time (in seconds) before the user gets logged
 *                                 out.
 * @param {function} handleLogout - Handler to log out the user.
 * @return {JSX.Element}
 */
const InactivityDialog = ({
    showDialog,
    handleHideDialog,
    timeRemaining,
    handleLogout,
}) => {
    const [secondsUntilTimeout, setSecondsUntilTimeout] = useState(timeRemaining);

    const handleLocalLogout = () => {
        handleLogout();
    };

    /**
     * Handles the countdown timer for the inactivity dialog.
     *
     * When the dialog is shown (`showDialog` is `true`):
     * 1. Starts a countdown timer that decrements the `secondsUntilTimeout` state every
     *    second.
     * 2. Stops the countdown when `secondsUntilTimeout` reaches zero.
     *
     * If the dialog is not shown or is dismissed, the countdown timer is cleared.
     *
     * A cleanup function ensures that the timer is cleared when:
     * 1. The dialog is dismissed.
     * 2. The component is unmounted, preventing potential memory leaks.
     */
    useEffect(() => {
        let timer;

        if (showDialog) {
            timer = setInterval(() => {
                setSecondsUntilTimeout((prevSeconds) => {
                    if (prevSeconds > 0) {
                        return prevSeconds - 1;
                    }
                    clearInterval(timer); // Clear when it reaches 0
                    return 0;
                });
            }, 1000);
        }

        // Cleanup function: Clear the interval when the component is unmounted or
        // when dialog is closed
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [showDialog]); // This effect depends on `showDialog`

    return (
        <Modal
            open={showDialog}
            onClose={handleHideDialog}
            aria-labelledby="inactivity-timeout-dialog-title"
            aria-describedby="inactivity-timeout-dialog-description"
        >
            <ModalDialog>
                <DialogTitle id="inactivity-timeout-dialog-title">
                    Are you still there?
                </DialogTitle>
                <DialogContent id="inactivity-timeout-dialog-description">
                    You will be logged out in {secondsUntilTimeout} seconds due to
                    activity.
                </DialogContent>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                    <Button onClick={handleHideDialog} variant="soft">
                        Stay Logged In ({secondsUntilTimeout})
                    </Button>
                    <Button
                        onClick={() => {
                            handleLocalLogout();
                        }}
                    >
                        Logout
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
};

InactivityDialog.propTypes = {
    showDialog: PropTypes.bool.isRequired,
    handleHideDialog: PropTypes.func.isRequired,
    timeRemaining: PropTypes.number.isRequired,
    handleLogout: PropTypes.func.isRequired,
};

export default InactivityDialog;
