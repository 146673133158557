import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * FormHeader component displays a header section with a title and supporting text.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title text.
 * @param {string} props.supportingText - Supporting text displayed below the title.
 * @return {React.Element}
 */
const FormHeader = ({title, supportingText, supportingContent}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography level="title-lg">{title}</Typography>
                {supportingText && (
                    <Typography level="body-md">{supportingText}</Typography>
                )}
            </Box>
            {supportingContent && supportingContent}
        </Box>
    );
};

FormHeader.propTypes = {
    title: PropTypes.string.isRequired,
    supportingText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    supportingContent: PropTypes.element,
};

export default FormHeader;
