import {gql, useMutation} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const CONFIRM_MEDICATION_ORDER = gql`
    mutation ConfirmMedicationOrder(
        $sessionParameters: SessionParametersInput!
        $pharmacy: PharmacyInput!
    ) {
        confirmMedicationOrder(
            sessionParameters: $sessionParameters
            pharmacy: $pharmacy
        ) {
            responseCode
            responseMessage
        }
    }
`;

export const useConfirmMedicationOrder = () => {
    const [confirmOrder, {loading, error, data}] = useMutation(
        CONFIRM_MEDICATION_ORDER,
    );

    const confirmMedicationOrder = async (sessionParameters, pharmacy) => {
        try {
            const {data} = await confirmOrder({
                variables: {sessionParameters, pharmacy},
            });
            return data.confirmMedicationOrder;
        } catch (e) {
            console.error('Error confirming medication order:', e);
        }
    };

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        confirmMedicationOrder,
        loading,
        error,
        data,
    };
};
