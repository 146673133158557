import {Box, Grid} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line max-len
import PastMedicationCard from 'routes/Medication/components/PastMedicationCard.js';
import {formatDate} from 'utilities/dateUtils';

const PastMedicationsYearBlock = ({medications, handleShowInfo, handleShowHistory}) => {
    return (
        <>
            {medications.map((medication) => {
                let informationPages = [];
                let hasSharedCareDetails = false;

                // Get a list of the medication pages for the drug.
                // Check if any of the pages have shared care details, if so
                // mark the drug as shared care.
                if (medication?.medicationInformation?.length > 0) {
                    informationPages = medication.medicationInformation.map(
                        (item) => item.informationPageName,
                    );

                    hasSharedCareDetails = medication?.medicationInformation?.some(
                        (item) =>
                            item?.informationDisplayContent?.sharedCareDetails &&
                            item?.informationDisplayContent?.sharedCareDetails.length >
                                0,
                    );
                }

                return (
                    <Grid xs={12} sm={12} md={6} lg={6} key={medication.medicineName}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            {/* eslint-disable max-len */}
                            <PastMedicationCard
                                medicineName={medication.medicineName}
                                medicineId={medication.medicineName}
                                dose={medication.prescriptions[0].dose}
                                quantity={`${medication.prescriptions[0].quantity} ${medication.prescriptions[0].quantityUnit}`}
                                lastIssued={formatDate(medication.latestPrescription)}
                                numberOfPrescriptions={medication.prescriptions?.length}
                                informationPages={informationPages}
                                isSharedCareDrug={hasSharedCareDetails}
                                handleShowInfo={handleShowInfo}
                                handleShowHistory={handleShowHistory}
                            />
                            {/* eslint-enable max-len */}
                        </Box>
                    </Grid>
                );
            })}
        </>
    );
};
export default PastMedicationsYearBlock;

PastMedicationsYearBlock.propTypes = {
    medications: PropTypes.array.isRequired,
    handleShowInfo: PropTypes.func.isRequired,
    handleShowHistory: PropTypes.func.isRequired,
};
