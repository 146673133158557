import {
    Box,
    Card,
    CardContent,
    Link,
    List,
    ListItem,
    Modal,
    ModalClose,
    Sheet,
    Typography,
} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';

const SurgeriesModal = (props) => {
    const open = props.open;
    const onClose = props.handleCloseSurgeriesModal;

    const colourMode = getColourMode();

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Sheet
                    sx={{
                        width: '90%',
                        maxWidth: '450px',
                        minWidth: '230px',
                        maxHeight: '900px',
                        position: 'absolute',
                        borderRadius: 'md',
                        overflow: 'auto',
                        top: '5%',
                        m: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Card>
                        <CardContent>
                            <ModalClose variant="plain" sx={{m: 1}} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        width: '80%',
                                        maxWidth: {xs: 350, md: 350},
                                        my: 3,
                                        mx: 2,
                                    }}
                                    alt="PocketGP Logo"
                                    /* eslint-disable max-len */
                                    src={
                                        colourMode === 'light'
                                            ? 'images/pocketgp_logo_transparent_background.png'
                                            : 'images/white_logo_transparent_background_cropped.png'
                                    }
                                    srcSet={
                                        colourMode === 'light'
                                            ? 'images/pocketgp_logo_transparent_background.png 1x, images/pocketgp_logo_transparent_background@2x.png 2x'
                                            : 'images/white_logo_transparent_background_cropped.png 1x, images/white_logo_transparent_background_cropped@2x.png 2x'
                                    }
                                    /* eslint-enable max-len */
                                />
                                <Typography
                                    sx={{
                                        mx: 2,
                                    }}
                                    id="modal-description"
                                >
                                    PocketGP is available at:
                                    <List>
                                        <ListItem>
                                            <strong>
                                                - Darwin Medical Practice, Staffordshire
                                            </strong>
                                        </ListItem>
                                    </List>
                                    If you are not registered at one of these practices,
                                    then PocketGP will not function for you. Please do
                                    not continue.
                                    <br />
                                    <br />
                                    If your Practice is not listed, and you would like
                                    to use PocketGP practice, please email us at{' '}
                                    <Link
                                        href="mailto:contact@synapse256.co.uk"
                                        underline="hover"
                                        variant="plain"
                                    >
                                        contact@synapse256.co.uk
                                    </Link>
                                    . We&apos;ll make contact with your Practice about
                                    providing PocketGP to them.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Sheet>
            </Box>
        </Modal>
    );
};

export default SurgeriesModal;

SurgeriesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCloseSurgeriesModal: PropTypes.func.isRequired,
};
