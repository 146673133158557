export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getTimeDifference = (oldestDate, latestDate = new Date()) => {
    const secondsPerMinute = 60;
    const secondsPerHour = 3600;
    const secondsPerDay = 86400;
    const secondsPerMonth = 2592000; // Approximation, as months vary in length
    const secondsPerYear = 31536000;

    const oldDate = new Date(oldestDate);
    const newDate = new Date(latestDate);

    // Milliseconds to seconds
    const elapsedSeconds = Math.floor((newDate - oldDate) / 1000);

    const formatString = (value, unit) => {
        return `${value} ${unit}${value > 1 ? 's' : ''}`;
    };

    if (elapsedSeconds < secondsPerMinute) {
        return '0 minutes';
    } else if (elapsedSeconds < secondsPerHour) {
        return formatString(Math.floor(elapsedSeconds / secondsPerMinute), 'minute');
    } else if (elapsedSeconds < secondsPerDay) {
        return formatString(Math.floor(elapsedSeconds / secondsPerHour), 'hour');
    } else if (elapsedSeconds < secondsPerMonth) {
        return formatString(Math.floor(elapsedSeconds / secondsPerDay), 'day');
    } else if (elapsedSeconds < secondsPerYear) {
        return formatString(Math.floor(elapsedSeconds / secondsPerMonth), 'month');
    } else {
        return formatString(Math.floor(elapsedSeconds / secondsPerYear), 'year');
    }
};
