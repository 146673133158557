import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {
    Link,
    List,
    ListItem,
    ListItemContent,
    ListItemDecorator,
    Typography,
} from '@mui/joy';

import {styled} from '@mui/system';
import propTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';

const DecoratedListItem = ({children}) => (
    <ListItem sx={{alignItems: 'flex-start'}}>
        <ListItemDecorator>
            <ChevronRightRoundedIcon />
        </ListItemDecorator>
        <ListItemContent>
            <Typography
                level="body-md"
                sx={{ml: 2, display: 'flex', alignSelf: 'flex-start'}}
                component="div"
            >
                <PlainMarkdown>{children}</PlainMarkdown>
            </Typography>
        </ListItemContent>
    </ListItem>
);
DecoratedListItem.propTypes = {
    children: propTypes.node.isRequired,
};

const DecoratedList = ({children}) => (
    <List sx={{mt: 0}} size="sm">
        {children}
    </List>
);
DecoratedList.propTypes = {
    children: propTypes.node.isRequired,
};

export const PaddedParagraph = styled('p')(({theme}) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
        marginTop: 0,
        marginBottom: 0,
    },
    '&:last-of-type': {
        marginTop: 0,
        marginBottom: 0,
    },
    wordBreak: 'break-word',
}));

export const TightParagraph = styled('p')(({theme}) => ({
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    wordBreak: 'break-word',
}));

const MuiLink = ({node, ...props}) => (
    <Link
        variant="soft"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
            p: 0,
            m: 0,
            borderRadius: 'xs',
            display: 'inline-block',
        }}
        {...props}
    />
);
MuiLink.propTypes = {
    node: propTypes.object,
};

export const PlainMarkdown = ({children}) => {
    return (
        <Markdown
            components={{
                p: TightParagraph,
                a: MuiLink,
                ul: DecoratedList,
                li: DecoratedListItem,
            }}
        >
            {children}
        </Markdown>
    );
};
PlainMarkdown.propTypes = {
    children: propTypes.node.isRequired,
};

export const StyledMarkdown = ({children}) => {
    return (
        <Markdown
            components={{
                p: PaddedParagraph,
                a: MuiLink,
                ul: DecoratedList,
                li: DecoratedListItem,
            }}
        >
            {children}
        </Markdown>
    );
};
StyledMarkdown.propTypes = {
    children: propTypes.node,
};
