import PropTypes from 'prop-types';

export const sentimentToColour = (sentiment, theme) => {
    switch (sentiment) {
        case 'VERY_GOOD':
            return '#00ECB1';
        case 'GOOD':
            return '#12B76A';
        case 'AVERAGE':
            return '#FDB022';
        case 'BAD':
            return '#F46D43';
        case 'VERY_BAD':
            return '#F04438';
        default:
            return '#241C70';
    }
};
sentimentToColour.PropTypes = {
    sentiment: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired,
};

export const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

export const normaliseDataToPercentage = (dataArray) => {
    return dataArray.map((item) => {
        const range = item.maxValue - item.minValue;
        let normalisedValue = 0;
        if (range !== 0) {
            normalisedValue = ((item.data - item.minValue) / range) * 100;
        }
        return {
            ...item,
            data: normalisedValue,
        };
    });
};

export const determineValueSentiment = (value, rangeDescriptions) => {
    const description = rangeDescriptions.find(
        (description) =>
            (description.minimum === null ||
                Number(value) >= Number(description.minimum)) &&
            (description.maximum === null ||
                Number(value) < Number(description.maximum)),
    );

    return description ? description.sentiment : 'AVERAGE';
};
determineValueSentiment.PropTypes = {
    value: PropTypes.string.isRequired,
    rangeDescriptions: PropTypes.arrayOf(
        PropTypes.shape({
            minimum: PropTypes.string.isRequired,
            maximum: PropTypes.string.isRequired,
            sentiment: PropTypes.string.isRequired,
        }),
    ),
};
