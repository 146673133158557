import CottageIcon from '@mui/icons-material/Cottage';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MedicationIcon from '@mui/icons-material/Medication';
import {Box, Grid} from '@mui/joy';
import PageHeader from 'components/headers/PageHeader';
import UserContext from 'contexts/UserContext';
import React, {useContext} from 'react';
import HomeCard from './components/HomeCard';

const Home = () => {
    const user = useContext(UserContext);

    const title = 'My Home';
    const subtitle = `
        Welcome to PocketGP ${user.given_name}!
        What would you like to do today?
        `;

    return (
        <>
            <PageHeader
                title={title}
                subtitle={subtitle}
                breadcrumbs={null}
                iconAlt="Home"
                icon={<CottageIcon fontSize="medium" />}
            />
            <Box sx={{mt: 2, width: '100%', pb: '1px'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="space-evenly"
                    spacing={3}
                    sx={{py: 2, mx: 'auto'}}
                >
                    <Grid>
                        <HomeCard
                            Icon={MedicationIcon}
                            title="Medications"
                            // eslint-disable-next-line max-len
                            supportingText="View your repeat medications, important information about them, and order new ones."
                            destination="/medications"
                        />
                    </Grid>
                    <Grid>
                        <HomeCard
                            Icon={FavoriteIcon}
                            title="Health Conditions"
                            // eslint-disable-next-line max-len
                            supportingText="View the health conditions you have been diagnosed with, and find out more about them."
                            destination="/health"
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Home;
