import {gql, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import {logError} from 'utilities/apolloUtils';

export const FETCH_MY_DATA_SETTINGS = gql`
    query GetMyDataSettings {
        me {
            patientDataSettings {
                canUsePocketGp
                hasGpCredentials
                gpSurgeryHasPocketGpSubscription
            }
        }
    }
`;

export const useFetchMyDataSettings = () => {
    const {loading, error, data, startPolling, stopPolling, networkStatus} = useQuery(
        FETCH_MY_DATA_SETTINGS,
        {
            fetchPolicy: 'network-only', // Always fetch from the server
            notifyOnNetworkStatusChange: true,
        },
    );

    useEffect(() => {
        if (error) {
            logError(error);
        }
    }, [error]);

    return {
        loading,
        error,
        data,
        startPolling,
        stopPolling,
        networkStatus,
    };
};
