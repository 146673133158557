import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Alert, Box, Button, Link, Typography} from '@mui/joy';
import 'App.css';
import PocketGpLogoHeader from 'logos/PocketGpLogoHeader';
import PropTypes from 'prop-types';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {handleAmplifyError, handleErrorCallback} from 'utilities/loginUtils';
import BackgroundSheet from './components/BackgroundSheet';
import LoginCard from './components/LoginCard';

import NHSLoginComponent from 'components/buttons/NhsLoginComponent';

/**
 * A page component that displays the login card for user authentication.
 *
 * @component
 * @param {Object} props - The props object (currently unused).
 * @return {JSX.Element}
 */
const LoginPage = (props) => {
    const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

    const setSurgeriesModalOpen = props.setSurgeriesModalOpen;

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    const errorInfo =
        handleErrorCallback(queryParameters) ?? handleAmplifyError(props.error);

    const handleCheckSurgeryClick = () => {
        setSurgeriesModalOpen(true);
    };

    return (
        <BackgroundSheet>
            <LoginCard>
                <PocketGpLogoHeader />
                {errorInfo && (
                    <Alert
                        color="danger"
                        startDecorator={<ErrorOutlineRoundedIcon />}
                        sx={{alignItems: 'flex-start', my: 2}}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Typography level="title-lg" gutterBottom sx={{pr: 1}}>
                                {errorInfo.title}
                            </Typography>
                            <Typography
                                sx={{
                                    whiteSpace: 'pre-line',
                                    '& p': {my: 1},
                                    pr: 1,
                                    textAlign: 'justify',
                                }}
                            >
                                {errorInfo.description}
                            </Typography>
                        </Box>
                    </Alert>
                )}
                {!maintenanceMode && (
                    <>
                        <Typography level="h3" align="right">
                            Log in to your account
                        </Typography>
                        <Typography
                            sx={{
                                mt: 1,
                                mb: 2,
                                mx: 2,
                            }}
                        >
                            Welcome! Please login to your NHS login account to continue.
                            If you don&apos;t have one, you can set one up.
                        </Typography>
                    </>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mx: 2,
                    }}
                >
                    {!maintenanceMode && (
                        <Alert
                            sx={{
                                alignItems: 'flex-start',
                            }}
                            startDecorator={<InfoOutlinedIcon />}
                            variant="soft"
                            color="primary"
                        >
                            <div>
                                <Typography level="title-md" color="inherit">
                                    Is PocketGP Available for You?
                                </Typography>
                                <Typography level="body-sm">
                                    PocketGP is exclusively available to certain GP
                                    surgeries. If you haven&apos;t been directed by your
                                    surgery to use PocketGP, please click the button
                                    below to verify if PocketGP is enabled for your
                                    practice.
                                </Typography>
                                <Box
                                    sx={{
                                        mt: 1,
                                        mr: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCheckSurgeryClick()}
                                    >
                                        Check Surgery
                                    </Button>
                                </Box>
                            </div>
                        </Alert>
                    )}
                    {maintenanceMode && (
                        <Alert
                            color="danger"
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography level="title-md" textAlign="center">
                                Sorry! We&apos;re currently offline for maintenance
                            </Typography>
                            <Typography level="body-sm" textAlign="center">
                                Please contact us at
                                <Link
                                    variant="plain"
                                    href="mailto:support@pocketgp.app"
                                >
                                    support@pocketgp.app
                                </Link>
                                if you have any questions.
                            </Typography>
                        </Alert>
                    )}
                    <NHSLoginComponent maintenanceMode={maintenanceMode} />
                </Box>
            </LoginCard>
        </BackgroundSheet>
    );
};

LoginPage.propTypes = {
    error: PropTypes.any,
    setSurgeriesModalOpen: PropTypes.func.isRequired,
};

export default LoginPage;
