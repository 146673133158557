import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import {Box, Button, Card, CardActions, CardContent, Link, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {toTitleCase} from 'utilities/stringFunctions';

const generateAddressString = (data) => {
    const addressParts = [
        data.address1,
        data.address2,
        data.address3,
        data.city,
        data.county,
    ];
    const nonEmptyAddressParts = addressParts.filter(
        (part) =>
            part !== null &&
            part !== undefined &&
            part !== '' &&
            part !== ' ' &&
            part.length > 0,
    );
    const titleCasedParts = nonEmptyAddressParts.map((part, index) => {
        return toTitleCase(part + (index < nonEmptyAddressParts.length - 1 ? ',' : ''));
    });
    return titleCasedParts;
};

const prependHttps = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
};

const WebsiteIconDisplay = ({websiteUrl}) => {
    const [loadError, setLoadError] = React.useState(false);
    const faviconUrl = `https://www.google.com/s2/favicons?domain=${websiteUrl}&sz=64`;

    const handleError = () => {
        setLoadError(true);
    };

    return (
        <Link
            href={prependHttps(websiteUrl)}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                width: 32,
                height: 32,
            }}
        >
            <>
                {loadError ? (
                    <LaunchRoundedIcon />
                ) : (
                    <img src={faviconUrl} onError={handleError} />
                )}
            </>
        </Link>
    );
};

WebsiteIconDisplay.propTypes = {
    websiteUrl: PropTypes.string.isRequired,
};

const PharmacyCard = ({pharmacy, infoOnlyDisplay = false, onPharmacySelect = null}) => {
    return (
        <Card
            sx={{
                width: '100%',
                minWidth: 150,
                // maxWidth: 400,
                height: '100%',
            }}
            variant="outlined"
        >
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <Typography level="title-lg">
                        {toTitleCase(pharmacy.organisationName)}
                    </Typography>
                    {pharmacy.url && (
                        <Link
                            href={prependHttps(pharmacy.url)}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                        >
                            <LaunchRoundedIcon />
                        </Link>
                    )}
                </Box>
                {pharmacy.organisationSubType != 'Internet Pharmacy' && (
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: 0.5,
                                width: '100%',
                            }}
                        >
                            {generateAddressString(pharmacy).map(
                                (part, index, array) => (
                                    <Typography level="body-md" key={part}>
                                        {part}
                                    </Typography>
                                ),
                            )}
                        </Box>
                        <Typography level="title-md">
                            {pharmacy.postcode.toUpperCase()}
                        </Typography>
                    </Box>
                )}
                {pharmacy.organisationSubType == 'Internet Pharmacy' && (
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body-sm">Internet Pharmacy</Typography>
                    </Box>
                )}
            </CardContent>
            {!infoOnlyDisplay && (
                <CardActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                        alignItems: 'stretch',
                    }}
                >
                    <Button
                        variant="solid"
                        onClick={() => {
                            onPharmacySelect(pharmacy, true);
                        }}
                    >
                        Select
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

PharmacyCard.propTypes = {
    infoOnlyDisplay: PropTypes.bool,
    pharmacy: PropTypes.shape({
        odsCode: PropTypes.string.isRequired,
        organisationName: PropTypes.string.isRequired,
        organisationType: PropTypes.string,
        url: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        city: PropTypes.string,
        county: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        organisationSubType: PropTypes.string,
        postcode: PropTypes.string.isRequired,
    }).isRequired,
    onPharmacySelect: PropTypes.func,
};

export default PharmacyCard;
