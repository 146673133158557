import propTypes from 'prop-types';

export const processYoutubeUrl = (url) => {
    // Ensure the URL starts with https://
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'https://' + url;
    } else if (url.startsWith('http://')) {
        url = 'https://' + url.substring(7);
    }

    // Ensure the URL contains www. after the protocol
    const protocolEndIndex = url.indexOf('//') + 2;
    if (!url.substring(protocolEndIndex).startsWith('www.')) {
        url =
            url.substring(0, protocolEndIndex) +
            'www.' +
            url.substring(protocolEndIndex);
    }

    // Handle youtu.be URLs, stripping any query parameters
    if (url.includes('youtu.be')) {
        url = url.replace(
            /(https?:\/\/)?(www\.)?youtu\.be\/([^?&]+)/,
            '$1www.youtube-nocookie.com/embed/$3',
        );
    } else {
        // Handle youtube.com watch URLs, stripping any query parameters
        // after the video ID
        url = url.replace(
            /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([^&]+)/,
            '$1www.youtube-nocookie.com/embed/$3',
        );
    }

    // This removes any remaining query parameters not related to the video ID
    // for embed URLs
    url = url.replace(/\?.*$/, '');

    return url;
};

processYoutubeUrl.propTypes = {
    url: propTypes.string.isRequired,
};

export const simplifyLink = (url) => {
    return url.replace(/^(https?:\/\/)?(www\.)?/, '');
};

simplifyLink.propTypes = {
    url: propTypes.string.isRequired,
};
