import {AccordionSummary, Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = ({icon, children}) => {
    return (
        <AccordionSummary>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                }}
            >
                {icon}
                <Typography level="title-lg">{children}</Typography>
            </Box>
        </AccordionSummary>
    );
};

export default SectionHeader;

SectionHeader.propTypes = {
    ref: PropTypes.object,
    icon: PropTypes.node,
    children: PropTypes.node,
};
