import {Box, Card, CardContent} from '@mui/joy';
import {useTheme} from '@mui/joy/styles';
import PropTypes from 'prop-types';
import React from 'react';

const LoginCard = ({children}) => {
    const theme = useTheme();

    React.useEffect(() => {
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVh();
        window.addEventListener('resize', setVh);

        return () => window.removeEventListener('resize', setVh);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: 'calc(var(--vh, 1vh) * 90)',
                maxWidth: '95%',
                width: 450,
                mt: `max(${theme.spacing(4)}, env(safe-area-inset-top))`,
                mb: `max(${theme.spacing(4)}, env(safe-area-inset-bottom))`,
            }}
        >
            <Card
                variant="soft"
                sx={{
                    // eslint-disable-next-line max-len
                    backgroundColor: theme.palette.background.body,
                    maxHeight: '90vh',
                    boxShadow: theme.shadow.md,
                    overflow: 'auto',
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {children}
                </CardContent>
            </Card>
        </Box>
    );
};

export default LoginCard;

LoginCard.propTypes = {
    children: PropTypes.node,
};
