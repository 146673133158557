import React, {useContext} from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Dropdown,
    IconButton,
    Link,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    Sheet,
    Stack,
    Typography,
    useTheme,
} from '@mui/joy';
import {signOut} from 'aws-amplify/auth';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import {getColourMode} from 'utilities/colourUtilities';

const settings = [
    {name: 'NHS login Settings', icon: <OpenInNewIcon fontSize="small" />},
    {name: 'Logout'},
];

/**
 * A responsive AppBar component that displays a header with navigation menu and user
 * settings menu.
 *
 * @return {JSX.Element} - The JSX element representing the ResponsiveAppBar component.
 */
const ResponsiveAppBar = ({setLoginAction}) => {
    const user = useContext(UserContext);
    const theme = useTheme();
    const colourMode = getColourMode();

    const [showHelpModal, setShowHelpModal] = React.useState(false);

    const getInitial = (name) =>
        name
            .split(' ')
            .map((word) => word.charAt(0))
            .join('');
    const initials = `${getInitial(user.given_name)}${getInitial(user.family_name)}`;

    const handleMenuClick = (setting) => {
        switch (setting) {
            case 'NHS login Settings':
                window.open(
                    process.env.REACT_APP_NHS_LOGIN_SETTINGS_PAGE,
                    '_blank',
                    'noopener,noreferrer',
                );
                break;
            case 'Logout':
                // Call the function for handling the Logout click
                handleLogoutClick();
                break;
            default:
                // Handle any other cases or do nothing
                break;
        }
    };

    const handleLogoutClick = async (event) => {
        setLoginAction('logout');
        signOut();
    };

    return (
        <>
            <Modal
                open={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                aria-labelledby="help-modal-title"
                aria-describedby="help-modal-description"
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Sheet
                        sx={{
                            width: '90%',
                            maxWidth: '450px',
                            minWidth: '230px',
                            maxHeight: '900px',
                            position: 'absolute',
                            borderRadius: 'md',
                            overflow: 'auto',
                            top: '5%',
                            m: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Card>
                            <CardContent>
                                <ModalClose variant="plain" sx={{m: 1}} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        component="img"
                                        sx={{
                                            width: '80%',
                                            maxWidth: {xs: 350, md: 350},
                                            my: 3,
                                            mx: 2,
                                        }}
                                        alt="PocketGP Logo"
                                        /* eslint-disable max-len */
                                        src={
                                            colourMode === 'light'
                                                ? 'images/pocketgp_logo_transparent_background.png'
                                                : 'images/white_logo_transparent_background_cropped.png'
                                        }
                                        srcSet={
                                            colourMode === 'light'
                                                ? 'images/pocketgp_logo_transparent_background.png 1x, images/pocketgp_logo_transparent_background@2x.png 2x'
                                                : 'images/white_logo_transparent_background_cropped.png 1x, images/white_logo_transparent_background_cropped@2x.png 2x'
                                        }
                                        /* eslint-enable max-len */
                                    />
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{mx: 2}}
                                    >
                                        <Typography
                                            level="h3"
                                            id="help-modal-title"
                                            align="center"
                                        >
                                            Help and Support
                                        </Typography>
                                        <Typography
                                            id="help-modal-description"
                                            align="center"
                                        >
                                            If you are having issues with PocketGP, or
                                            wish to provide us with feedback, please
                                            email us at{' '}
                                            <Link
                                                href="mailto:support@pocketgp.app"
                                                underline="hover"
                                                variant="plain"
                                            >
                                                support@pocketgp.app
                                            </Link>
                                            .
                                            <br />
                                            <br />
                                            We&apos;ll get back to you as soon as we
                                            can.
                                        </Typography>
                                        <Divider />
                                        <Typography level="body-xs" align="justify">
                                            PocketGP™ is a product of Synapse256 Ltd,
                                            registered in England and Wales under
                                            Company Number 14823535. PocketGP™ is a
                                            registered trademark of Synapse256 Ltd.
                                        </Typography>
                                        <Typography level="body-xs" align="justify">
                                            © {new Date().getFullYear()} Synapse256
                                            Ltd. All rights reserved.
                                        </Typography>
                                    </Stack>
                                </Box>
                            </CardContent>
                        </Card>
                    </Sheet>
                </Box>
            </Modal>
            <Sheet
                position="sticky"
                sx={{
                    top: 0,
                    width: '100%',
                    bgcolor: theme.palette.primary['500'],
                    pt: 'max(8px, env(safe-area-inset-top))',
                    px: 1,
                    pb: 1,
                    boxShadow: `0px 1px 4px ${theme.palette.primary['500']}`,
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            width: '95%',
                            maxWidth: 1200,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: 150,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Link to="/" style={{display: 'inline-block'}}>
                                    <img
                                        src="./images/white_title_logo.png"
                                        srcSet={`./images/white_title_logo.png 1x,
                                     ./images/white_title_logo@2x.png 2x`}
                                        alt="PocketGP Logo"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'contain',
                                            display: 'block',
                                        }}
                                    />
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <IconButton
                                    variant="plain"
                                    onClick={() => setShowHelpModal(true)}
                                    sx={{color: theme.vars.palette.primary[200]}}
                                    size="lg"
                                >
                                    <HelpOutlineIcon />
                                </IconButton>
                                <Dropdown>
                                    <MenuButton
                                        slots={{root: Avatar}}
                                        slotProps={{root: {variant: 'plain'}}}
                                        sx={{
                                            p: 0,
                                            borderRadius: '50%', // Make it circular
                                            lineHeight: 0,
                                            minWidth: 0,
                                        }}
                                    >
                                        <Avatar
                                            variant="outlined"
                                            color="primary"
                                            // eslint-disable-next-line max-len
                                            alt={`${user.given_name} ${user.family_name}`}
                                            sx={{
                                                bgcolor: theme.palette.primary['200'],
                                                color: theme.palette.primary['800'],
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {initials}
                                        </Avatar>
                                    </MenuButton>
                                    <Menu placement="bottom-end">
                                        {settings.map((setting) => (
                                            <MenuItem
                                                key={setting.name}
                                                onClick={() =>
                                                    handleMenuClick(setting.name)
                                                }
                                            >
                                                <Typography
                                                    textAlign="center"
                                                    sx={{
                                                        display: 'flex',
                                                        justifyItems: 'center',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                    }}
                                                >
                                                    {setting.name}{' '}
                                                    {setting.icon && (
                                                        <ListItemDecorator
                                                            sx={{color: 'inherit'}}
                                                        >
                                                            {setting.icon}
                                                        </ListItemDecorator>
                                                    )}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Dropdown>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Sheet>
        </>
    );
};

ResponsiveAppBar.propTypes = {
    setLoginAction: PropTypes.func.isRequired,
};

export default ResponsiveAppBar;
