import {Box, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * FormSectionHeader component displays a title, supporting text, and optional
 * supporting content.
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - The title text.
 * @param {string} props.supportingText - Supporting text displayed below the title.
 * @param {React.Element} [props.supportingContent] - Optional JSX content displayed
 * below the supporting text.
 * @return {React.Element}
 */
const FormSectionHeader = ({title, supportingText, supportingContent}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '100%',
                width: '100%',
                maxWidth: {xs: 'none', sm: '280px'},
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100%',
                }}
            >
                <Typography level="title-md">{title}</Typography>
                {supportingText && (
                    <Typography level="body-sm">{supportingText}</Typography>
                )}
            </Box>
            {supportingContent && supportingContent}
        </Box>
    );
};

FormSectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    supportingText: PropTypes.string,
    supportingContent: PropTypes.element,
};

export default FormSectionHeader;
