import {
    AccordionGroup,
    Divider,
    List,
    ListItem,
    ListItemContent,
    Sheet,
    Typography,
    useTheme,
} from '@mui/joy';
import Accordion, {accordionClasses} from '@mui/joy/Accordion';
import AccordionDetails, {accordionDetailsClasses} from '@mui/joy/AccordionDetails';
import AccordionSummary, {accordionSummaryClasses} from '@mui/joy/AccordionSummary';
import PropTypes from 'prop-types';
import React from 'react';
import DisplayInformation from './DisplayInformation';
import ImmediateActionQuery from './ImmediateActionQuery';
import UserQuery from './UserQuery';

const MedicationChecksSection = ({medicationCheckActions, addResolution, expanded}) => {
    const theme = useTheme();
    const numItemsText = `${medicationCheckActions.actions.length} item${
        medicationCheckActions.actions.length > 1 ? 's' : ''
    }`;

    const numAvailableItems = medicationCheckActions.actions.filter(
        (obj) => obj.hidden === false,
    ).length;

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'md',
                backgroundColor: theme.palette.background.surface,
            }}
        >
            <AccordionGroup
                variant="plain"
                transition="1.0s"
                sx={{
                    borderRadius: 'md',
                    width: '100%',
                    // eslint-disable-next-line max-len
                    [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                        {
                            paddingBlock: '1rem',
                        },
                    [`& .${accordionSummaryClasses.button}`]: {
                        paddingBlock: '1rem',
                    },
                    [`& .${accordionClasses.root}`]: {
                        '& button:hover': {
                            background: 'transparent',
                            cursor: 'default',
                        },
                    },
                }}
            >
                <Accordion
                    disabled={medicationCheckActions.hidden}
                    expanded={!medicationCheckActions.hidden}
                >
                    <AccordionSummary>
                        <ListItemContent
                            sx={{
                                display: 'flex',
                                flexDirection: {xs: 'column', sm: 'row'},
                                justifyContent: 'space-between',
                            }}
                        >
                            {medicationCheckActions.medicineName}
                            <Typography level="body-sm">{numItemsText}</Typography>
                        </ListItemContent>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            {medicationCheckActions.actions.map((obj, index) => {
                                const isFirstItem = index === 0;
                                const isLastItem = index === numAvailableItems - 1;

                                if (!obj.hidden) {
                                    return (
                                        <React.Fragment key={obj.path}>
                                            <FadeInListItem>
                                                {/* eslint-disable max-len */}
                                                {obj.type ===
                                                    'ImmediateActionQuery' && (
                                                    <ImmediateActionQuery
                                                        {...obj}
                                                        isFirstItem={isFirstItem}
                                                        addResolution={addResolution}
                                                    />
                                                )}
                                                {obj.type === 'UserQuery' && (
                                                    <UserQuery
                                                        {...obj}
                                                        isFirstItem={isFirstItem}
                                                        addResolution={addResolution}
                                                    />
                                                )}
                                                {obj.type === 'DisplayInformation' && (
                                                    <DisplayInformation
                                                        {...obj}
                                                        isFirstItem={isFirstItem}
                                                    />
                                                )}
                                            </FadeInListItem>
                                            {!isLastItem && <FadeInDivider />}
                                        </React.Fragment>
                                    );
                                }
                                return null;
                            })}
                        </List>
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>
        </Sheet>
    );
};

MedicationChecksSection.propTypes = {
    medicationCheckActions: PropTypes.shape({
        medicineId: PropTypes.string.isRequired,
        medicineName: PropTypes.string.isRequired,
        actions: PropTypes.array,
        hidden: PropTypes.bool.isRequired,
    }).isRequired,
    resolutionValues: PropTypes.object.isRequired,
    addResolution: PropTypes.func,
    expanded: PropTypes.bool,
};

export default MedicationChecksSection;

const FadeInDivider = ({...props}) => {
    const [opacity, setOpacity] = React.useState(0);

    React.useEffect(() => {
        // Set a timeout to ensure the CSS transition applies only after the
        // initial render
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 10); // Small delay like 10ms

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <Divider
            sx={{
                opacity: opacity,
                transition: opacity === 1 ? 'opacity 0.5s ease-in-out' : 'none',
            }}
            {...props}
        />
    );
};

FadeInDivider.propTypes = {};

const FadeInListItem = ({children, ...props}) => {
    const [opacity, setOpacity] = React.useState(0);

    React.useEffect(() => {
        // Set a timeout to ensure the CSS transition applies only after the
        // initial render
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 10); // Small delay like 10ms

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <ListItem
            sx={{
                opacity: opacity,
                transition: opacity === 1 ? 'opacity 0.5s ease-in-out' : 'none',
            }}
            {...props}
        >
            {children}
        </ListItem>
    );
};

FadeInListItem.propTypes = {
    children: PropTypes.node.isRequired,
};
