import {Box, Link} from '@mui/joy';
import React from 'react';

const NhsSyndicationApisLogo = () => {
    const handleError = (event) => {
        event.target.src = './images/nhs_attribution.png';
    };

    return (
        <Box
            sx={{
                maxWidth: '200px',
                height: 'auto',
            }}
        >
            <Link
                // eslint-disable-next-line max-len
                href="https://www.nhs.uk/nhs-services/prescriptions-and-pharmacies/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <img // NOSONAR
                    // eslint-disable-next-line max-len
                    src="https://digital.nhs.uk/binaries/content/gallery/website/developer/api-catalogue/nhs-website-content/nhs-attribution.png"
                    // eslint-disable-next-line max-len
                    alt="Content supplied by the NHS website nhs.uk © NHS website"
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                    onError={handleError}
                />
            </Link>
        </Box>
    );
};

export default NhsSyndicationApisLogo;
