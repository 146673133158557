import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Alert, Box, FormControl, Radio, RadioGroup, Sheet, Typography} from '@mui/joy';
import PropTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
import Action from './ImmediateActionQueryAction';

const ImmediateActionQuery = ({
    hash,
    path,
    title,
    body,
    supportingText,
    questionNumber,
    responseType,
    onTruthy,
    onFalsy,
    medicineId,
    addResolution,
    isFirstItem,
}) => {
    const [responseValue, setResponseValue] = React.useState(null);
    const handleChange = (event, newValue) => {
        const booleanValue = event.target.value === 'true';
        const flowInterrupt = booleanValue
            ? onTruthy.flowInterrupt
            : onFalsy.flowInterrupt;

        setResponseValue(booleanValue);
        addResolution({
            path: path,
            hash: hash,
            type: 'ImmediateActionQuery',
            booleanValue: booleanValue,
            medicineId: medicineId,
            flowInterrupt: flowInterrupt,
        });
    };

    return (
        <Sheet
            sx={{
                width: '100%',
                mt: isFirstItem ? 0 : 2,
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, mx: 1}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: 'space-between',
                        gap: 1,
                    }}
                >
                    <Typography level="title-lg" component="div">
                        <PlainMarkdown>{title}</PlainMarkdown>
                    </Typography>
                    <Typography level="body-sm" sx={{alignSelf: {xs: 'flex-end'}}}>
                        {questionNumber}
                    </Typography>
                </Box>
                {body && (
                    <Typography
                        sx={{
                            whiteSpace: 'pre-line',
                            margin: 0,
                            padding: 0,
                        }}
                        component="div"
                    >
                        <StyledMarkdown>{body}</StyledMarkdown>
                    </Typography>
                )}
                {supportingText && (
                    <Alert
                        color="primary"
                        variant="soft"
                        startDecorator={<InfoOutlinedIcon />}
                    >
                        <StyledMarkdown>{supportingText}</StyledMarkdown>
                    </Alert>
                )}
            </Box>
            <Box>
                <FormControl sx={{ml: 4, mt: 2}}>
                    <RadioGroup
                        aria-labelledby="yes-no-radio-buttons-group-label"
                        name="yes-no-radio-buttons-group"
                        sx={{
                            gap: 2,
                            flexDirection: {
                                xs: 'column', // Vertical on small screens
                                sm: 'row', // Horizontal on medium screens and up
                            },
                            alignItems: {xs: 'flex-start', sm: 'center'},
                        }}
                        onChange={handleChange}
                    >
                        <Radio
                            value={true}
                            label={responseType == 'YesNo' ? 'Yes' : 'True'}
                            sx={{m: 0}}
                        />
                        <Radio
                            value={false}
                            label={responseType == 'YesNo' ? 'No' : 'False'}
                            sx={{m: 0}}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box sx={{my: 2}}>
                {responseValue === true && <Action {...onTruthy} />}
                {responseValue === false && <Action {...onFalsy} />}
            </Box>
        </Sheet>
    );
};

export default ImmediateActionQuery;

ImmediateActionQuery.propTypes = {
    medicineId: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    supportingText: PropTypes.string,
    questionNumber: PropTypes.string.isRequired,
    responseType: PropTypes.string.isRequired,
    onTruthy: PropTypes.object.isRequired,
    onFalsy: PropTypes.object.isRequired,
    addResolution: PropTypes.func.isRequired,
    isFirstItem: PropTypes.bool,
};
