/* eslint-disable max-len */
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import {
    Box,
    Chip,
    FormControl,
    FormLabel,
    Option,
    Select,
    Skeleton,
    TabList,
    TabPanel,
    Tabs,
    Typography,
    useTheme,
} from '@mui/joy';
import Tab, {tabClasses} from '@mui/joy/Tab';
import PageHeader from 'components/headers/PageHeader';
import {useFetchMyConditions} from 'graphql/myConditions';
import React from 'react';
import ConditionSection from './components/ConditionSection';
import LoadingSection from './components/LoadingSection';
/* eslint-enable max-len */

const MyHealth = () => {
    const theme = useTheme();

    const [conditions, setConditions] = React.useState([]);
    const [selectedCondition, setSelectedCondition] = React.useState(0);

    const {data, loading, error} = useFetchMyConditions();
    React.useEffect(() => {
        if (data?.me?.conditions) {
            const tmpConditions = data.me.conditions;
            const patientConditions = [...tmpConditions].sort((a, b) => {
                const dateA = a.datetime
                    ? new Date(a.datetime)
                    : new Date(-8640000000000000); // Min date
                const dateB = b.datetime
                    ? new Date(b.datetime)
                    : new Date(-8640000000000000); // Min date
                return dateA - dateB; // Reverse the subtraction
            });
            setConditions(patientConditions);
        }
    }, [data]);

    const title = 'My Health';

    const breadcrumbData = [{href: '/health', text: 'My Health'}];

    const handleChange = (event, newValue) => {
        setSelectedCondition(newValue);
    };

    return (
        <>
            <PageHeader
                title={title}
                breadcrumbs={breadcrumbData}
                icon={<MedicalInformationIcon fontSize="medium" />}
                iconAlt="My Health"
            />
            <Box
                sx={{
                    py: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {(loading || conditions.length > 0) && (
                    <Tabs
                        aria-label="Condition Tabs"
                        orientation="vertical"
                        sx={{
                            display: {xs: 'none', sm: 'flex'},
                            flexDirection: 'row',
                        }}
                    >
                        <TabList
                            disableUnderline
                            sx={{
                                borderRadius: 0,
                                minWidth: '200px',
                                width: '200px',
                                maxWidth: '200px',
                                gap: 1,
                                [`&& .${tabClasses.root}`]: {
                                    flex: 'initial',
                                },
                            }}
                        >
                            <Typography level="title-md" color="inherit" sx={{pl: 2}}>
                                My Conditions
                            </Typography>
                            {loading &&
                                Array(3)
                                    .fill(0)
                                    .map((_, index) => (
                                        <Tab
                                            key={index}
                                            color="neutral"
                                            disableIndicator
                                            disabled={true}
                                            sx={{
                                                width: '100%',
                                                borderRadius: ' 0.375rem',
                                                color: theme.vars.palette.neutral[500],
                                                '&.Mui-selected': {
                                                    // eslint-disable-next-line max-len
                                                    border: `1px solid rgba(${theme.vars.palette.primary[500]}, 0.9)`,
                                                    bgcolor:
                                                        theme.vars.palette.neutral[100],
                                                    color: theme.vars.palette
                                                        .neutral[700],
                                                },
                                            }}
                                        >
                                            <Skeleton
                                                sx={{
                                                    left: 0,
                                                    right: 0,
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    borderRadius: ' 0.375rem',
                                                }}
                                            >
                                                Health Summary
                                            </Skeleton>
                                        </Tab>
                                    ))}
                            {!loading &&
                                !error &&
                                conditions.map((condition, index) => {
                                    const outstandingActionsSection =
                                        condition.definition?.sections?.find(
                                            (section) =>
                                                section.title === 'Outstanding Actions',
                                        );
                                    // Filter actions with "actionType": "REQUIRED"
                                    const requiredActions =
                                        outstandingActionsSection?.content?.filter(
                                            (action) =>
                                                action.actionType === 'REQUIRED',
                                        ) || []; // Default to an empty array
                                    const actionRequiredCount = requiredActions.length;

                                    return (
                                        <Tab
                                            key={condition.definition.title}
                                            color="neutral"
                                            disableIndicator
                                            sx={{
                                                width: '100%',
                                                borderRadius: ' 0.375rem',
                                                color: theme.vars.palette.neutral[500],
                                                '&.Mui-selected': {
                                                    // eslint-disable-next-line max-len
                                                    border: `1px solid rgba(${theme.vars.palette.primary[500]}, 0.9)`,
                                                    bgcolor:
                                                        theme.vars.palette.neutral[100],
                                                    color: theme.vars.palette
                                                        .neutral[700],
                                                },
                                                px: 2,
                                            }}
                                        >
                                            <Typography
                                                level="title-sm"
                                                color="inherit"
                                            >
                                                {condition.definition.title}
                                            </Typography>
                                            {actionRequiredCount > 0 && (
                                                <Chip
                                                    color="neutral"
                                                    variant="outlined"
                                                >
                                                    {actionRequiredCount}
                                                </Chip>
                                            )}
                                        </Tab>
                                    );
                                })}
                        </TabList>
                        {loading && (
                            <TabPanel
                                keepMounted
                                sx={{flexGrow: 1, pt: 0, maxWidth: '100%'}}
                            >
                                <LoadingSection />
                            </TabPanel>
                        )}
                        {conditions.map((condition, index) => (
                            <TabPanel
                                keepMounted
                                value={index}
                                key={condition.definition.title}
                                sx={{flexGrow: 1, pt: 0, maxWidth: '100%'}}
                            >
                                <ConditionSection
                                    condition={condition.definition}
                                    hidden={false}
                                />
                            </TabPanel>
                        ))}
                    </Tabs>
                )}
                {(loading || conditions.length > 0) && (
                    <Box
                        sx={{
                            display: {xs: 'flex', sm: 'none'},
                            flexDirection: 'column',
                            width: '100%',
                            px: 1,
                        }}
                    >
                        <FormControl sx={{width: '100%', mb: 2}}>
                            <FormLabel
                                id="select-condition-label"
                                htmlFor="select-condition-button"
                                sx={{alignSelf: 'start'}}
                            >
                                My Conditions
                            </FormLabel>
                            <Select
                                placeholder={
                                    loading
                                        ? 'Loading...'
                                        : 'Select a page to explore...'
                                }
                                disabled={loading}
                                sx={{width: '100%'}}
                                onChange={handleChange}
                                /* eslint-disable max-len */
                                slotProps={{
                                    button: {
                                        id: 'select-condition-button',
                                        'aria-labelledby':
                                            'select-condition-label select-condition-button',
                                    },
                                }}
                                /* eslint-enable max-len */
                            >
                                {!loading &&
                                    !error &&
                                    conditions.map((condition, index) => (
                                        <Option
                                            key={condition.definition.title}
                                            value={index}
                                        >
                                            <Typography
                                                level="title-sm"
                                                color="inherit"
                                            >
                                                {condition.definition.title}
                                            </Typography>
                                        </Option>
                                    ))}
                            </Select>
                        </FormControl>
                        {!loading &&
                            !error &&
                            conditions.map((condition, index) => (
                                <ConditionSection
                                    hidden={selectedCondition !== index}
                                    condition={condition.definition}
                                    key={condition.definition.title}
                                />
                            ))}
                        {loading && <LoadingSection />}
                    </Box>
                )}
                {!loading && !error && data && conditions.length < 1 && (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            my: 4,
                        }}
                    >
                        <Box
                            sx={{
                                width: 42,
                                height: 42,
                                // eslint-disable-next-line max-len
                                border: `1px solid ${theme.vars.palette.neutral[300]}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 8,
                                boxShadow: `0px 1px 2px 0px rgba(16, 24, 40, 0.05)`,
                            }}
                        >
                            <MedicalInformationIcon color="primary" fontSize="large" />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography level="title-md" sx={{textAlign: 'center'}}>
                                No supported conditions found
                            </Typography>
                            <Typography level="body-md" sx={{textAlign: 'center'}}>
                                It looks like you have not been diagnosed with any
                                medical conditions that we can provide additional
                                information about at this time. If you believe this is a
                                mistake, please contact your GP surgery.
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default MyHealth;
