const DEFAULT_LOGARITHMIC = false;
import PropTypes from 'prop-types';
import React from 'react';

import {Box, useTheme} from '@mui/joy';
import {
    RadialGauge,
    RadialGaugeArc,
    StackedRadialGaugeDescriptionLabel,
    StackedRadialGaugeSeries,
    StackedRadialGaugeValueLabel,
} from 'reaviz';
import {
    degreesToRadians,
    determineValueSentiment,
    normaliseDataToPercentage,
    sentimentToColour,
} from 'utilities/chartUtilities';

/**
 * RadialStackedGauge component displays a radial gauge with stacked arcs representing
 * data.
 *
 * @param {object} props - The props object containing the following properties:
 *   @param {number[]} data - An array of numeric data values to be displayed in the
 *     gauge.
 *   @param {number} height - The height of the radial gauge in pixels.
 *   @param {number} width - The width of the radial gauge in pixels.
 *   @param {number} minValue - The minimum value for the gauge data range (default is
 *     0).
 *   @param {number} maxValue - The maximum value for the gauge data range.
 *   @param {string[]} colorScheme - An array of color values for the stacked arcs.
 *   @param {string} label - The main label to display on the gauge.
 *   @param {string} supportingLabel - The supporting label to display on the gauge.
 * @return {JSX.Element} The JSX element representing the RadialStackedGauge.
 */
const RadialStackedGauge = ({values, height, width, label, supportingLabel}) => {
    const theme = useTheme();

    const data = values
        .map((value, index) => ({
            key: value.label,
            data:
                value.logarithmic || DEFAULT_LOGARITHMIC
                    ? Math.log10(Number(value.value))
                    : Number(value.value),
            minValue:
                value.logarithmic || DEFAULT_LOGARITHMIC
                    ? Math.log10(Number(value.rangeInformation.minimum) || 1)
                    : Number(value.rangeInformation.minimum) || 0,
            maxValue:
                value.logarithmic || DEFAULT_LOGARITHMIC
                    ? Math.log10(Number(value.rangeInformation.maximum))
                    : Number(value.rangeInformation.maximum),
            colour: sentimentToColour(
                determineValueSentiment(
                    value.value,
                    value.rangeInformation.rangeDescriptions,
                ),
                theme,
            ),
        }))
        .reverse();

    const normalisedData = normaliseDataToPercentage(data);

    return (
        <Box
            sx={{
                overflow: 'hidden',
                /*
                The Reaviz radial gauge component is designed for displaying
                circular gauges. We need to therefore remove the whitespace
                that would normally be in place for the bottom of the circle.
                */
                mt: '-16px',
                mb: `-${width / 2}px`,
                ml: -2,
                mr: -2,
            }}
        >
            <RadialGauge
                data={normalisedData}
                height={height}
                width={width}
                // Set the start angle to -90 degrees (left)
                startAngle={degreesToRadians(-90)}
                // Set the end angle to 90 degrees (right)
                endAngle={degreesToRadians(90)}
                angleRange={degreesToRadians(180)}
                series={
                    <StackedRadialGaugeSeries
                        arcPadding={0.3}
                        fillFactor={0.2}
                        colorScheme={normalisedData.map((value) => value.colour)}
                        label={
                            <StackedRadialGaugeValueLabel
                                label={label}
                                className="radialGaugeLabel"
                                yOffset={-10}
                            />
                        }
                        descriptionLabel={
                            <StackedRadialGaugeDescriptionLabel
                                label={supportingLabel}
                                className="radialGaugeDescriptionLabel"
                                yOffset={-16}
                            />
                        }
                        innerArc={
                            <RadialGaugeArc
                                animated={true}
                                cornerRadius={12.5}
                                tooltip={null}
                            />
                        }
                        outerArc={
                            <RadialGaugeArc
                                disabled={true}
                                animated={false}
                                cornerRadius={12.5}
                                color={theme.vars.palette.neutral[100]}
                                tooltip={null}
                            />
                        }
                    />
                }
            />
        </Box>
    );
};

export default RadialStackedGauge;

RadialStackedGauge.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            datetime: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string.isRequired,
            rangeInformation: PropTypes.shape({
                minimum: PropTypes.string.isRequired,
                maximum: PropTypes.string.isRequired,
                rangeDescriptions: PropTypes.arrayOf(
                    PropTypes.shape({
                        minimum: PropTypes.string,
                        maximum: PropTypes.string,
                        sentiment: PropTypes.string.isRequired,
                    }),
                ),
            }),
        }),
    ).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    supportingLabel: PropTypes.string,
};
