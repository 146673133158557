/* eslint-disable max-len */
import CloseIcon from '@mui/icons-material/Close';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import {
    Alert,
    Box,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
    Sheet,
    Typography,
    useTheme,
} from '@mui/joy';
import Timeline from '@mui/lab/Timeline';
import PropTypes from 'prop-types';
import React from 'react';
import {getColourMode} from 'utilities/colourUtilities';
import {getTimeDifference} from 'utilities/dateUtils';
import PrescriptionTimelineItem from './components/PrescriptionTimelineItem';
/* eslint-enable max-len */

const MedicationHistoryPanel = ({
    open,
    setMedicationHistoryPanelContents,
    medicationName,
    medicationHistoryPanelContents,
}) => {
    const theme = useTheme();
    const mode = getColourMode();

    return (
        <Drawer
            open={open}
            onClose={() => {
                setMedicationHistoryPanelContents(null);
            }}
            anchor="right"
            size="lg"
        >
            <Sheet
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 'md',
                    overflow: 'hidden',
                    pr: 'env(safe-area-inset-right)',
                    pb: 'env(safe-area-inset-bottom)',
                }}
            >
                {medicationHistoryPanelContents && (
                    <>
                        <DialogTitle
                            sx={{
                                mb: 2,
                                paddingTop: 'max(16px, env(safe-area-inset-top))',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mr: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        px: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 40,
                                            maxHeight: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor:
                                                mode == 'light'
                                                    ? theme.palette.primary['100']
                                                    : theme.palette.primary['800'],
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MedicationRoundedIcon color="primary" />
                                    </Box>
                                    {medicationName}
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setMedicationHistoryPanelContents(null);
                                    }}
                                    aria-label="close"
                                    sx={{
                                        maxHeight: 40,
                                        maxWidth: 40,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                paddingBottom: 'env(safe-area-inset-bottom)',
                                flexGrow: 1,
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                className="scrollable"
                                sx={{
                                    height: '100%',
                                    '--scrollable-background':
                                        theme.vars.palette.background.surface,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        my: 1,
                                        mx: 2,
                                    }}
                                >
                                    <Typography level="body-sm" component="div">
                                        Here, your can view your past orders of for your
                                        medicine {medicationName}.
                                    </Typography>
                                    <Alert
                                        severity="info"
                                        startDecorator={<InfoOutlinedIcon />}
                                    >
                                        <Typography
                                            level="body-xs"
                                            color={theme.vars.palette.grey[700]}
                                        >
                                            Some previous medications, particularly
                                            repeat prescriptions, might not be visible.
                                        </Typography>
                                    </Alert>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        px: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 1,
                                        }}
                                    >
                                        <HistoryRoundedIcon />
                                        <Typography level="title-lg">
                                            Order History
                                        </Typography>
                                    </Box>
                                    <Box sx={{width: '100%'}}>
                                        <Timeline
                                            position="left"
                                            sx={{px: 0, mx: 0, width: '100%'}}
                                        >
                                            {/* eslint-disable-next-line max-len */}
                                            {medicationHistoryPanelContents.pastOrders.map(
                                                (order, index, array) => {
                                                    const nextOrder = array[index - 1];
                                                    let elapsedTime = '';

                                                    if (nextOrder) {
                                                        elapsedTime = getTimeDifference(
                                                            order.date,
                                                            nextOrder.date,
                                                        );
                                                    } else {
                                                        elapsedTime = getTimeDifference(
                                                            order.date,
                                                        );
                                                    }

                                                    if (elapsedTime === '0 minutes') {
                                                        elapsedTime = false;
                                                    }

                                                    return (
                                                        <PrescriptionTimelineItem
                                                            key={index}
                                                            order={order}
                                                            nextOrder={nextOrder}
                                                            elapsedTime={elapsedTime}
                                                        />
                                                    );
                                                },
                                            )}
                                        </Timeline>
                                    </Box>
                                </Box>
                            </Box>
                        </DialogContent>
                    </>
                )}
            </Sheet>
        </Drawer>
    );
};

export default MedicationHistoryPanel;

MedicationHistoryPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    setMedicationHistoryPanelContents: PropTypes.func.isRequired,
    medicationName: PropTypes.string,
    medicationHistoryPanelContents: PropTypes.shape({
        pastOrders: PropTypes.arrayOf(
            PropTypes.shape({
                date: PropTypes.string,
                dose: PropTypes.string,
                prescriptionType: PropTypes.string,
                quantity: PropTypes.string,
                quantityUnit: PropTypes.string,
                status: PropTypes.string,
            }),
        ),
    }),
};
