/* eslint-disable max-len */
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import {
    AccordionDetails,
    AspectRatio,
    Box,
    Card,
    Link,
    List,
    ListItem,
    ListItemContent,
    Typography,
} from '@mui/joy';
import Accordion from '@mui/joy/Accordion';
import propTypes from 'prop-types';
import React from 'react';
import {PlainMarkdown, StyledMarkdown} from 'utilities/textUtilities';
import {processYoutubeUrl} from 'utilities/urlUtils';
import SectionHeader from './SectionHeader';

/* eslint-enable max-len */

const LinksSection = ({links}) => {
    return (
        <Accordion defaultExpanded={true}>
            <SectionHeader icon={<LinkRoundedIcon />}>Useful links</SectionHeader>
            <AccordionDetails>
                <List marker='"-"'>
                    {links.map((link) => (
                        <ListItem key={link.title}>
                            <ListItemContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <Link
                                        variant="soft"
                                        href={link.url}
                                        target="_blank"
                                        sx={{
                                            display: 'inline-flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            ml: 2,
                                            borderRadius: 'sm',
                                        }}
                                    >
                                        <Typography level="title-md" component="div">
                                            <PlainMarkdown>{link.title}</PlainMarkdown>
                                        </Typography>
                                    </Link>
                                    <Link href={link.url} target="_blank">
                                        <OpenInNewRoundedIcon fontSize="small" />
                                    </Link>
                                </Box>
                                <Typography sx={{ml: 2}} component="div">
                                    <StyledMarkdown>{link.description}</StyledMarkdown>
                                </Typography>
                            </ListItemContent>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

LinksSection.propTypes = {
    links: propTypes.arrayOf(
        propTypes.shape({
            title: propTypes.string,
            description: propTypes.string,
            url: propTypes.string,
        }),
    ),
};

const VideosSection = ({videos}) => {
    return (
        <Accordion defaultExpanded={true}>
            <SectionHeader icon={<OndemandVideoRoundedIcon />}>
                Helpful videos
            </SectionHeader>
            <AccordionDetails>
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: 2,
                        my: 2,
                    }}
                >
                    {videos.map((video) => (
                        <Card key={video.title} variant="outlined" sx={{width: '90%'}}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <AspectRatio
                                    sx={{
                                        width: '90%',
                                    }}
                                >
                                    <iframe
                                        src={processYoutubeUrl(video.youtubeUrl)}
                                        title={video.title}
                                        style={{
                                            border: 0,
                                        }}
                                        // eslint-disable-next-line max-len
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                    ></iframe>
                                </AspectRatio>
                            </Box>
                            <div>
                                <Typography level="title-md" component="div">
                                    <PlainMarkdown>{video.title}</PlainMarkdown>
                                </Typography>
                                <Typography level="body-sm" component="div">
                                    <StyledMarkdown>{video.description}</StyledMarkdown>
                                </Typography>
                            </div>
                        </Card>
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

VideosSection.propTypes = {
    videos: propTypes.arrayOf(
        propTypes.shape({
            title: propTypes.string,
            description: propTypes.string,
            youtubeUrl: propTypes.string,
        }),
    ),
};

export {LinksSection, VideosSection};
